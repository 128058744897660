import React from 'react'

 const TopBar = () => {
    return (
        <section className="TopBar">
            <div className="container-fluid">
             <div className="container">
             <div className="row">
                    <div className="col-lg-4">
                        <a href="/"><span><i className="fas fa-mobile"/></span>হেল্পলাইন: 09638147678(10am-6pm)</a>
                    </div>
                    <div className="col-lg-4 text-center">
                    <a href="/"><span><i className="fas fa-hand-point-right"/></span>Aladinerprodip Online Shopping Mall</a>
                    </div>
                    <div className="col-lg-4">
                        <ul>
                            <li><a href="/"><span><i className="fab fa-telegram-plane"/></span>পরামর্শ / অভিযোগ</a></li>
                            <li><a href="/"><span><i className="fas fa-hand-point-right"/></span>সেলার কর্নার</a></li>
                            {/* <li><a href="/"><span><i className="fab fa-youtube-square"></i></span>ভিডিও টিউটোরিয়াল</a></li> */}
                        </ul>
                    </div>
                </div>
             </div>
            </div>

        </section>
    )
}
export default TopBar