import React, {useState} from "react";
import Nav from "./Include/Nav";
import {Link} from "react-router-dom";
import contactImg from '../../../Assets/Images/contact/contact.jpg'


const Contact = () => {

    // const [contact, setContact] = useState([
    //     {
    //         title: 'About',
    //         description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias cumque dolores\n' +
    //             'ducimus earum eligendi fugit impedit ipsam ipsum minus nesciunt, officia, quidem,\n' +
    //             'voluptates. Accusantium amet animi consectetur deserunt dicta distinctio, est fuga\n' +
    //             'maiores minus molestiae molestias, nobis quod recusandae sapiente sequi sit, veniam\n' +
    //             'voluptatem. Accusantium eligendi esse est laboriosam magni!'
    //     },
    //     {
    //         title: 'Our Target',
    //         description: 'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias cumque dolores\n' +
    //             'ducimus earum eligendi fugit impedit ipsam ipsum minus nesciunt, officia, quidem,\n' +
    //             'voluptates. Accusantium amet animi consectetur deserunt dicta distinctio, est fuga\n' +
    //             'maiores minus molestiae molestias, nobis quod recusandae sapiente sequi sit, veniam\n' +
    //             'voluptatem. Accusantium eligendi esse est laboriosam magni!'
    //     }
    // ])

    const [contactInfo, setcontactInfo] = useState([
        {
            contactIcon: '<i class="fas fa-mobile-alt"/>',
            contatTitle: 'Call Us',
            contact1: '017221545',
            contact2: '017221545'
        },
        {
            contactIcon: '<i class="fas fa-map-marker-alt"/>',
            contatTitle: 'Visit Us',
            contact1: 'Innovative IT (iIT)\n' +
                '7th Floor, Fair Plaza\n' +
                'Mirpur-1, Dhaka-1216\n' +
                'Bangladesh',

        },
        {
            contactIcon: '<i class="fas fa-envelope"/>',
            contatTitle: 'Mail Us',
            contact1: 'info@iithost.com',

        },
        {
            contactIcon: '<i class="fas fa-headset"/>',
            contatTitle: 'Live Chat',
            contact1: 'Chat with Us 24/7',

        },
    ])

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <Nav/>
                    </div>
                    <div className="col-md-12">
                        <div className="others-section bg-white contact-us-section-padding-bottom custom-shadow-without-top">
                            <h1 className={'text-center'}>Contact Us</h1>

                            {/*{contact.map((item, index) => (*/}
                            {/*    <div className="other-section-inner" key={index}>*/}
                            {/*        <h4>{item.title}</h4>*/}
                            {/*        <p>{item.description}</p>*/}
                            {/*    </div>*/}
                            {/*))}*/}

                            <div className="row">
                                <div className="col-md-12">
                                    <div className="contact-information-section">
                                        {contactInfo.map((item, index) => (
                                            <div className="contact-information-inner" key={index}>
                                                <span dangerouslySetInnerHTML={{__html: item.contactIcon}}/>
                                                <h6>{item.contatTitle}</h6>
                                                <Link to={'/contact'}><p>{item.contact1}</p></Link>
                                                <p>{item.contact2}</p>
                                            </div>
                                        ))}

                                    </div>
                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-12">
                                    <div className="contact-form-and-img custom-box-shadow-inner">
                                        <div className="get-in-touch">
                                            <div className="other-section-inner-title">
                                                <h4>Get In Touch</h4>
                                            </div>
                                        </div>
                                        <div className="row align-items-center">

                                            <div className="col-md-6 g-0">
                                                <div className="contact-image">
                                                    <img src={contactImg} className={'img-fluid'} alt=""/>
                                                </div>
                                            </div>
                                            <div className="col-md-6 g-0">
                                                <div className="contact-form">

                                                    <form>
                                                        <div className="mb-3">
                                                            <input type="text" className="form-control"
                                                                   placeholder={'Enter Name'}/>
                                                        </div>
                                                        <div className="mb-3">
                                                            <input type="email" className="form-control"
                                                                   placeholder={'Enter Email'}/>
                                                        </div>
                                                         <div className="mb-3">
                                                            <input type="number" className="form-control"
                                                                   placeholder={'Enter Number'}/>
                                                        </div>
                                                        <div className="mb-3">
                                                            <input type="text" className="form-control"
                                                                   placeholder={'Enter Subject'}/>
                                                        </div>
                                                        <div className="mb-3">
                                                        <textarea rows="4" className="form-control"
                                                                  placeholder="Enter Message"/>
                                                        </div>
                                                        <div className="contact-form-btn">
                                                            <button type="submit" className="send-message-btn">Send
                                                                Message
                                                            </button>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container-fluid">
                <div className="row">
                    <div className="col-12 p-0">
                        <div className="location-map bg-white">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d1825.1900920982648!2d90.3499739079564!3d23.80507620047842!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3755c0e6d15c4c73%3A0x995f8bf135434984!2siIT+Host!5e0!3m2!1sen!2sbd!4v1516088792703"
                            width={'100%'} height={'450px'} frameBorder={'0'}  allowFullScreen=""/>
                        </div>

                    </div>
                </div>
            </div>
        </>
    )
}
export default Contact