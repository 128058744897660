import React from 'react';
import SideNavBar from "../Include/SideNavBar";
import ClintProfileTopBar from "../Include/ClintProfileTopBar";
import {Link} from "react-router-dom";


const AllTransaction = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="my-dashboard-inner">
                        <div className="row">
                            <div className="col-md-3">
                                <SideNavBar/>
                            </div>
                            <div className="col-md-9">
                                <ClintProfileTopBar/>
                                <div className="all-order-dropdown">
                                    <div className="main-all-order-section">
                                        <label className={'all-order-section-label'}>Show:</label>
                                        <select name="" id="" className={'all-order-select-section'}>
                                            <option value="" className={'all-order-option-section'}>Last 5 Transaction
                                            </option>
                                            <option value="" className={'all-order-option-section'}>Last 1 Week
                                            </option>
                                            <option value="" className={'all-order-option-section'}>Last 15 Days
                                            </option>
                                            <option value="" className={'all-order-option-section'}>Last 30 Days
                                            </option>
                                            <option value="" className={'all-order-option-section'}>All Transaction
                                            </option>
                                        </select>
                                    </div>
                                    <div className="transaction-list-search-box">
                                            <input className="transaction-list-search-box-main" type="text" placeholder="Search Invoice Number"/>
                                            <span className={'search-icon-absolute'}><i className="fas fa-search"/></span>
                                    </div>
                                    <h5 className={'my-order-title'}>All Transaction List</h5>
                                </div>
                                <div className="all-transaction-table">
                                    <table className="table custom-table table-hover">
                                        <thead className={'all-transaction-list'}>
                                        <tr>
                                            <th>Sl</th>
                                            <th>Transaction Date</th>
                                            <th>Invoice Number</th>
                                            <th>Gateway</th>
                                            <th>Transaction Id</th>
                                            <th>Amount</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <tr>
                                            <th>1</th>
                                            <td>Tue, Oct 20, 2020 12:00 AM</td>
                                            <td>10001</td>
                                            <td>Visa Card</td>
                                            <td>#T5480</td>
                                            <td>50000</td>
                                        </tr>
                                        <tr>
                                            <th>2</th>
                                            <td>Tue, Oct 25, 2020 12:00 PM</td>
                                            <td>10002</td>
                                            <td>bKash</td>
                                            <td>#T5489</td>
                                            <td>80000</td>
                                        </tr>
                                        <tr>
                                            <th>3</th>
                                            <td>Tue, Oct 20, 2020 12:00 AM</td>
                                            <td>10003</td>
                                            <td>Rocket</td>
                                            <td>#T5482</td>
                                            <td>150700</td>
                                        </tr>

                                        <tr>
                                            <th>4</th>
                                            <td>Tue, Oct 15, 2020 12:00 PM</td>
                                            <td>10004</td>
                                            <td>Master Card</td>
                                            <td>#T5475</td>
                                            <td>190500</td>
                                        </tr>

                                        <tr>
                                            <th>5</th>
                                            <td>Tue, Oct 04, 2020 12:00 AM</td>
                                            <td>10005</td>
                                            <td>Nexus Pay</td>
                                            <td>#T5473</td>
                                            <td>170600</td>
                                        </tr>

                                        <tr>
                                            <th>6</th>
                                            <td>Tue, Oct 30, 2020 12:00 PM</td>
                                            <td>10006</td>
                                            <td>Rocket</td>
                                            <td>#T5490</td>
                                            <td>150200</td>
                                        </tr>
                                        </tbody>
                                    </table>
                                    <div className="all-transaction-page-info">
                                        <div className="data-tables-info">
                                            <p>Showing 1 to 3 of 3 entries</p>
                                        </div>
                                        <div className="data-tables-paginate">
                                            <nav>
                                                <ul className="pagination">
                                                    <li className="page-item disabled">
                                                        <Link className="page-link" to={'/'}>Previous</Link>
                                                    </li>
                                                    <li className="page-item active">
                                                        <Link className="page-link" to={'/'}>1</Link>
                                                    </li>
                                                    <li className="page-item">
                                                        <Link className="page-link" to={'/'}>2</Link>
                                                    </li>
                                                    <li className="page-item">
                                                        <Link className="page-link" to={'/'}>3</Link>
                                                    </li>
                                                    <li className="page-item">
                                                        <Link className="page-link" to={'/'}>Next</Link>
                                                    </li>
                                                </ul>
                                            </nav>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AllTransaction;
