import React, {useEffect, useState} from "react";
import paymentIcon1 from '../../../../Assets/Images/icons/shopping_cart_white_24dp.svg'
import paymentIcon2 from '../../../../Assets/Images/icons/review.png'
import paymentIcon3 from '../../../../Assets/Images/icons/credit-card.svg'
import paymentIcon4 from '../../../../Assets/Images/icons/published_with_changes_white_24dp.svg'
import {Link} from "react-router-dom";
import NumberFormat from "react-number-format";


const SecurePayment = () => {

    const [url, setUrl] = useState(0)

    useEffect(() => {
        setUrl(window.location.pathname)
    });


    return (
        <div className="product-cart-section py-5 custom-shadow">
            <NumberFormat displayType={'text'}/>
            <div className="row">
                <div className="col-md-12">
                    <div className="cart-timeline">
                        <div className="timeline">
                            <div className="liner"/>
                            <div className="liner-colored"
                                 style={url == '/mycart' ? {width: '0%'} : url == '/checkout' ? {width: '27%'} : url == '/secure-payment' ? {width: '53%'} :url=='/payment-completed'? {width: '75%'}: {}}/>
                            <div className="time-line-item">

                                <div className="list-item-icon ">
                                    <Link to={'/mycart'}>

                                        <i className="fas fa-cart-plus secure-checkout-icon"
                                             style={url == '/mycart' ? {background: '#20ace2'} : url == '/checkout' ? {background: '#20ace2'} : url == '/secure-payment' ? {background: '#20ace2'} : url == '/payment-completed' ? {background: '#20ace2'} : {}}/>

                                    {/*    <img src={paymentIcon1} className={'secure-checkout-icon'} alt=""*/}
                                    {/*         style={url == '/mycart' ? {background: '#20ace2'} : url == '/checkout' ? {background: '#20ace2'} : url == '/secure-payment' ? {background: '#20ace2'} : url == '/payment-completed' ? {background: '#20ace2'} : {}}/>*/}
                                    </Link>

                                </div>
                                <p className="cart-list">Shopping Cart</p>
                            </div>
                            <div className="time-line-item">
                                <div className="list-item-icon ">
                                    <Link to={'/checkout'}>
                                        <i className="fas fa-plane secure-checkout-icon "
                                       style={url == '/checkout' ? {background: '#20ace2'} : url == '/secure-payment' ? {background: '#20ace2'} : url == '/payment-completed' ? {background: '#20ace2'} : {}}/>
                                    </Link>
                                </div>
                                <p>Review & Checkout</p>
                            </div>
                            <div className="time-line-item">
                                <div className="list-item-icon">
                                    <Link to={'/secure-payment'}>
                                        <i className="fas fa-plane secure-checkout-icon "
                                       style={url == '/secure-payment' ? {background: '#20ace2'} : url == '/payment-completed' ? {background: '#20ace2'} : {}}/>
                                    </Link>
                                    </div>
                                <p className="margin-left-pay">Payment</p>
                            </div>
                            <div className="time-line-item">
                                <div className="list-item-icon">
                                    <Link to={'/payment-completed'}>
                                        <i className="fas fa-plane secure-checkout-icon "
                                       style={url == '/payment-completed' ? {background: '#20ace2'} : {}}/>
                                    </Link>
                                    </div>
                                <p>Completed</p>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default SecurePayment