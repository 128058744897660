import React from "react";
import CategoryMaster from "./include/CategoryMaster";
const Category = () => {

    return (
        <div className="container">
            <CategoryMaster/>
        </div>
    )
}

export default Category
