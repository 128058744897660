import React, {useEffect, useState} from "react";
import Slider from "react-slick";
import '../../../node_modules/slick-carousel/slick/slick.css';
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import images1 from '../../Assets/Images/flashdeal/1.jpg'
import images2 from '../../Assets/Images/flashdeal/2.jpg'
import images3 from '../../Assets/Images/flashdeal/3.jpg'
import images4 from '../../Assets/Images/flashdeal/4.jpg'
import images5 from '../../Assets/Images/flashdeal/5.jpg'
import images6 from '../../Assets/Images/flashdeal/6.jpg'
import images7 from '../../Assets/Images/product/bannerp.jpg'

const GroceryZone = () => {

    const [grocery, setGrocery] = useState([
        {name:'This is Name', img:images1},
        {name:'This is Name', img:images2},
        {name:'This is Name', img:images3},
        {name:'This is Name', img:images4},
        {name:'This is Name', img:images5},
        {name:'This is Name', img:images6},
        {name:'This is Name', img:images7},
    ]);
    const [settings] = useState({
        dots: false,
        arrows: true,
        infinite: true,
        autoplay: false,
        speed: 500,
        slidesToShow: 4,
        slidesToScroll: 1
    })

    return (
        <div className="container py-2">
            <div className="bg-color custom-shadow">
                <div className="row">
                    <div className="col-md-3 ps-0">
                        <a href="">
                            <div className="GroceryZoneBannar">
                                <img src={images7} alt=""/>
                            </div>
                        </a>
                    </div>
                    <div className="col-md-9 GroceryZonePaddingRight">
                        <div className="GroceryItemInner">
                            <a href="">
                                <h3>Grocery Zone</h3>
                            </a>
                        </div>
                        <div className="GroceryItemSlider">
                            <Slider {...settings}>
                                {grocery.map((item,index)=>(
                                    <div className="GroceryItemSliderImg" key={index}>
                                        <img src={item.img} className={'img-fluid'} alt=""/>
                                        <div className="GroceryItemSliderImgInner">
                                            <h5>{item.name}</h5>
                                        </div>
                                    </div>
                                ))}
                            </Slider>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default GroceryZone
