import React from 'react';
import SideNavBar from "../Include/SideNavBar";
import ClintProfileTopBar from "../Include/ClintProfileTopBar";

const AccontAndSecurity = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="my-dashboard-inner">
                        <div className="row">
                            <div className="col-md-3">
                                <SideNavBar/>
                            </div>
                            <div className="col-md-9">
                                <ClintProfileTopBar/>
                                <div className="user-profile">
                                    <h5 className={'my-order-title text-center'}>Account And Security</h5>
                                </div>
                                <div className="form-opening-header user-profile">
                                    <h5 className={'user-default-shipping-address'}>Account Details</h5>
                                </div>
                                <div className="card user-profile-shipping">
                                    <div className="">
                                        <form  className="profile_form">
                                            <input name="_token" type="hidden"/>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="name">Name</label>
                                                    <input className="form-control" placeholder="Name"
                                                           name="company_name" type="text"
                                                           id="company_name" value={'Zakaria Ahmed Zahid '}/>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="email">Email</label>
                                                    <input className="form-control" placeholder="Email"
                                                           name="address" type="text" id="address" value={'zahidfact@gmail.com'}/>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="number">Phone Number</label>
                                                    <input className="form-control" placeholder="Phone Number"
                                                           name="city" type="text" id="city" value={'01811151115'}/>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="username">Username</label>
                                                    <input className="form-control" placeholder="Username"
                                                           name="region" type="text" id="region" value={'zahid'}/>
                                                </div>

                                                <div className="col-md-12 text-center">
                                                    <button className="add-address-btn" type="submit">
                                                        Update
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>


                                <div className="form-opening-header user-profile">
                                    <h5 className={'user-default-shipping-address'}>Support PIN</h5>
                                </div>
                                <div className="card user-profile-shipping">
                                    <div className="">
                                        <form  className="profile_form">
                                            <input name="_token" type="hidden"/>
                                            <div className="row">
                                                    <p className={'text-center generate-pin'}>61DC8</p>
                                                <div className="col-md-12 text-center">
                                                    <button className="add-address-btn" type="submit">
                                                        Generate New Support PIN
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default AccontAndSecurity;
