import React from 'react'
import ShoppingCart from './include/ShoppingCart'
import SecurePayment from "./include/SecurePayment";
import OrderSummary from "./include/OrderSummary";

const MyCart = () => {
    return (
        <>
        <div className="container">
            <div className="row">
                <div className="col-md-12 ">
                  <SecurePayment/>
                </div>
            </div>
        </div>
            <div className="container">
                <div className="row ">
                    <div className="col-md-9">
                        <ShoppingCart/>
                    </div>
                    <OrderSummary/>
                </div>
            </div>

        </>
    )
}

export default MyCart
