import React, {useState} from 'react'
import Select from 'react-select'
import ShoppingCart from "../../Cart/include/ShoppingCart";

const ShippingIformation = () => {

    const [address, setAddress] = useState([
        {name:'Ashfaqur Rahman', phone:'01770277616',shippingAddress:'Section#7,Road#04,House#695\n' +
                'Mirpur 07, Dhaka, Bangladesh, 1216'},
        {name:'Younis Rahman', phone:'01770277616',shippingAddress:'Section#7,Road#04,House#695\n' +
                'Mirpur 07, Dhaka, Bangladesh, 1216'},
        {name:'Younis Rahman', phone:'01770277616',shippingAddress:'Section#7,Road#04,House#695\n' +
                'Mirpur 07, Dhaka, Bangladesh, 1216'},
        {name:'Younis Rahman', phone:'01770277616',shippingAddress:'Section#7,Road#04,House#695\n' +
                'Mirpur 07, Dhaka, Bangladesh, 1216'},
    ]);

    const [searchOption, setSearchOption] = useState([
        {value: 'chocolate', label: 'Dhaka'},
        {value: 'strawberry', label: 'Rajshaji'},
        {value: 'vanilla', label: 'Jessore'}
    ])

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            // width: '100%',
            // fontSize: '13px',
            outline: 'none',
            border: 'none',
            zIndex: 9999,


        }),
        control: (provided, state) => ({
            ...provided,
            //  width: '1000%',
            // fontSize: '13px',
            outline: 'none',
            // border: '',
            boxShadow: 'none',
            background: '#f9f9f9',
            border: 'none',
            borderBottomLeftRadius: '20px',
            borderTopLeftRadius: '20px',


        }),
        option: (provided, state) => ({
            ...provided,
            "&:hover": {
                backgroundColor: "silver"
            },

        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            width: '0',
            position: 'relative',
        }),


    }
    return (
        <>

            <div className="shipping-information-inner">
                <p className="shipping-information">Shipping Info</p>
                <div className="row align-items-center">
                    <div className="col-md-6">
                        <div className="shipping-address-flex">
                            <div className="address-name-and-phone">
                                <p><strong>Ashfaqur Rahman</strong></p>
                                <p><strong>+880 01770277616</strong></p>
                                <p>Section#7,Road#04,House#695
                                    Mirpur 07, Dhaka, Bangladesh, 1216</p>
                            </div>
                            <div className="shipping-address-edit-btn">
                                <button className={'set-as-default-btn'}>Set as Default</button>
                                <div className="edit-and-delete-icon-for-shipping">
                                    <span><i className="fas fa-edit"/></span>
                                    <span><i className="fas fa-trash-alt"/></span>
                                </div>
                            </div>
                        </div>

                    </div>
                    <div className="col-md-6">
                        <div className="new-shipping-address">
                            <button className={'add-new-shipping'} data-bs-toggle="modal" data-bs-target="#newAddress">
                                Add new +
                            </button>
                            <button className={'add-new-shipping'} data-bs-toggle="modal"
                                    data-bs-target="#othersAddresses">
                                Select addresses
                            </button>
                        </div>

                    </div>
                </div>
            </div>
            <ShoppingCart/>

            {/*code start modal for Add a new address*/}
            <div className="modal fade" id="newAddress">
                <div className="modal-dialog modal-dialog-custom-width">
                    <div className="modal-content">
                        <div className="modal-header shipping-modal-header-section">

                            <h5 className="modal-title">Add new</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"/>
                        </div>
                        <div className="modal-body p-0">
                            <div className="row shipping-from-padding">
                                <form>
                                    <div className="mb-3">
                                        <input type="text" className="form-control"
                                               placeholder={'Enter Your Full Name'}/>
                                    </div>
                                    <div className="mb-3">
                                        <input type="number" className="form-control"
                                               placeholder={"Enter Your mobile Number"}/>
                                    </div>
                                    <div className="mb-3">
                                        <div className="input-group">
                                            <div className="category-search-area padding-for-city-zip-code-select">
                                                <Select
                                                    options={searchOption}
                                                    styles={customStyles}
                                                    placeholder={'District'}
                                                />
                                            </div>
                                            <input type="number"
                                                   className="form-control padding-for-city-zip-code-select"
                                                   placeholder={"City"}/>
                                            <input type="number"
                                                   className="form-control padding-for-city-zip-code-select"
                                                   placeholder={"Zip Code"}/>
                                        </div>
                                    </div>
                                    <div className="mb-3">
                                        <textarea rows="4" className="form-control" placeholder="Detail Address"/>
                                    </div>
                                    <div className="modal-footer modal-border-none shipping-save-btn">
                                        <div className="default-as-check-box">
                                            <input className="form-check-input" type="checkbox"/>
                                            <span>Set as us</span>
                                        </div>

                                        <button type="button" className="save-btn">Save</button>
                                    </div>
                                </form>
                            </div>
                        </div>

                    </div>
                </div>
            </div>

            {/*code end modal for Add a new address*/
            }

            {/*code start modal for Select others addresses*/
            }
            <div className="modal fade" id="othersAddresses">
                <div className="modal-dialog new-select-modal-custom-width">
                    <div className="modal-content">
                        <div className="modal-header shipping-modal-header-section">
                            <h5 className="modal-title">Select others addresses</h5>
                            <button type="button" className="btn-close" data-bs-dismiss="modal"/>
                        </div>
                        <div className="modal-body address-select-modal-body">
                            <div className="row border-bottom">
                                {address.map((item,index)=>(
                                    <div className="col-md-6" key={index}>
                                        <div className="shipping-address-flex custom-margin-shipping">
                                            <div className="address-name-and-phone">
                                                <p><strong>{item.name}</strong></p>
                                                <p><strong>{item.phone}</strong></p>
                                                <p>{item.shippingAddress}</p>
                                            </div>
                                            <div className="shipping-address-edit-btn">
                                                <button className={'set-as-default-btn'}>Set as Default</button>
                                                <div className="edit-and-delete-icon-for-shipping">
                                                    <span><i className="fas fa-edit"/></span>
                                                    <span><i className="fas fa-trash-alt"/></span>
                                                </div>
                                            </div>
                                        </div>
                                  </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            {/*code end modal for Select others addresses*/
            }
        </>
    )
}

export default ShippingIformation
