import React, {useState} from 'react'
import images1 from '../../Assets/Images/orponpic/1.png'
import images2 from '../../Assets/Images/orponpic/2.png'
import images3 from '../../Assets/Images/orponpic/3.png'
import images4 from '../../Assets/Images/orponpic/4.png'
import images5 from '../../Assets/Images/orponpic/5.png'
import images6 from '../../Assets/Images/orponpic/6.png'
import images7 from '../../Assets/Images/product/bannerp.jpg'
import icon4 from '../../Assets/Images/home-page-icon/hot.png'


const HotCategory = () => {
    const [hotCategory, setHotCategory] = useState([
        {productName: 'Mobile', image: images1},
        {productName: 'Laptop', image: images2},
        {productName: 'Wach', image: images3},
        {productName: 'camera', image: images4},
        {productName: 'Tab', image: images5},
        {productName: 'Router', image: images6},
    ])
    return (
        <div className="container py-2  ">
            <div className="bg-color custom-shadow">
                <div className="row">
                    <div className="col-md-12 g-1">
                        <div className="flash-deal-inner">
                            <div className="img-and-title">
                                <img src={icon4} alt=""/>
                                <h3 className={'new-font-size'}>Hot Category</h3>
                            </div>
                            <a href="">View More <span><i className="fas fa-arrow-circle-right"/></span></a>
                        </div>
                    </div>
                </div>
                <div className="row g-1 ">
                    <div className="col-md-8">
                        <div className="row g-1">
                            {hotCategory.map((item, index) => (
                                <div className="col-md-6 " key={index}>
                                    <a href="">
                                        <div className=" hotCatagorysBanner my-card img-relative">
                                            <img className="image" src={item.image} alt=""/>
                                            <div className="hotCatagorysInner">
                                                <h5>{item.productName}</h5>
                                            </div>
                                        </div>
                                    </a>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="col-md-4">
                        <div className="hotCatagorysSideBanner my-card">
                            <a href=""><img className="img-fluid" src={images7} alt=""/></a>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}

export default HotCategory


