import React, { useState } from 'react'
import images1 from '../../Assets/Images/topbrand/1.png'
import images2 from '../../Assets/Images/topbrand/2.png'
import images3 from '../../Assets/Images/topbrand/3.png'
import images4 from '../../Assets/Images/topbrand/4.png'
import images5 from '../../Assets/Images/topbrand/5.png'
import images6 from '../../Assets/Images/topbrand/6.png'
import { Link, NavLink } from "react-router-dom";
import icon3 from "../../Assets/Images/home-page-icon/brand.png";

const TopBrands = () => {
    const [topBrands, setTopBrands] = useState([
        { product: '1500 Likes', image: images1 },
        { product: '1500 Likes', image: images2 },
        { product: '1500 Likes', image: images3 },
        { product: '1500 Likes', image: images4 },
        { product: '1500 Likes', image: images5 },
        { product: '1500 Likes', image: images6 },
    ])
    return (
        <div className="container py-2">
            <div className="bg-color custom-shadow">
                <div className="row">
                    <div className="col-md-12 g-1">
                        <div className="flash-deal-inner">
                            <div className="img-and-title">
                                <img src={icon3} alt=""/>
                                <h3 className={'new-font-size'}>Top Brands</h3>
                            </div>
                            <a href="">View More  <span><i className="fas fa-arrow-circle-right"/></span></a>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {topBrands.map((item, index) => (
                        <div className="col-md-2 g-1" key={index}>
                            <div className="card-inner">
                                <div className="card  my-card  img-relative" >
                                    <Link to="/product"> <img src={item.image} className="card-img-top image" alt="..." /> </Link>
                                    <div className="card-body">
                                        <button className="btn rounded-pill top-brand-button-color" type="button">{item.product}</button>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default TopBrands
