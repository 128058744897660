import React from 'react';

const ClintProfileTopBar = () => {
    return (
        <><div className="my-dashboard-main-content">
            <div className="my-dashboard-overview">
            <div className="row my-profile-top-bar-shadow">
                <div className="col-md-6">
                    <div className="my-profile-top-bar">
                        <span><i className="fas fa-user"/></span>
                        <div className="my-address">
                            <h6>Ashfaqur Rahman</h6>
                            <p><i className="fas fa-envelope my-address-email-icon"/> ashfak@gmail.com</p>
                            <p>Phone:01775487558</p>
                        </div>
                    </div>
                </div>
                <div className="col-md-6">
                    <div className="my-profile-notification-live-chat">
                        <span><i className="fas fa-bell"/></span>
                        <div className="merchant-follow-live-chat">
                            <button type="button"
                                    className="chat-btn profile-live-chat-bg"><i className="fas fa-comment-dots"/> Live Chat
                            </button>
                        </div>
                    </div>
                </div>
            </div>
            </div>
        </div>
        </>
    );
};

export default ClintProfileTopBar;
