import React, {useState} from 'react'
import logo from '../../../Assets/Images/brand_logo.png'
import images2 from '../../../Assets/Images/newarival/2.jpg'
import specificationImage1 from '../../../Assets/Images/product/1.webp'
import specificationImage2 from '../../../Assets/Images/product/2.webp'
import specificationImage3 from '../../../Assets/Images/product/3.webp'
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import {Link} from "react-router-dom";
import {SideBySideMagnifier, MOUSE_ACTIVATION,} from "react-image-magnifiers";
import {Accordion,AccordionItem,AccordionItemHeading,AccordionItemButton,AccordionItemPanel,} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';
import Flashsell from "./Flashsell";

const Product = () => {

    const [storeProduct, setStoreProduct] = useState([
        {storeProductName: 'iphone'},
        {storeProductName: 'Laptop'},
        {storeProductName: 'Wach'},
        {storeProductName: 'Mobile'},
        {storeProductName: 'Tv'},
        {storeProductName: 'Desktop'},
    ])

    const [progress, setprogress] = useState([
        {star: '5 Stars', progressParsent: '90%', progressWidth: '90%'},
        {star: '4 Stars', progressParsent: '50%', progressWidth: '50%'},
        {star: '3 Stars', progressParsent: '20%', progressWidth: '20%'},
        {star: '2 Stars', progressParsent: '20%', progressWidth: '20%'},
        {star: '1 Stars', progressParsent: '10%', progressWidth: '20%'},
    ])
    const [specifications, setSpecifications] = useState([
        {productName: 'Brand Name', productValue: 'PUGB'},
        {productName: 'Origin', productValue: 'CN(Origin)'},
        {productName: 'Features', productValue: 'Transparent Plating Square Frame'},
        {productName: 'Package', productValue: 'opp package'},
        {productName: 'Compatible Model 5', productValue: 'for iphone se 2020'},
        {productName: 'Brand Name', productValue: 'PUGB'},
        {productName: 'Origin', productValue: 'CN(Origin)'},
        {productName: 'Features', productValue: 'Transparent Plating Square Frame'},
        {productName: 'Package', productValue: 'opp package'},
        {productName: 'Compatible Model 5', productValue: 'for iphone se 2020'},
        {productName: 'Brand Name', productValue: 'PUGB'},
        {productName: 'Origin', productValue: 'CN(Origin)'},
        {productName: 'Features', productValue: 'Transparent Plating Square Frame'},
        {productName: 'Package', productValue: 'opp package'},
        {productName: 'Compatible Model 5', productValue: 'for iphone se 2020'},
        {productName: 'Brand Name', productValue: 'PUGB'},
        {productName: 'Origin', productValue: 'CN(Origin)'},
        {productName: 'Features', productValue: 'Transparent Plating Square Frame'},
        {productName: 'Package', productValue: 'opp package'},
        {productName: 'Compatible Model 5', productValue: 'for iphone se 2020'},
        {productName: 'Brand Name', productValue: 'PUGB'},
        {productName: 'Origin', productValue: 'CN(Origin)'},
        {productName: 'Features', productValue: 'Transparent Plating Square Frame'},
        {productName: 'Package', productValue: 'opp package'},
        {productName: 'Compatible Model 5', productValue: 'for iphone se 2020'},
        {productName: 'Brand Name', productValue: 'PUGB'},
        {productName: 'Origin', productValue: 'CN(Origin)'},
        {productName: 'Features', productValue: 'Transparent Plating Square Frame'},
        {productName: 'Package', productValue: 'opp package'},
        {productName: 'Compatible Model 5', productValue: 'for iphone se 2020'},
        {productName: 'Brand Name', productValue: 'PUGB'},
        {productName: 'Origin', productValue: 'CN(Origin)'},
        {productName: 'Features', productValue: 'Transparent Plating Square Frame'},
        {productName: 'Package', productValue: 'opp package'},
        {productName: 'Compatible Model 5', productValue: 'for iphone se 2020'},
        {productName: 'Brand Name', productValue: 'PUGB'},
        {productName: 'Origin', productValue: 'CN(Origin)'},
        {productName: 'Features', productValue: 'Transparent Plating Square Frame'},
        {productName: 'Package', productValue: 'opp package'},
        {productName: 'Compatible Model 5', productValue: 'for iphone se 2020'},
        {productName: 'Brand Name', productValue: 'PUGB'},
        {productName: 'Origin', productValue: 'CN(Origin)'},
        {productName: 'Features', productValue: 'Transparent Plating Square Frame'},
        {productName: 'Package', productValue: 'opp package'},
        {productName: 'Compatible Model 5', productValue: 'for iphone se 2020'},
    ])

    const [recommendedProduct]=useState([
        {img:images2, price:145782},
        {img:images2, price:145782},
        {img:images2, price:145782},
        {img:images2, price:145782},
        {img:images2, price:145782},
        {img:images2, price:145782},
        {img:images2, price:145782},
        {img:images2, price:145782},
        {img:images2, price:145782},
        {img:images2, price:145782},
    ])

    const [faq]=useState([
        {title:'Is free will real or just Is free will real or just an illusion?', description:'Exercitation in fugiat est ut ad ea cupidatat ut in cupidatat occaecat ut occaecat consequat est minim minim esse tempor laborum consequat esse adipisicing eu reprehenderit enim.'},
        {title:'Is free will Is free will real or just an illusion? illusion?', description:'Exercitation in fugiat est ut ad ea cupidatat ut in cupidatat occaecat ut occaecat consequat est minim minim esse tempor laborum consequat esse adipisicing eu reprehenderit enim.'},
        {title:'Is free will Is free will real or just an illusion? an illusion?', description:'Exercitation in fugiat est ut ad ea cupidatat ut in cupidatat occaecat ut occaecat consequat est minim minim esse tempor laborum consequat esse adipisicing eu reprehenderit enim.'},
        {title:'Is free will real orIs free will real or just an illusion?', description:'Exercitation in fugiat est ut ad ea cupidatat ut in cupidatat occaecat ut occaecat consequat est minim minim esse tempor laborum consequat esse adipisicing eu reprehenderit enim.'},
        {title:'Is free will real Is free will real or just an illusion?', description:'Exercitation in fugiat est ut ad ea cupidatat ut in cupidatat occaecat ut occaecat consequat est minim minim esse tempor laborum consequat esse adipisicing eu reprehenderit enim.'},
        {title:'Is free will Is free will real or just an illusion?', description:'Exercitation in fugiat est ut ad ea cupidatat ut in cupidatat occaecat ut occaecat consequat est minim minim esse tempor laborum consequat esse adipisicing eu reprehenderit enim.'},
        {title:'Is free will real or jIs free will real or just an illusion?', description:'Exercitation in fugiat est ut ad ea cupidatat ut in cupidatat occaecat ut occaecat consequat est minim minim esse tempor laborum consequat esse adipisicing eu reprehenderit enim.'},
        {title:'Is free will real or just Is free will real or just an illusion?', description:'Exercitation in fugiat est ut ad ea cupidatat ut in cupidatat occaecat ut occaecat consequat est minim minim esse tempor laborum consequat esse adipisicing eu reprehenderit enim.'},
        {title:'Is free will Is free will real or just an illusion?', description:'Exercitation in fugiat est ut ad ea cupidatat ut in cupidatat occaecat ut occaecat consequat est minim minim esse tempor laborum consequat esse adipisicing eu reprehenderit enim.'},
        {title:'Is free will real Is free will real or just an illusion?', description:'Exercitation in fugiat est ut ad ea cupidatat ut in cupidatat occaecat ut occaecat consequat est minim minim esse tempor laborum consequat esse adipisicing eu reprehenderit enim.'},
        {title:'Is free will real or just Is free will real or just an illusion?', description:'Exercitation in fugiat est ut ad ea cupidatat ut in cupidatat occaecat ut occaecat consequat est minim minim esse tempor laborum consequat esse adipisicing eu reprehenderit enim.'},
        {title:'Is free will real or Is free will real or just an illusion?', description:'Exercitation in fugiat est ut ad ea cupidatat ut in cupidatat occaecat ut occaecat consequat est minim minim esse tempor laborum consequat esse adipisicing eu reprehenderit enim.'},
        {title:'Is free will real or just Is free will real or just an illusion?', description:'Exercitation in fugiat est ut ad ea cupidatat ut in cupidatat occaecat ut occaecat consequat est minim minim esse tempor laborum consequat esse adipisicing eu reprehenderit enim.'},
        {title:'Is free Is free will real or just an illusion?', description:'Exercitation in fugiat est ut ad ea cupidatat ut in cupidatat occaecat ut occaecat consequat est minim minim esse tempor laborum consequat esse adipisicing eu reprehenderit enim.'},
        {title:'Is free will real or just an illusion?', description:'Exercitation in fugiat est ut ad ea cupidatat ut in cupidatat occaecat ut occaecat consequat est minim minim esse tempor laborum consequat esse adipisicing eu reprehenderit enim.'},
    ])

    const [quantity, setQuantity] = useState(1)

    const handleInputChange = (e) => {
        setQuantity(e.target.value)
    }
    const decreaseQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1)
        }
    }
    const increaseQuantity = () => {
        setQuantity(parseInt(quantity) + 1)
    }
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <nav style={{'--bs-breadcrumb-divider': '">"'}} aria-label="breadcrumb">
                            <ol className="breadcrumb">
                                <li className="breadcrumb-item"><Link to="/">Home</Link></li>
                                <li className="breadcrumb-item"><Link to="/">Category</Link></li>
                                <li className="breadcrumb-item"><Link to="/">Sub Category</Link></li>
                                <li className="breadcrumb-item"><Link to={'/'}>Product Title</Link></li>
                            </ol>
                        </nav>
                    </div>
                    <div className="col-md-9">
                        <div className="row white-background product-img-padding h-100 custom-shadow">
                            <div className="col-md-5 p-0">
                                <div className="product-image">
                                    <SideBySideMagnifier
                                        imageSrc={images2}
                                        imageAlt="product-image"
                                        mouseActivation={MOUSE_ACTIVATION.HOVER} // Optional
                                        alwaysInPlace={true}
                                    />
                                </div>
                                <div className="small-product-img">
                                    <img src={images2} alt=""/>
                                    <img src={images2} alt=""/>
                                    <img src={images2} alt=""/>
                                    <img src={images2} alt=""/>
                                    <img src={images2} alt=""/>
                                </div>
                            </div>
                            <div className="col-md-7 pe-0">
                                <div className="product-margin">
                                    <Flashsell/>
                                    <div className="product-title product-heading-color">
                                        <h6>আইফোন 12 11 প্রো ট্রান্সপারেন্ট কেসের জন্য আইফোন 12 11 প্রো ম্যাক্স ব্যাক
                                            পিসি+টিপিইউ শকপ্রুফ ফুল লেন্স প্রোটেকশন কভার জন্য জয়রুম ক্লিয়ার কেস</h6>
                                    </div>
                                    <div className="star-review-order">
                                        <div className="star-icon">
                                            <a href="/">
                                                <span className="star-icon-color"><i className="fas fa-star"/></span>
                                                <span className="star-icon-color"><i className="fas fa-star"/></span>
                                                <span className="star-icon-color"><i className="fas fa-star"/></span>
                                                <span className="star-icon-color"><i className="fas fa-star"/></span>
                                                <span className="star-icon-color"><i className="fas fa-star"/></span>
                                                <span className="rating">5.0</span>
                                                <span className="rating-arrow-icon"><i
                                                    className="fas fa-chevron-down"/></span>
                                            </a>
                                        </div>
                                        <a href="/" className={'review-font-size'}><span className="product-reviews">58 Reviews</span></a>
                                        <span className="product-order-number">112 orders</span>
                                    </div>
                                    <div className="product-price">
                                        <div className="product-price-card">
                                            <h5> ৳ 1980</h5>
                                            <p className={'product-discount'}><small><del>৳ 2200 </del>(-10%)</small></p>
                                        </div>
                                        <div className="price-separator"></div>
                                        <div className="product-price-card">
                                            <h5> ৳ 1980</h5>
                                            <p className={'product-discount'}><small><del>৳ 2200 </del>(-10%)</small></p>
                                        </div>

                                    </div>
                                    <div className="product-color product-heading-color">
                                        <h6>Color:</h6>
                                        <div className="product-color-img">
                                            <a href="/"><img src={images2} alt=""/></a>
                                            <a href="/"><img src={images2} alt=""/></a>
                                            <a href="/"><img src={images2} alt=""/></a>
                                        </div>
                                    </div>
                                    <div className="product-size product-heading-color">
                                        <h6>Size:</h6>
                                        <div className="product-size-inner">
                                            <ul>
                                                <li><span>S</span></li>
                                                <li><span>M</span></li>
                                                <li><span>L</span></li>
                                                <li><span>XL</span></li>
                                            </ul>
                                        </div>
                                    </div>
                                    <div className="product-quantity product-heading-color">
                                        <h6>Quantity:</h6>
                                        <div className="product-quantity-inner">
                                            <button className="minus-btn" onClick={decreaseQuantity}><i
                                                className="fas fa-minus"/></button>
                                            <input
                                                className="quantity-input"
                                                onChange={handleInputChange}
                                                name="quantity"
                                                type="number"
                                                value={quantity}
                                                max="20"/>
                                            <button className="plus-btn" onClick={increaseQuantity}><i
                                                className="fas fa-plus"/></button>
                                            <span className="product-available">20 pieces available</span>
                                        </div>
                                        <button className="product-emi"><span
                                            className="product-heading-color">EMI:</span> Available
                                        </button>
                                    </div>
                                    {/* <div className="product-delivery-time product-heading-color">
                                    <h6>Estimated Delivery Time: <span>5-7 Days</span></h6>
                                </div> */}
                                    <div className="buy-now-add-to-cart-btn">
                                        <div className="buy-now-add-to-cart-btn-inner">
                                            <button className="btn rounded-pill buy-now-btn-color">Buy Now</button>
                                            <button
                                                className="btn rounded-pill buy-now-btn-color add-to-cart-btn-color">Add
                                                to Cart
                                            </button>
                                            <button className="product-fav-btn"><i className="fas fa-heart"/></button>
                                            <span className="product-heading-color">Add to Wishlist (250)</span>
                                        </div>
                                    </div>

                                    <div className={'offline-order'}>
                                        <p className={'offline-order-title'}>Offline Order</p>
                                        <p className={'offline-call-number'}>Call Now 09638147678</p>
                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                    <div className="col-md-3 delivery-section-z-index">
                        <div className="delivery-information">
                            <Tabs>
                                <TabList className="delivery-information-tabs">
                                    <Tab>তথ্য</Tab>
                                    <Tab>অনুরূপ পণ্য</Tab>
                                </TabList>

                                <TabPanel>
                                    <div className="dark-background  py-3">
                                        <div className="row pb-3">
                                            <div className="col-7">
                                                <div className="sold-by-section">
                                                    <small>Sold by</small>
                                                </div>
                                            </div>
                                            <div className="col-5 text-end">
                                                <div className="sold-by-section">
                                                    <a href="/"><i className="fas fa-comment-dots"/> Contact</a>
                                                </div>
                                            </div>
                                            <div className="row">
                                                <div className="col-12">
                                                    <div className="store-information">
                                                        <div className="store-logo">
                                                            <img src={logo} alt="store-logo"/>
                                                        </div>
                                                        <div className="store-name">
                                                            <h6>Aladiner-Prodip</h6>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                        <div className="dark-background border-top border-bottom sold-by-section py-3">
                                            <div className="row">
                                                <div className="col-4">
                                                    <p>Positive Ratting</p>
                                                    <h4>96.0%</h4>
                                                </div>
                                                <div className="col-4">
                                                    <p className="text-center">Ship On Time</p>
                                                    <h4>98%</h4>
                                                </div>
                                                <div className="col-4">
                                                    <p className="text-center">Response Rate</p>
                                                    <h4>96.0%</h4>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="store-section">
                                            <a href="/">GO TO STORE</a>
                                        </div>
                                    </div>
                                    <div className="sold-by-section dark-background mt-1 py-3">
                                        <div className="row mb-3">
                                            <div className="col-10">
                                                <h5>Delivery Option</h5>
                                            </div>
                                            <div className="col-2 text-end ">
                                                <a href="/"><i className="fas fa-exclamation-circle"/></a>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-2 delivery-section-icon">
                                                <i className="fas fa-map-marker-alt"/>
                                            </div>
                                            <div className="col-8">
                                                <p> Barishal, Barishal - Agailjhara, Agailjhara Bazar </p>
                                            </div>
                                            <div className="col-2">
                                                <button className={'btn btn-light btn-sm color-sm-btn'}>
                                                    <i className={'fas fa-edit'}/></button>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-2 delivery-section-icon">
                                                <span><i className="fas fa-caravan"/></span>
                                            </div>
                                            <div className="col-7">
                                                <h6>Home Delivery</h6>
                                                <small>4 - 13 days</small>
                                            </div>
                                            <div className="col-3 text-end">
                                                <h6>৳ 35</h6>
                                            </div>
                                        </div>
                                        <div className="row mt-3 justify-content-center mt-3">
                                            <div className="col-10 free-shipping-section">
                                                <p>Enjoy free shipping promotion with minimum spend of ৳ 30,000
                                                    from <a href="">Happy Shopping.</a></p>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className='col-2 delivery-section-icon'>
                                                <i className="fas fa-file-invoice-dollar"/>
                                            </div>
                                            <div className="col-10">
                                                <h6>Cash on Delivery Available</h6>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-9">
                                                <h5>Return & Warranty</h5>
                                            </div>
                                            <div className="col-3 text-end">
                                                <a href="/"><i className="fas fa-exclamation-circle"/></a>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-2 delivery-section-icon">
                                                <i className="fas fa-undo-alt"/>
                                            </div>
                                            <div className="col-10">
                                                <h6>7 Days Returns</h6>
                                                <p>Change of mind is not applicable</p>
                                            </div>
                                        </div>
                                        <div className="row mt-3">
                                            <div className="col-2 delivery-section-icon">
                                                <i className="fas fa-times-circle"/>
                                            </div>
                                            <div className="col-10">
                                                <h6>Warranty not available</h6>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                                <TabPanel>
                                    <div className="dark-background  py-3">
                                        <div className="row">
                                            <div className="col-6 g-1">
                                                <div className="card official-store-img">
                                                    <a href=""> <img src={images2} className="" alt="..."/> </a>
                                                    <div className="card-body official-store-price ">
                                                        <span className="flash-deal-price">৳  1080.2</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 g-1">
                                                <div className="card official-store-img">
                                                    <a href=""> <img src={images2} className="" alt="..."/> </a>
                                                    <div className="card-body official-store-price ">
                                                        <span className="flash-deal-price">৳  1080.2</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="row">
                                            <div className="col-6 g-1">
                                                <div className="card official-store-img">
                                                    <a href=""> <img src={images2} className="" alt="..."/> </a>
                                                    <div className="card-body official-store-price ">
                                                        <span className="flash-deal-price">৳  1080.2</span>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-6 g-1">
                                                <div className="card official-store-img">
                                                    <Link to={'/product'}> <img src={images2} className="" alt="..."/> </Link>
                                                    <div className="card-body official-store-price ">
                                                        <span className="flash-deal-price">৳  1080.2</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </TabPanel>
                            </Tabs>
                        </div>
                    </div>
                </div>
            </div>
            <div className="container mt-4">
                <div className="row">
                    <div className="col-md-9">
                        <Tabs className={'product-details white-background custom-shadow'}>
                            <TabList className={'product-details-tab-list'}>
                                <Tab>Product Details</Tab>
                                <Tab>Product Specifications</Tab>
                                <Tab>Review</Tab>
                                <Tab>FAQ</Tab>
                            </TabList>
                            <TabPanel>
                                <div className="row p-5">
                                    <div className="col-md-12">
                                        <h5>Luxury Plating Square Frame Matte Soft Silicone Case for iPhone 11 12 Pro
                                            Max Mini XR X XS 7 8 Plus SE 2020 Shockproof Transparent Cover</h5>
                                        <p className={'mt-5'}>Feature: </p>
                                        <p>
                                            1.All series （from 7-12 pro max）with Straight edge design,with our case can
                                            make your phone looks like the new 12,
                                        </p>
                                        <p>
                                            2.matte back design,anti-fingerprint;
                                        </p>
                                        <p>
                                            3.full coverage camera protection, protect the lens;
                                        </p>
                                        <p>
                                            4.soft tpu,easy to install and remove.
                                        </p>
                                        <p>
                                            5.Perfectly restore the real phone, show the beauty of the original mach
                                        </p>
                                        <img src={specificationImage1} className={'img-fluid mt-4'} alt={'Details'}/>
                                        <h5 className={'my-5'}>Luxury Plating Square Frame Matte Soft Silicone Case for
                                            iPhone 11 12 Pro
                                            Max Mini XR X XS 7 8 Plus SE 2020 Shockproof Transparent Cover</h5>
                                        <img src={specificationImage2} className={'img-fluid'} alt={'Details'}/>
                                        <h5 className={'my-5'}>Luxury Plating Square Frame Matte Soft Silicone Case for
                                            iPhone 11 12 Pro
                                            Max Mini XR X XS 7 8 Plus SE 2020 Shockproof Transparent Cover</h5>
                                        <img src={specificationImage3} className={'img-fluid'} alt={'Details'}/>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="row py-5">
                                    <div className="col-md-12 px-4">
                                        <div className="Product-Specifications-section">
                                            <ul>
                                                <div className="row">
                                                    {specifications.map((item, index) => (
                                                        <div className="col-md-6 " key={index}>
                                                            <li>
                                                                <span
                                                                    className="product-name gray-text">{item.productName}: </span>
                                                                <span
                                                                    className="product-value ">{item.productValue}</span>
                                                            </li>
                                                        </div>
                                                    ))}
                                                </div>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>



                            <TabPanel>
                                <div className="row py-5 align-items-center">
                                    <div className="col-md-6 px-4">
                                        <div className="coustomer-review-section ">
                                            <p>Customer Reviews (125)</p>
                                        </div>
                                        {progress.map((item, index) => (
                                            <div className="review-display pb-2" key={index}>
                                                <div className="star-label">
                                                    <span>{item.star}</span>
                                                </div>
                                                <div className="star-progress">
                                                    <div className="progress">
                                                        <div className="progress-bar" role="progressbar"
                                                             style={{width: item.progressWidth}} aria-valuenow="75"
                                                             aria-valuemin="0" aria-valuemax="100"/>
                                                    </div>
                                                </div>
                                                <div className="start-count text-end">
                                                    <button
                                                        className={'btn btn-sm btn-outline-warning coustom-progress-btn'}>{item.progressParsent}</button>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                    <div className="col-md-6 ">
                                        <div className="total-reviews-section">
                                            <p className="gray-text">4.8/5
                                                <span className="star-icon-color">
                                                    <i className="fas fa-star"/>
                                                    <i className="fas fa-star"/>
                                                    <i className="fas fa-star"/>
                                                    <i className="fas fa-star"/>
                                                    <i className="fas fa-star"/>
                                                </span>
                                            </p>
                                        </div>
                                    </div>

                                    <div className="filter-by-section">
                                        <div className="row align-items-center ">
                                            <div className="col-md-6">
                                                <h5 className="gray-text">Product Reviews</h5>
                                            </div>
                                            <div className="col-md-6 d-flex filter-content">
                                                <div className="dropdown">
                                                    <button
                                                        className="dropdown-toggle coustom-dropdown-filter gray-text"
                                                        type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown"
                                                        aria-expanded="false">
                                                        <p><span className="pr-2"><i
                                                            className="fas fa-exchange-alt"/></span> Sort: Relevance</p>
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                                        <li><a className="dropdown-item" href="#">Relevance</a></li>
                                                        <li><a className="dropdown-item" href="#">Recent</a></li>
                                                        <li><a className="dropdown-item" href="#">Rating: High to
                                                            Low</a></li>
                                                        <li><a className="dropdown-item" href="#">Rating: Low to
                                                            High</a></li>
                                                    </ul>
                                                </div>
                                                <div className="dropdown">
                                                    <button
                                                        className=" dropdown-toggle coustom-dropdown-filter gray-text"
                                                        type="button" id="dropdownMenuButton2" data-bs-toggle="dropdown"
                                                        aria-expanded="false">
                                                        <p><span className="pr-2"><i
                                                            className="fas fa-filter"/></span> Filter: All Star</p>
                                                    </button>
                                                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton2">
                                                        <li><a className="dropdown-item" href="#">5 Star</a></li>
                                                        <li><a className="dropdown-item" href="#">4 Star</a></li>
                                                        <li><a className="dropdown-item" href="#">3 Star</a></li>
                                                        <li><a className="dropdown-item" href="#">2 Star</a></li>
                                                        <li><a className="dropdown-item" href="#">1 Star</a></li>
                                                        <li>
                                                            <hr className="dropdown-divider"/>
                                                        </li>
                                                        <li><a className="dropdown-item" href="#">All Star</a></li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="coustomer-reviews-section py-3">
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0">
                                                        <img src={images2} alt="..."/>
                                                    </div>
                                                    <div className="flex-grow-1 ms-2">
                                                        <div className="single-reviews">
                                                            <p className="name-of-reviewrs">Naimu Hassan</p>
                                                            <p className="">I like your store product </p>
                                                        </div>
                                                        <p className="hour-like-reply pb-2"><span
                                                            className="review-hour pe-3">5h</span><span
                                                            className="review-like pe-3">Like</span><span
                                                            className="review-reply">Reply</span></p>
                                                        <div className="d-flex">
                                                            <div className="flex-shrink-0">
                                                                <img src={specificationImage3} alt="..."/>
                                                            </div>
                                                            <div className="flex-grow-1 ms-2">
                                                                <div className="single-reviews">
                                                                    <p className="name-of-reviewrs">Younis Rahman</p>
                                                                    <p className="">Thank you sir </p>
                                                                </div>
                                                                <p><span className="review-hour pe-3">5h</span><span
                                                                    className="review-like pe-3">Like</span><span
                                                                    className="review-reply">Reply</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                            <TabPanel>
                                <div className="row">
                                    <div className="col-md-12">
                                        <div className="faq-section">
                                            <Accordion allowZeroExpanded={true}>
                                                {faq.map((item,index)=>(
                                                    <AccordionItem key={index}>
                                                        <AccordionItemHeading>
                                                            <AccordionItemButton>
                                                                {item.title}
                                                            </AccordionItemButton>
                                                        </AccordionItemHeading>
                                                        <AccordionItemPanel>
                                                            <p>
                                                                {item.description}
                                                            </p>
                                                        </AccordionItemPanel>
                                                    </AccordionItem>
                                                ))}
                                            </Accordion>
                                        </div>
                                    </div>
                                </div>
                            </TabPanel>
                        </Tabs>
                    </div>

                    <div className="col-md-3">
                        <div className="Store-categories white-background mb-2 p-2 custom-shadow ">
                            <h5 className="Store-categories-title">Store Categories</h5>
                            <ul className={'store-category-product-padding'}>
                                {storeProduct.map((item, index) => (
                                    <li className={'store-category-product'} key={index}>
                                        <Link to={'/product'}>{item.storeProductName}</Link>
                                    </li>
                                ))}
                            </ul>
                        </div>
                        <div className="recomended-img custom-shadow">
                            <h5 className="text-center pb-2">Top Selling</h5>
                            <div className="row">
                                {recommendedProduct.map((item,index)=>(
                                <div className="col-6 p-1">
                                    <div className="card" key={index}>
                                        <img src={item.img} className="card-img-top" alt="..."/>
                                        <div className="card-body recomended-card-cody">
                                            <h5 className="card-title gray-text m-0">৳ {item.price}</h5>
                                        </div>
                                    </div>
                                </div>
                                ))}
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Product
