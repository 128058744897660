import React, {useState} from "react";
import ProductCartMaster from "./ProductCartMaster";
import img1 from '../../Assets/Images/flashdeal/2.jpg'
import $ from 'jquery';

const ProductCart = () => {

    const ShowCard = () => {
        $('#cart_item_details').show();
        $('#div_hide').hide();
    }
    const closeDiv = () => {
        $('.cart-item-details').hide();
        $('#div_hide').show();
    }

    const [quantity, setQuantity] = useState(1)

    const handleInputChange = (e) => {
        setQuantity(e.target.value)
    }
    const decreaseQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1)
        }
    }
    const increaseQuantity = () => {
        setQuantity(parseInt(quantity) + 1)
    }

    const [newProduct, setNewProduct] = useState([
        {img: img1, title: 'This a product', price: 547854},
        {img: img1, title: 'This a product', price: 547854},

    ])

    return (
        <>
            <div className="custom-product-cart" onClick={ShowCard} id={'div_hide'}>
                <ProductCartMaster/>
            </div>

            <div className="product-section-new">
                <div className="cart-item-details" id={'cart_item_details'} style={{display: 'none'}}>
                    <p onClick={closeDiv} className={'text-center shopping-continue'}><i
                        className="fas fa-arrow-alt-circle-left"/> Continue Shopping</p>
                    <div className="cart-item-new-product">
                        <p className={'new-product-item text-center'}>Your product Item (100)</p>
                    </div>
                    <div className="new-product-scrolling">
                        <div className="new-product-store-name">
                            <p>Aladiner Prodip</p>
                            {newProduct.map((item, index) => (
                                <div className="new-product-item-show" key={index}>
                                    <div className="product-delete-icon">
                                        <i className="fas fa-times"/>
                                    </div>
                                    <div className="new-product-img">
                                        <img src={item.img} className={'img-fluid'} alt="product-img"/>
                                    </div>
                                    <div className="new-title-img">
                                        <p>{item.title}</p>
                                    </div>
                                    <div className="new-plus-minus-product">
                                        <p className={'new-custom-price'}>৳ {item.price}</p>
                                        <div className="">
                                            <div className="new-product-quantity-inner">
                                                <button className="left-btn" onClick={decreaseQuantity}><i
                                                    className="fas fa-chevron-left"/></button>
                                                <input
                                                    className="new-quantity-input"
                                                    onChange={handleInputChange}
                                                    name="quantity"
                                                    type="number"
                                                    value={quantity}
                                                    max="20"/>
                                                <button className="right-btn" onClick={increaseQuantity}><i
                                                    className="fas fa-chevron-right"/></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                        <div className="new-product-store-name">
                            <p>Daraz</p>
                            {newProduct.map((item, index) => (
                                <div className="new-product-item-show" key={index}>
                                    <div className="product-delete-icon">
                                        <i className="fas fa-times"/>
                                    </div>
                                    <div className="new-product-img">
                                        <img src={item.img} className={'img-fluid'} alt="product-img"/>
                                    </div>
                                    <div className="new-title-img">
                                        <p>{item.title}</p>
                                    </div>
                                    <div className="new-plus-minus-product">
                                        <p className={'new-custom-price'}>৳ {item.price}</p>
                                        <div className="">
                                            <div className="new-product-quantity-inner">
                                                <button className="left-btn" onClick={decreaseQuantity}><i
                                                    className="fas fa-chevron-left"/></button>
                                                <input
                                                    className="new-quantity-input"
                                                    onChange={handleInputChange}
                                                    name="quantity"
                                                    type="number"
                                                    value={quantity}
                                                    max="20"/>
                                                <button className="right-btn" onClick={increaseQuantity}><i
                                                    className="fas fa-chevron-right"/></button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            ))}
                        </div>
                    </div>
                    <div className="checkout-and-total fixed-bottom">
                        <button className={'new-checkout-btn'}>Checkout</button>
                        <p className={'new-total-price'}>Total: ৳ 547841 </p>
                    </div>
                </div>
            </div>


        </>
    )
}
export default ProductCart