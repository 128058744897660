import React, {useState} from "react";
import Nav from "./Include/Nav";



const AboutUs = () => {
    const [about, setAbout]=useState([
        {title:'About', description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias cumque dolores\n' +
                'ducimus earum eligendi fugit impedit ipsam ipsum minus nesciunt, officia, quidem,\n' +
                'voluptates. Accusantium amet animi consectetur deserunt dicta distinctio, est fuga\n' +
                'maiores minus molestiae molestias, nobis quod recusandae sapiente sequi sit, veniam\n' +
                'voluptatem. Accusantium eligendi esse est laboriosam magni!'},
    {title:'Our Target', description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias cumque dolores\n' +
                'ducimus earum eligendi fugit impedit ipsam ipsum minus nesciunt, officia, quidem,\n' +
                'voluptates. Accusantium amet animi consectetur deserunt dicta distinctio, est fuga\n' +
                'maiores minus molestiae molestias, nobis quod recusandae sapiente sequi sit, veniam\n' +
                'voluptatem. Accusantium eligendi esse est laboriosam magni!'}
    ])

    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                    <Nav/>
                    </div>
                    <div className="col-md-12">
                        <div className="others-section bg-white custom-shadow-without-top">
                            <h1 className={'text-center'}>About Us</h1>

                            {about.map((item,index)=>(
                                <div className="other-section-inner custom-box-shadow-inner" key={index}>
                                    <div className="other-section-inner-title">
                                        <h4>{item.title}</h4>
                                    </div>
                                    <p>{item.description}</p>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default AboutUs