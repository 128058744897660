import React, {useEffect} from "react";
import {Link, NavLink} from "react-router-dom";

const Nav = () => {

    return(

                    <div className={'all-policy-menu-section'} >
                        <NavLink to={'/faq'}  activeClassName={'active'} className={'all-policy-menu '}>FAQ</NavLink>
                        <NavLink to={'/aboutus'} activeClassName={'active'} className={'all-policy-menu'}>About Us</NavLink>
                        <NavLink to={'/contact'} activeClassName={'active'} className={'all-policy-menu'}>Contact</NavLink>
                        <NavLink to={'/terms-and-conditions'}  activeClassName='active' className={'all-policy-menu'}>Terms And Conditions</NavLink>
                        <NavLink to={'/delivery-policy'} activeClassName={'active'} className={'all-policy-menu'}>Delivery Policy</NavLink>
                        <NavLink to={'/privacy-policy'} activeClassName={'active'} className={'all-policy-menu'}>Privacy Policy</NavLink>
                        <NavLink to={'/return-policy'} activeClassName={'active'} className={'all-policy-menu'}>Return Policy</NavLink>
                        <NavLink to={'/refund-policy'}  activeClassName={'active'} className={'all-policy-menu'}>Refund Policy</NavLink>
                        <NavLink to={'/mission'} activeClassName={'active'} className={'all-policy-menu'}>Mission</NavLink>
                        <NavLink to={'/vision'} activeClassName={'active'} className={'all-policy-menu'}>Vision</NavLink>
                        <NavLink to={'/video-tutorial'} activeClassName={'active'} className={'all-policy-menu'}>Video Tutorial</NavLink>
                    </div>


  )
}
export default Nav