import React, { useState } from 'react'
import images1 from '../../Assets/Images/product/1.jpg'
import images2 from '../../Assets/Images/product/2.jpg'
import images3 from '../../Assets/Images/product/3.jpg'
import icon5 from "../../Assets/Images/home-page-icon/global.png";

export const GlobalShopping = () => {
    const [globalShopping, setGlobalShopping] = useState([
        { productStok: 'stock Product', productTitle: 'Card title', image: images1 },
        { productStok: 'stock Product', productTitle: 'Card title', image: images2 },
        { productStok: 'stock Product', productTitle: 'Card title', image: images3 },
        { productStok: 'stock Product', productTitle: 'Card title', image: images1 },
        { productStok: 'stock Product', productTitle: 'Card title', image: images2 },
        { productStok: 'stock Product', productTitle: 'Card title', image: images3 },
        { productStok: 'stock Product', productTitle: 'Card title', image: images2 },
        { productStok: 'stock Product', productTitle: 'Card title', image: images3 },

    ])
    return (
        <div className="container py-2">
            <div className="bg-color custom-shadow">
                <div className="row">
                    <div className="col-md-12 g-1">
                        <div className="flash-deal-inner">
                            <div className="img-and-title">
                                <img src={icon5} alt=""/>
                                <h3 className={'new-font-size'}>Global Shopping</h3>
                            </div>
                            <a href="">View More  <span><i className="fas fa-arrow-circle-right"></i></span></a>
                        </div>
                    </div>
                </div>
                <div className="row  globalTitle">
                    {globalShopping.map((item, index) => (
                        <div className="col-md-3 g-1" key={index}>
                            <div className="card  my-card">
                                <div className="card-body">
                                    <a href=""><h5>{item.productTitle}</h5></a>
                                    <p>{item.productStok}</p>
                                    <div className="row globalImg">
                                        <div className="col-4">
                                            <img src={item.image} alt="" />
                                        </div>
                                        <div className="col-4">
                                            <img src={item.image} alt="" />
                                        </div>
                                        <div className="col-4">
                                            <img src={item.image} alt="" />
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
        </div>
    )
}
export default GlobalShopping
