import React from 'react'
import Typed from 'react-typed';
import 'react-typed/dist/animatedCursor.css';

 const HeadLine = () => {
    return (
        <>
        <div className={'container-fluid head-line-bgcolor '}>
            <div className="container text-center">
                <Typed
                    strings={['ঘোষণাঃ পবিত্র ইদ-উল-আজহা এর ছুটির অংশ হিসেবে ১৭ জুলাই থেকে ২৪ জুলাই পর্যন্ত সকল অর্ডার ২৫ শে জুলাই থেকে প্রসেস করা হবে।']}
                    typeSpeed={40}
                    backSpeed={40}
                    stopped={false}
                    loop={true}
                />
            </div>
        </div>
        </>
    )
}
export default HeadLine