import React from 'react'
import SliderAndCategory from "../Include/SliderAndCategory";
import FlashDeals from '../Include/FlashDeals';
import Newarivel from '../Include/Newarivel';
import BestSelling from '../Include/BestSelling';
import TopBrands from '../Include/TopBrands';
import HotCategory from '../Include/HotCategory';
import GlobalShopping from '../Include/GlobalShopping';
import GlobalShoppingNew from '../Include/GlobalShoppingNew';
import GroceryZone from '../Include/GroceryZone';
import Products from '../Include/Products';
import MoreToLove from '../Include/MoreToLove';
import NewSlider from "../Include/NewSlider";
// import logo from './logo.svg';
function Home() {
    return (
        <div className = "App" >
            <SliderAndCategory/>
            {/*<NewSlider/>*/}
            <FlashDeals/>
            <Newarivel/>
            <BestSelling/>
            <TopBrands/>
            <HotCategory/>
            <GlobalShopping/>
            <GlobalShoppingNew/>
            <GroceryZone/>
            <Products/>
            <MoreToLove/>
        </div>
    );
}
export default Home;