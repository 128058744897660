import React, {useEffect, useState} from "react";

import logo1 from '../../../../Assets/Images/merchant-logo/1.jpg'
import logo2 from '../../../../Assets/Images/merchant-logo/2.jpg'
import logo3 from '../../../../Assets/Images/merchant-logo/3.jpg'
import logo4 from '../../../../Assets/Images/merchant-logo/4.jpg'
import logo5 from '../../../../Assets/Images/merchant-logo/5.jpg'
import sent from '../../../../Assets/Images/live-chat-img/send.png'

import Nav from "../Include/Nav";
import SideNavBar from "../Include/SideNavBar";


const Chat = () => {

    // useEffect(() => {
    //     setTimeout(() => {
    //         setHeight(document.querySelector('#my_dashboard_side_bar').offsetHeight)
    //         console.log(height)
    //     }, 1000)
    //
    // }, []);


    const [height, setHeight] = useState('600px')

    const [liveChatProfile, setLiveChatProfile] = useState([
        {profileName: 'Ashraful Islam', massage: 'Thank You', image: logo1, hour: '1w'},
        {profileName: 'Monirul Islam', massage: 'Thank You', image: logo2, hour: '12m'},
        {profileName: 'Sariful Islam', massage: 'Thank You', image: logo3, hour: '3h'},
        {profileName: 'Mahima Islam', massage: 'Thank You', image: logo4, hour: '2h'},
        {profileName: 'Tuli Islam', massage: 'Thank You', image: logo5, hour: '1min'},
        {profileName: 'Naimul Hasan', massage: 'Thank You', image: logo2, hour: '5min'},
        {profileName: 'Naimul Hasan', massage: 'Thank You', image: logo2, hour: '5min'},
        {profileName: 'Khorsheda', massage: 'Thank You', image: logo2, hour: '5min'},
        {profileName: 'Morsheda', massage: 'Thank You', image: logo2, hour: '5min'},
        {profileName: 'Mohima', massage: 'Thank You', image: logo2, hour: '5min'},
        {profileName: 'Anwara', massage: 'Thank You', image: logo2, hour: '5min'},
        {
            profileName: 'Younis Rahman', massage: 'Thank You', image: logo2, hour: '5min', chat: [
                {msg: 'hello how are you?', type: 'send'},
                {msg: 'I am fine', type: 'received'}
            ]
        },
    ])

    const handleChatList = (index, e) => {
        document.querySelector('#user_name').innerHTML = liveChatProfile[index]['profileName']
        document.querySelector('#last_active').innerHTML = liveChatProfile[index]['hour']
        document.querySelector('#user_profile_photo').src = liveChatProfile[index]['image']

    }


    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="my-dashboard-inner">
                            <div className="row">
                                <div className="col-md-3">
                                    <SideNavBar/>
                                </div>
                                <div className="col-md-9 bg-white live-chat-padding position-relative"
                                     style={{height: height}}>
                                    <div className="row">
                                        <div className="col-md-4 live-chat-title" style={{height: height}}>
                                            <h4>Chats</h4>
                                            <div className="live-chat-search">
                                                <div className="input-group live-chat-search-input-group ">
                                                    <span className={'input-group-text search-input-group-text'}><i
                                                        className="fas fa-search"/></span>
                                                    <input type="text"
                                                           className="form-control form-control-sm my-search-form-control"
                                                           placeholder="Search"/>
                                                </div>
                                            </div>
                                            <div className="chat-page-chat-list">
                                                {liveChatProfile.map((item, index) => (
                                                    <div className="live-chat-merchant-profile" data-index={index}
                                                         onClick={(e) => handleChatList(index, e)} key={index}>
                                                        <div className="d-flex">
                                                            <div className="flex-shrink-0">
                                                                <img src={item.image} alt="..."/>
                                                            </div>
                                                            <div className="flex-grow-1 ms-3 ">
                                                                <div
                                                                    className={'live-chat-merchant-name'}>{item.profileName}</div>
                                                                <p>{item.massage} . <span>{item.hour}</span></p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="col-md-8 p-0 live-chat-right-border">
                                            <div className="chat-customer-details">
                                                <div className="d-flex">
                                                    <div className="flex-shrink-0">
                                                        <img id={'user_profile_photo'} src={logo1} alt="..."/>
                                                    </div>
                                                    <div className="flex-grow-1 ms-3" id={'user_information'}>
                                                        <a href={'/'} id={'user_name'}>Md. Ashraful Isalam </a>
                                                        <p><small id={'last_active'}>Active 3 hours Ago</small></p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="chat-content">
                                                {/*single send chat start*/}
                                                <div className="live-chat-message-received">
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0">
                                                            <img id={'user_profile_photo'} src={logo1} alt="..."/>
                                                        </div>
                                                        <div className="flex-grow-1 ms-3" id={'user_information'}>
                                                            <div className="receive-message-container">
                                                                <div className="chat-receive">
                                                                    <p className={'chat-receive-text'}>Hello How are
                                                                        you?</p>
                                                                    <div className="chat-received-time">January 1,2021
                                                                        at 1:28 pm
                                                                    </div>
                                                                </div>
                                                                <div className="chat-receive-action">
                                                                    <span><i className="fas fa-ellipsis-v"/></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*single send chat end*/}


                                                {/*single received chat start*/}
                                                <div className="live-chat-message-sent">
                                                    <div className="d-flex">
                                                        <div className="flex-grow-1 me-3 " id={'user_information'}>
                                                            <div className="sent-message-container">
                                                                <div className="chat-sent-action">
                                                                    <span><i className="fas fa-ellipsis-v"/></span>
                                                                </div>
                                                                <div className="chat-sent">

                                                                    <div className="chat-sent-time">January 1,2021
                                                                        at 1:28 pm
                                                                    </div>
                                                                    <p className={'chat-sent-text'}>Im fine</p>

                                                                </div>

                                                            </div>
                                                        </div>
                                                        <div className="flex-shrink-0">
                                                            <img id={'user_profile_photo'} src={logo1} alt="..."/>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*single received chat end*/}


                                                {/*single send chat start*/}
                                                <div className="live-chat-message-received">
                                                    <div className="d-flex">
                                                        <div className="flex-shrink-0">
                                                            <img id={'user_profile_photo'} src={logo1} alt="..."/>
                                                        </div>
                                                        <div className="flex-grow-1 ms-3" id={'user_information'}>
                                                            <div className="receive-message-container">
                                                                <div className="chat-receive">
                                                                    <p className={'chat-receive-text'}>Hello How are
                                                                        you?</p>
                                                                    <div className="chat-received-time">January 1,2021
                                                                        at 1:28 pm
                                                                    </div>
                                                                </div>
                                                                <div className="chat-receive-action">
                                                                    <span><i className="fas fa-ellipsis-v"/></span>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/*single send chat end*/}

                                            </div>
                                            <div className="live-chat-emoji-box">
                                                <h2>emoji box</h2>
                                            </div>
                                            <div className="live-chat-text-writing">
                                                <div className="upload-btn-live-chat">
                                                    <button className="live-chat-btn">+</button>
                                                </div>
                                                <div className="input-group live-chat-search-input-group">
                                                    <input type="text"
                                                           className=" form-control form-control-sm my-search-form-control"
                                                           placeholder="massage"/>
                                                    <span><i className="fas fa-smile"/></span>
                                                </div>
                                                <div className="live-chat-sent-btn">
                                                    <img src={sent} alt=""/>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Chat