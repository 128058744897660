import React from 'react'
import SecurePayment from "../Cart/include/SecurePayment";
import OrderSummary from "../Cart/include/OrderSummary";
import ShippingIformation from "./include/ShippingIformation"

const Checkout = () => {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <SecurePayment/>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-9">
                        <ShippingIformation/>
                    </div>
                    <OrderSummary/>
                </div>
            </div>
        </>
    )
}

export default Checkout
