import React, {useState} from "react";
import Slider from "react-slick";
import '../../../../../node_modules/slick-carousel/slick/slick.css';
import "../../../../../node_modules/slick-carousel/slick/slick-theme.css";
import Slider1 from "../../../../Assets/Images/banner/1.jpg";
import Slider2 from "../../../../Assets/Images/banner/2.jpg";
import Slider3 from "../../../../Assets/Images/banner/3.jpg";
import Select from "react-select";
import images1 from "../../../../Assets/Images/flashdeal/1.jpg";
import images2 from "../../../../Assets/Images/flashdeal/2.jpg";
import images3 from "../../../../Assets/Images/flashdeal/3.jpg";
import images4 from "../../../../Assets/Images/flashdeal/4.jpg";
import images5 from "../../../../Assets/Images/flashdeal/5.jpg";
import images6 from "../../../../Assets/Images/flashdeal/6.jpg";
import images7 from "../../../../Assets/Images/newarival/1.jpg";
import images8 from "../../../../Assets/Images/newarival/2.jpg";
import images9 from "../../../../Assets/Images/newarival/3.jpg";
import images11 from "../../../../Assets/Images/newarival/5.jpg";
import images12 from "../../../../Assets/Images/newarival/6.jpg";
import CardHover from "../../../Include/CardHover";
import ModalProduct from "../../../Include/ModalProduct";

const CategoryMaster = () => {
    const [catagory, setCatagory] = useState([
        {productTitle: 'Bag', price: 28.12, discountPrice: 10.2, discountParsent: '15%', image: images1},
        {productTitle: 'Showe', price: 180.00, discountPrice: 12.00, discountParsent: '27%', image: images2},
        {productTitle: 'Brush', price: 158.13, discountPrice: 8.3, discountParsent: '20%', image: images3},
        {productTitle: 'Bag', price: 258.89, discountPrice: 5.9, discountParsent: '35%', image: images4},
        {productTitle: 'Brush', price: 12.43, discountPrice: 43.2, discountParsent: '10%', image: images5},
        {productTitle: 'Camera', price: 3.58, discountPrice: 39.2, discountParsent: '25%', image: images6},
        {productTitle: 'Brush', price: 158.13, discountPrice: 8.3, discountParsent: '20%', image: images3},
        {productTitle: 'Bag', price: 258.89, discountPrice: 5.9, discountParsent: '35%', image: images4},
        {productTitle: 'Bag', price: 28.12, discountPrice: 10.2, discountParsent: '15%', image: images1},
        {productTitle: 'Showe', price: 180.00, discountPrice: 12.00, discountParsent: '27%', image: images2},
        {productTitle: 'Brush', price: 12.43, discountPrice: 43.2, discountParsent: '10%', image: images5},
        {productTitle: 'Camera', price: 3.58, discountPrice: 39.2, discountParsent: '25%', image: images6},
        {productTitle: 'Brush', price: 158.13, discountPrice: 8.3, discountParsent: '20%', image: images3},
        {productTitle: 'Bag', price: 258.89, discountPrice: 5.9, discountParsent: '35%', image: images4},
        {productTitle: 'Bag', price: 28.12, discountPrice: 10.2, discountParsent: '15%', image: images1},
        {productTitle: 'Showe', price: 180.00, discountPrice: 12.00, discountParsent: '27%', image: images2},
    ])

    const [relatedImage, setRelatedImage] = useState([
        {product: 'Bag', image: images1},
        {product: 'Shoes', image: images2},
        {product: 'Brush', image: images3},
        {product: 'Bag', image: images4},
        {product: 'Brush', image: images5},
        {product: 'Mobile', image: images7},
        {product: 'Accessories', image: images8},
        {product: 'Dress', image: images9},
        {product: 'Gown', image: images11},
        {product: 'Electronics', image: images12},
    ])

    const [settingsCatagory] = useState({
        dots: true,
        arrows: true,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    })

    const [product_slider_category_page] = useState({
        dots: false,
        infinite: true,
        speed: 300,
        arrows: true,
        autoplay: true,
        slidesToShow: 6,
        slidesToScroll: 4,
        responsive: [
            {
                breakpoint: 1024,
                settings: {
                    slidesToShow: 3,
                    slidesToScroll: 3,
                    infinite: true,
                    dots: true
                }
            },
            {
                breakpoint: 600,
                settings: {
                    slidesToShow: 2,
                    slidesToScroll: 2
                }
            },
            {
                breakpoint: 480,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1
                }
            }
            // You can unslick at a given breakpoint now by adding:
            // settings: "unslick"
            // instead of a settings object
        ]
    })
    const options = [
        {value: 'chocolate', label: 'Chocolate'},
        {value: 'strawberry', label: 'Strawberry'},
        {value: 'vanilla', label: 'Vanilla'}
    ]
    const customStylesSort = {
        menu: (provided, state) => ({
            ...provided,
            // width: '30px',
            // fontSize: '13px',
            outline: 'none',
            border: 'none',
            zIndex: 9999
        }),
        container: (provided, state) => ({
            ...provided,
            width: '105px',
        }),
        dropdownIndicator: (provided, state) => ({
            ...provided,
            padding: '0 5px !important',
        }),
        control: (provided, state) => ({
            ...provided,
            //  width: '1000%',
            // fontSize: '13px',
            outline: 'none',
            border: 'none',
            boxShadow: 'none',
            minHeight: '25px',
            // boxShadow: '0px 0px 2px black',

        }),
        option: (provided, state) => ({
            ...provided,
            "&:hover": {
                backgroundColor: "silver"
            }
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            right: '0',
            position: 'relative',
        })
    }

    const [viewToggle, setViewToggle] = useState(0)

    const handleView = (id, e) => {
        // grip-horizontal-active
        let elemnet_id = e.currentTarget.id;
        if (id == 'list_view') {
            setViewToggle(1)
            document.querySelector('#list_view').classList.add('grip-horizontal-active')
            document.querySelector('#grid_view').classList.remove('grip-horizontal-active')
        } else {
            setViewToggle(0)
            document.querySelector('#list_view').classList.remove('grip-horizontal-active')
            document.querySelector('#grid_view').classList.add('grip-horizontal-active')
        }
    }
    const [quantity, setQuantity] = useState(1)

    const handleInputChange = (e) => {
        setQuantity(e.target.value)
    }
    const decreaseQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1)
        }
    }
    const increaseQuantity = () => {
        setQuantity(parseInt(quantity) + 1)
    }

    return (
        <>
            <div className="row">
                <div className="col-md-12">
                    <div className="catagory-bannar-slider">

                        <Slider {...settingsCatagory}>
                            <div>
                                <img className="catagory-img" src={Slider1} alt=""/>
                            </div>
                            <div>
                                <img className="catagory-img" src={Slider2} alt=""/>
                            </div>
                            <div>
                                <img className="catagory-img" src={Slider3} alt=""/>
                            </div>

                        </Slider>

                    </div>
                </div>
            </div>
            <div className="row card-top-img-gutter category-img-bg custom-shadow">
                <div className="col-md-12">
                    <div className="category_page_product_slider ">
                        <Slider {...product_slider_category_page}>
                            {relatedImage.map((item, index) => (
                                <div key={index}>
                                    <img src={item.image}
                                         className="img-fluid category-page-slider-image custom-eliment-shaow"
                                         alt="..."/>
                                    <div className="card-body recomended-card-cody">
                                        <h5 className="card-title gray-text m-0">{item.product}</h5>
                                    </div>
                                </div>
                            ))}
                        </Slider>
                    </div>
                </div>
            </div>
            <div className="row">
                <div className="col-md-12">
                    <div className="row bg-white category-item-img-border custom-shadow">
                        <div className="row related-cagory-pading bg-white">
                            <div className="col-md-3">
                                <div className="related-categories">
                                    <h6>Related Categories</h6>
                                    <p>TV Accessories</p>
                                </div>
                            </div>
                            <div className="col-md-5 p-1">
                                <div className="related-categories price-input-box">
                                    <h6>Price:</h6>
                                    <input className="min-box" type="text" placeholder="min"/>
                                    <span>-</span>
                                    <input className="max-box" type="text" placeholder="max"/>
                                    <button className="price-btn"><i className="fas fa-play"/></button>

                                    <p className="gray-text catagory-star-icon">
                                        <input className="star-icon-checkbox" type="checkbox" name="" id=""/>
                                        <span className="star-icon-color">
                                <i className="fas fa-star"/>
                                <i className="fas fa-star"/>
                                <i className="fas fa-star"/>
                                <i className="fas fa-star"/>
                                <i className="fas fa-star"/>
                            </span> & Up
                                    </p>

                                </div>
                            </div>
                            <div className="col-md-4  related-categories">
                                <div className="row select-item-end">
                                    <div className="col-6">
                                        <div className="coustom-select">
                                            <Select options={options}
                                                // options={searchOption}
                                                    styles={customStylesSort}
                                                    placeholder={'Sort By'}
                                            />
                                        </div>
                                    </div>
                                    <div className="col-4">
                                        <div className="grid-viwe">
                                            <p>View:</p>
                                            <span className={'grip-horizontal grip-horizontal-active'} id={'grid_view'}
                                                  onClick={(e) => handleView('grid_view', e)}><i
                                                className="fas fa-th-large"/></span>
                                            <span className="grip-horizontal" id={'list_view'}
                                                  onClick={(e) => handleView('list_view', e)}><i
                                                className="fas fa-th-list"/></span>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-3 pe-0 ">
                            <div className="category-left-filter-area bg-white">
                                <div className="brand">
                                    <h6>Brand</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value=""
                                               id="flexCheckDefault"/>
                                        <label className="form-check-label">
                                            Apple
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value=""
                                               id="flexCheckChecked"/>
                                        <label className="form-check-label">
                                            Redme
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value=""
                                               id="flexCheckChecked"/>
                                        <label className="form-check-label">
                                            Oppo
                                        </label>
                                    </div>
                                    <p>View More</p>
                                </div>
                                <div className="brand">
                                    <h6>Service</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value=""
                                               id="flexCheckDefault"/>
                                        <label className="form-check-label">
                                            Installment
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value=""
                                               id="flexCheckChecked"/>
                                        <label className="form-check-label">
                                            Cash On Delivery
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value=""
                                               id="flexCheckChecked"/>
                                        <label className="form-check-label">
                                            Free Shipping
                                        </label>
                                    </div>

                                </div>
                                <div className="brand">
                                    <h6>Location</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value=""
                                               id="flexCheckDefault"/>
                                        <label className="form-check-label">
                                            Bangladesh
                                        </label>
                                    </div>
                                </div>
                                <div className="brand">
                                    <h6>Warranty Type</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value=""
                                               id="flexCheckDefault"/>
                                        <label className="form-check-label">
                                            Apple
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value=""
                                               id="flexCheckChecked"/>
                                        <label className="form-check-label">
                                            Redme
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value=""
                                               id="flexCheckChecked"/>
                                        <label className="form-check-label">
                                            Oppo
                                        </label>
                                    </div>

                                </div>
                                <div className="brand">
                                    <h6>Color Family</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value=""
                                               id="flexCheckDefault"/>
                                        <label className="form-check-label">
                                            Apple
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value=""
                                               id="flexCheckChecked"/>
                                        <label className="form-check-label">
                                            Redme
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value=""
                                               id="flexCheckChecked"/>
                                        <label className="form-check-label">
                                            Oppo
                                        </label>
                                    </div>

                                </div>
                                <div className="brand">
                                    <h6>TV Receiver</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value=""
                                               id="flexCheckDefault"/>
                                        <label className="form-check-label">
                                            Apple
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value=""
                                               id="flexCheckChecked"/>
                                        <label className="form-check-label">
                                            Redme
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value=""
                                               id="flexCheckChecked"/>
                                        <label className="form-check-label">
                                            Oppo
                                        </label>
                                    </div>

                                </div>
                                <div className="brand">
                                    <h6>Warranty Period</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value=""
                                               id="flexCheckDefault"/>
                                        <label className="form-check-label">
                                            Apple
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value=""
                                               id="flexCheckChecked"/>
                                        <label className="form-check-label">
                                            Redme
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value=""
                                               id="flexCheckChecked"/>
                                        <label className="form-check-label">
                                            Oppo
                                        </label>
                                    </div>

                                </div>
                                <div className="brand">
                                    <h6>Universal</h6>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value=""
                                               id="flexCheckDefault"/>
                                        <label className="form-check-label">
                                            Apple
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value=""
                                               id="flexCheckChecked"/>
                                        <label className="form-check-label">
                                            Redme
                                        </label>
                                    </div>
                                    <div className="form-check">
                                        <input className="form-check-input" type="checkbox" value=""
                                               id="flexCheckChecked"/>
                                        <label className="form-check-label">
                                            Oppo
                                        </label>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="col-md-9 p-0 ">
                            <div className="row">
                                <div className="col-md-12 ">
                                    <div className="row category-img-bg">
                                        {viewToggle == 0 ?
                                            <>
                                                {catagory.map((item, index) => (
                                                    <div className="col-3 g-2" key={index}>
                                                        <div className="category-img ">
                                                            <div className="card card-border-none custom-eliment-shaow img-relative">
                                                               <CardHover/>
                                                                <img src={item.image} className="img-fluid" alt="..."/>
                                                                <div className="card-body p-2 catagory-body">
                                                                    <h5 className="card-title gray-text">{item.productTitle}</h5>
                                                                    <p className="catagory-price">{item.price}</p>
                                                                    <p className="gray-text catagory-discount">
                                                                        <del style={{marginRight:'5px'}}>৳ {item.discountPrice} </del>
                                                                        -{item.discountParsent}</p>
                                                                    <div className="catagory-star">
                                                                        <p className="gray-text">
                                                                            <span className="star-icon-color">
                                                                                <i className="fas fa-star"/>
                                                                                <i className="fas fa-star"/>
                                                                                <i className="fas fa-star"/>
                                                                                <i className="fas fa-star"/>
                                                                                <i className="fas fa-star"/>
                                                                            </span> (701)
                                                                        </p>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                            : <>
                                                {catagory.map((item, index) => (
                                                    <div className="category-list-item-bg" key={index}>
                                                        <div
                                                            className="category-all-list-item-product custom-eliment-shaow">
                                                            <div className="row">
                                                                <div className="col-md-3 p-0">
                                                                    <div className="category-list-item-product-image">
                                                                        <img className={'img-fluid'} src={item.image}
                                                                             alt=""/>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-6">
                                                                    <div
                                                                        className="category-list-item-product-name category-list-item-padding-top">
                                                                        <a href="/list"
                                                                           className={'category-list-item-product-title'}>
                                                                            [bluetooth Call] Bakeey CK30 Temperature
                                                                            Measurement Heart Rate Blood Pressure....
                                                                        </a>
                                                                        <div className="star-icon">
                                                                            <a href="/">
                                                                                <span className="star-icon-color"><i
                                                                                    className="fas fa-star"/></span>
                                                                                <span className="star-icon-color"><i
                                                                                    className="fas fa-star"/></span>
                                                                                <span className="star-icon-color"><i
                                                                                    className="fas fa-star"/></span>
                                                                                <span className="star-icon-color"><i
                                                                                    className="fas fa-star"/></span>
                                                                                <span className="star-icon-color"><i
                                                                                    className="fas fa-star"/></span>
                                                                                <span className="rating">5.0</span>

                                                                            </a>
                                                                            <p>
                                                                                <span>58 Reviews</span>
                                                                                <span
                                                                                    className={'list-item-review-line'}>|</span>
                                                                                <span>112 orders</span>
                                                                            </p>
                                                                        </div>
                                                                    </div>

                                                                    <div className="product-quantity product-heading-color">
                                                                        <div className="product-quantity-inner">
                                                                            <button className="minus-btn" onClick={decreaseQuantity}><i
                                                                                className="fas fa-minus"/></button>
                                                                            <input
                                                                                className="quantity-input"
                                                                                onChange={handleInputChange}
                                                                                name="quantity"
                                                                                type="number"
                                                                                value={quantity}
                                                                                max="20"/>
                                                                            <button className="plus-btn" onClick={increaseQuantity}><i
                                                                                className="fas fa-plus"/></button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-md-3">
                                                                    <div
                                                                        className="category-list-item-product-price category-list-item-padding-top text-end">
                                                                        <p>৳ 158.13</p>
                                                                        <div
                                                                            className="category-list-item-product-price-discount">
                                                                            <del
                                                                                className={'category-discount-shadow'}>1520
                                                                            </del>
                                                                            <span
                                                                                className={'category-discount-shadow'}>-50%</span>
                                                                        </div>
                                                                    </div>

                                                                    <div className="buy-now-add-to-cart-btn">
                                                                        <div className="wishlist-cart-btn">
                                                                            <button className="btn rounded-pill wishlist-form-buy-now-btn-color">Buy
                                                                                Now
                                                                            </button>
                                                                            <button
                                                                                className="btn rounded-pill wishlist-form-buy-now-btn-color wishlist-form-add-to-cart-btn-color">Add
                                                                                to Cart
                                                                            </button>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </>
                                        }
                                    </div>

                                </div>

                            </div>
                        </div>
                    </div>
                </div>
<ModalProduct/>
            </div>
        </>
    )
}
export default CategoryMaster