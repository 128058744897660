import React, {useState} from "react";
import Nav from "./Include/Nav";

const ReturnPolicy = () => {

    const [returnPolicy, setReturnPolicy]=useState([
        {title:'About', description:'1. যদি আপনার পণ্যটি ভাঙা অবস্থায় পৌঁছে বা অর্ডার করা পণ্যের পরিবর্তে ভিন্ন কিংবা একেবারেই অসামঞ্জস্যপূর্ণ পণ্য হাতে পান, তাহলে আপনার পণ্যটি রিটার্ন করুন। পণ্য রিটার্ন করার জন্যে হটলাইনে কল বা মেইল করার প্রয়োজন নেই। পণ্যটি রিটার্নযোগ্য হবে কি না তা যাচাই করতে পণ্য ডেলিভারি পাওয়ার সঙ্গে সঙ্গেই একই দিনে পণ্য আনপ্যাকেজিং করার ভিডিয়ো অথবা পণ্যের ছবি ০১৩২২-৮৭৩৪৮৯  নম্বরে হোয়াটসঅ্যাপ করুন (রিটার্ন ইস্যু ব্যতীত অন্য কোনো কারণে এই হোয়াটসঅ্যাপ নম্বর থেকে সেবা দেওয়া হবে না এবং হোয়াটসঅ্যাপে কল দেওয়া যাবে না)।'+

            'হোয়াটসঅ্যাপ থেকে সম্মতি পেলে আপনার পণ্যটি নিম্নোক্ত ঠিকানায় কুরিয়ার ফি বাকি রেখে (টু পে-তে পাঠিয়ে দিন। আমরা পণ্যের কুরিয়ার  ফি পরিশোধ করে পণ্য তুলে নেবো ।যদি আপনার পণ্যটি ভাঙা অবস্থায় পৌঁছে বা অর্ডার করা পণ্যের পরিবর্তে ভিন্ন কিংবা একেবারেই অসামঞ্জস্যপূর্ণ পণ্য হাতে পান, তাহলে আপনার পণ্যটি রিটার্ন করুন। পণ্য রিটার্ন করার জন্যে হটলাইনে কল বা মেইল করার প্রয়োজন নেই। পণ্যটি রিটার্নযোগ্য হবে কি না তা যাচাই করতে পণ্য ডেলিভারি পাওয়ার সঙ্গে সঙ্গেই একই দিনে পণ্য আনপ্যাকেজিং করার ভিডিয়ো অথবা পণ্যের ছবি ০১৩২২-৮৭৩৪৮৯  নম্বরে হোয়াটসঅ্যাপ করুন (রিটার্ন ইস্যু ব্যতীত অন্য কোনো কারণে এই হোয়াটসঅ্যাপ নম্বর থেকে সেবা দেওয়া হবে না এবং হোয়াটসঅ্যাপে কল দেওয়া যাবে না)।'+

            'হোয়াটসঅ্যাপ থেকে সম্মতি পেলে আপনার পণ্যটি নিম্নোক্ত ঠিকানায় কুরিয়ার ফি বাকি রেখে (টু পে-তে পাঠিয়ে দিন। আমরা পণ্যের কুরিয়ার  ফি পরিশোধ করে পণ্য তুলে নেবো । '},
        {title:'Our Target', description:'যদি আপনার পণ্যটি ভাঙা অবস্থায় পৌঁছে বা অর্ডার করা পণ্যের পরিবর্তে ভিন্ন কিংবা একেবারেই অসামঞ্জস্যপূর্ণ পণ্য হাতে পান, তাহলে আপনার পণ্যটি রিটার্ন করুন। পণ্য রিটার্ন করার জন্যে হটলাইনে কল বা মেইল করার প্রয়োজন নেই। পণ্যটি রিটার্নযোগ্য হবে কি না তা যাচাই করতে পণ্য ডেলিভারি পাওয়ার সঙ্গে সঙ্গেই একই দিনে পণ্য আনপ্যাকেজিং করার ভিডিয়ো অথবা পণ্যের ছবি ০১৩২২-৮৭৩৪৮৯  নম্বরে হোয়াটসঅ্যাপ করুন (রিটার্ন ইস্যু ব্যতীত অন্য কোনো কারণে এই হোয়াটসঅ্যাপ নম্বর থেকে সেবা দেওয়া হবে না এবং হোয়াটসঅ্যাপে কল দেওয়া যাবে না)।'+

                'হোয়াটসঅ্যাপ থেকে সম্মতি পেলে আপনার পণ্যটি নিম্নোক্ত ঠিকানায় কুরিয়ার ফি বাকি রেখে (টু পে-তে পাঠিয়ে দিন। আমরা পণ্যের কুরিয়ার  ফি পরিশোধ করে পণ্য তুলে নেবো ।'}
    ])
  return(
      <>
          <div className="container">
              <div className="row">
                  <div className="col-md-12">
<Nav/>
                  </div>
                  <div className="col-md-12">
                      <div className="others-section bg-white custom-shadow-without-top">
                          <h1 className={'text-center'}>Return Policy</h1>

                          {returnPolicy.map((item,index)=>(
                              <div className="other-section-inner custom-box-shadow-inner" key={index}>
                                  <div className="other-section-inner-title">
                                      <h4>{item.title}</h4>
                                  </div>
                                  <p>{item.description}</p>
                              </div>
                          ))}

                      </div>
                  </div>
              </div>
          </div>
      </>
  )
}

export default ReturnPolicy