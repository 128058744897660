import React, {useState} from 'react'
import Countdown from 'react-countdown';
import images1 from '../../Assets/Images/flashdeal/1.jpg'
import images2 from '../../Assets/Images/flashdeal/2.jpg'
import images3 from '../../Assets/Images/flashdeal/3.jpg'
import images4 from '../../Assets/Images/flashdeal/4.jpg'
import images5 from '../../Assets/Images/flashdeal/5.jpg'
// import images6 from '../../Assets/Images/flashdeal/6.jpg'
import {Link, NavLink} from "react-router-dom";
import ReactImageZoom from "react-image-zoom";
import NumberFormat from "react-number-format";
import ModalProduct from "./ModalProduct";
import CardHover from "./CardHover";


const renderer = ({hours, minutes, seconds, completed}) => {
    if (completed) {
        // Render a completed state
        return 'Event Finished';
    } else {
        // Render a countdown
        return <span className={'flash-deal-count-down'}><span className={'flash-deal-count-hour'}>{hours}</span>:<span
            className={'flash-deal-count-min'}>{minutes}</span>:<span
            className={'flash-deal-count-sec'}>{seconds}</span></span>;
    }
};

const FlashDeals = () => {
    const [flashDeal, setFlashDeal] = useState([
        {price: 28.12, discount: 10.2, sold: 15, image: images1},
        {price: 180.00, discount: 12.00, sold: 27, image: images2},
        {price: 158.13, discount: 8.3, sold: 200, image: images3},
        {price: 258.89, discount: 5.9, sold: 358, image: images4},
        {price: 12.43, discount: 43.2, sold: 100, image: images5},
        {price: 180.00, discount: 12.00, sold: 27, image: images2},
        // {price: 3.58 , discount : 39.2 , sold : 25, image : images6},
    ])
    return (
        <div className="container py-2">
            <div className="bg-color custom-shadow">
                <div className="row">
                    <div className="col-md-12 g-1">
                        <div className="flash-deal-inner">
                            <h3 className={'new-font-size'}>Flash Deals
                                <span className={'text-success'}>
                                        <Countdown
                                            date={Date.now() + 86400000}
                                            renderer={renderer}
                                        />
                                    </span>
                            </h3>
                            <a href="">View More <span><i className="fas fa-arrow-circle-right"/></span></a>
                        </div>
                    </div>
                </div>
                <div className="row h-100">
                    {flashDeal.map((item, index) => (
                        <div className="col-md-2 h-100 g-1" key={index}>
                            <div className="card-inner h-100">
                                <div className="card my-card img-relative">
                                    <CardHover/>
                                    <img src={item.image} className="card-img-top" alt="..."/>
                                    <div className="card-body new-card-content mr20">
                                        <div className="new-card-content-flex">
                                            <span className="flash-deal-price">৳ {item.price} </span>
                                            <span className="flash-deal-discount">{item.discount}% OFF</span>
                                        </div>
                                        <p className={'flash-deal-sold'}>{item.sold} Sold</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <ModalProduct/>
        </div>


    )
}
export default FlashDeals
