import React, {useState} from 'react'
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import image1 from '../../../../Assets/Images/payment2/1.png'
import image2 from '../../../../Assets/Images/payment2/2.png'
import image3 from '../../../../Assets/Images/payment2/3.png'
import image4 from '../../../../Assets/Images/payment2/4.png'
import OrderSummary from "./OrderSummary";
import SecurePayment from "./SecurePayment";

const Payment = () => {
    const [payment, setPayment] = useState([
        {image: image1},
        {image: image2},
        {image: image3},
        {image: image4}
    ])
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <SecurePayment/>
                    </div>
                </div>
            </div>
            <div className="container">
                <div className="row">
                    <div className="col-md-9">
                        <div className="row">
                            <div className="col-md-12 payment-margin custom-shadow bg-white">
                                <div className="payment-title">
                                    <h4>Select Payment Method</h4>
                                </div>
                                <div className="all-payment-method">
                                    <Tabs>
                                        <TabList className={'payment-method-tab-list mt-4'}>
                                            {payment.map((item, index) => (
                                                <Tab>
                                                    <div className="payment-method-img" key={index}>
                                                        <img src={item.image} alt=""/>
                                                    </div>
                                                </Tab>
                                            ))}
                                        </TabList>

                                        <TabPanel className={'payment-method-tab-panel'}>
                                            <div className="bekash">
                                                <p>1) Users paying with bkash for the first time: Enter bKash Wallet Number
                                                    and OTP for successful account saving.</p>
                                                <p>2) For all subsequent users: Enter PIN to make payment </p>
                                                <p>Disclaimer: you will be redirected back to Checkout for first transaction
                                                    to complete payment</p>
                                                <p><strong>Please Note</strong></p>
                                                <p> 1. You have an activated bKash account <br/>
                                                    2. Ensure you have sufficient balance in your bKash account to cover the
                                                    total cost of the order<br/>
                                                    3. Ensure you are able to receive your OTP (one-time-password) on your
                                                    mobile and have bKash PIN</p>
                                                <p>***Please note that one bkash account can only be saved in one Daraz
                                                    account.***</p>
                                                <div className="pay-now">
                                                    <button type="button" className="btn rounded-pill btn btn-info pay-now-btn">Pay Now</button>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        <TabPanel className={'payment-method-tab-panel'}>
                                            <div className="bekash">
                                                <p>1) Users paying with bkash for the first time: Enter bKash Wallet Number
                                                    and OTP for successful account saving.</p>
                                                <p>2) For all subsequent users: Enter PIN to make payment </p>
                                                <p>Disclaimer: you will be redirected back to Checkout for first transaction
                                                    to complete payment</p>
                                                <p><strong>Please Note</strong></p>
                                                <p> 1. You have an activated bKash account <br/>
                                                    2. Ensure you have sufficient balance in your bKash account to cover the
                                                    total cost of the order<br/>
                                                    3. Ensure you are able to receive your OTP (one-time-password) on your
                                                    mobile and have bKash PIN</p>
                                                <p>***Please note that one bkash account can only be saved in one Daraz
                                                    account.***</p>
                                                <div className="pay-now">
                                                    <button type="button" className="btn rounded-pill btn btn-info pay-now-btn">Pay Now</button>
                                                </div>
                                            </div>
                                        </TabPanel>
                                        <TabPanel className={'payment-method-tab-panel'}>
                                            <div className="pay-now">
                                                    <button type="button" className="btn rounded-pill btn btn-info pay-now-btn">Pay Now</button>
                                                </div>
                                        </TabPanel>
                                        <TabPanel className={'payment-method-tab-panel'}>
                                            <div className="pay-now">
                                                    <button type="button" className="btn rounded-pill btn btn-info pay-now-btn">Pay Now</button>
                                                </div>
                                        </TabPanel>
                                    </Tabs>
                                </div>
                            </div>
                        </div>

                    </div>

                    <OrderSummary/>

                </div>
            </div>
        </>
    )
}
export default Payment


