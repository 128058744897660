import React from 'react';
import SideNavBar from "../Include/SideNavBar";
import ClintProfileTopBar from "../Include/ClintProfileTopBar";

const ShippingAddress = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="my-dashboard-inner">
                        <div className="row">
                            <div className="col-md-3">
                                <SideNavBar/>
                            </div>
                            <div className="col-md-9">
                                <ClintProfileTopBar/>
                                <div className="user-profile">
                                    <h5 className={'my-order-title text-center'}>Shipping Address</h5>
                                </div>
                                <div className="default-shipping-address user-profile">
                                    <div className="shipping-address-heading">
                                        <h5 className={'user-default-shipping-address'}>Default Shipping Address</h5>
                                    </div>
                                    <div className="shipping-address-table">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <table className="table table-hover table-striped common-table">
                                                    <tbody>
                                                    <tr>
                                                        <th>Address</th>
                                                        <td>7th Floor, Fair Plaza, Mirpur-1, Dhaka-1216, Bangladesh</td>
                                                    </tr>
                                                    <tr>
                                                        <th>City</th>
                                                        <td>Dhaka</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Region</th>
                                                        <td>Dhaka</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Post Code</th>
                                                        <td>1216</td>
                                                    </tr>
                                                    </tbody>
                                                </table>
                                            </div>
                                            <div className="col-md-6">
                                                <table className="table table-hover table-striped common-table">
                                                    <tbody>
                                                    <tr>
                                                        <th>Country</th>
                                                        <td>Bangladesh</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Mobile Number</th>
                                                        <td>01811151115</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Phone Number</th>
                                                        <td>01707170717</td>
                                                    </tr>
                                                    <tr>
                                                        <th>Landmarks</th>
                                                        <td></td>
                                                    </tr>
                                                    </tbody>
                                                </table>

                                                <div className="default-address-action-area">
                                                    <form><input name="_method" type="hidden"value="DELETE"/>
                                                        <input
                                                        name="_token" type="hidden"/>
                                                        <button type="submit"
                                                                className="btn btn-danger btn-sm text-right ml-1 me-1 address-button"><i className="fas fa-trash-alt"/></button>
                                                    </form>
                                                    <a href="">
                                                        <button className="btn btn-warning btn-sm text-right address-button"><i className={'fas fa-edit'}/></button>
                                                    </a>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="card-body shipping-address-body user-profile">
                                    <div className="row">
                                        <div className="col-md-9 mb-3 g-0">
                                            <p className="not_default_shipping_address_show"> 7th Floor, Fair Plaza,
                                                Mirpur-1, Dhaka-1216, Bangladesh, Dhaka, Dhaka, 1216, Bangladesh,
                                                01811151115, 01707170717, </p>
                                        </div>
                                        <div className="col-md-3 shipping-address-update-button">
                                            <form>
                                                <input name="_method" type="hidden"
                                                                                value="DELETE"/><input name="_token"
                                                                                                      type="hidden"/>
                                                <button type="submit"
                                                        className="btn btn-danger btn-sm text-right ml-1 me-1">
                                                    <i className="fas fa-trash-alt"/></button>
                                            </form>
                                            <a href="">
                                                <button className="btn btn-warning btn-sm text-right me-1"><i className={'fas fa-edit'}/></button>
                                            </a>
                                            <a href="http://localhost:8000/client/shipping_address/make-default/1">
                                                <button className="btn btn-success btn-sm text-right ml-1 me-1">Make
                                                    Default
                                                </button>
                                            </a>
                                        </div>
                                    </div>
                                </div>

                                <div className="form-opening-header user-profile">
                                    <h3 className="text-center text-success" id="open_create_shipping_form"
                                        ><i className="fas fa-plus-square"/></h3>
                                    <h3  className="text-center text-danger" id="close_create_shipping_form" style={{display: "none"}}>
                                        <i className="fas fa-minus-square"/>
                                    </h3>
                                </div>

                                <div className="card user-profile-shipping">
                                    <div className="">
                                        <form  className="profile_form">
                                            <input name="_token" type="hidden"/>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="company_name">Address</label>
                                                    <input className="form-control" placeholder="Insert Address"
                                                           name="company_name" type="text"
                                                           id="company_name"/>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="address">City</label>
                                                    <input className="form-control" placeholder="Insert City"
                                                           name="address" type="text" id="address"/>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="city">Region</label>
                                                    <input className="form-control" placeholder="Insert Region"
                                                           name="city" type="text" id="city"/>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="region">Country</label>
                                                    <input className="form-control" placeholder="Insert Country"
                                                           name="region" type="text" id="region"/>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="country">Post Code</label>
                                                    <input className="form-control" placeholder="Insert Post Code"
                                                           disabled="disabled" name="country" type="text"
                                                            id="country"/>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="post_code">Mobile Number</label>
                                                    <input className="form-control" placeholder="Insert Mobile Number"
                                                           name="post_code" type="text" id="post_code"/>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="nid">Phone Number (Additional)</label>
                                                    <input className="form-control" placeholder="Phone Number (Additional) "
                                                           name="nid" type="text" id="nid"/>
                                                </div>
                                                <div className="col-md-6 mb-4">
                                                    <label htmlFor="dob">Landmarks (Additional)</label>
                                                    <textarea className="form-control" placeholder="Floor, Apt. No or tell us how we can find the shipping address easily."
                                                           id="datetimepicker"/>
                                                </div>
                                                <div className="col-md-6 mb-4">
                                                    <div className="form-check form-switch">
                                                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckChecked" />
                                                            <label className="form-check-label"
                                                                   htmlFor="flexSwitchCheckChecked">Default</label>
                                                    </div>
                                                </div>

                                                <div className="col-md-12 text-center">
                                                    <button className="add-address-btn" type="submit">
                                                        Add Shipping Address
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ShippingAddress;
