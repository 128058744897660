import React, {useState} from 'react';
import SideNavBar from "../Include/SideNavBar";
import ClintProfileTopBar from "../Include/ClintProfileTopBar";
import {Link} from "react-router-dom";
import image1 from "../../../../Assets/Images/newarival/1.jpg";
import image2 from "../../../../Assets/Images/newarival/2.jpg";
import image3 from "../../../../Assets/Images/newarival/3.jpg";

const Delivered = () => {

    const [products] = useState([
        {
            orderNumber: '#245875641',
            orderPlace: '24-12-2021',
            orderManage: '/my/manage-order',
            storeName:[
                {productStoreName: '360 Shopping'},
            ],
            subProduct: [
                {
                    productImg: image1,
                    productDescription: 'This is description',
                    productQty: '5',
                    productWarranty: '3',
                    productStatus: 'Delivered',
                    productPrice: '65478'
                },
                {
                    productImg: image2,
                    productDescription: 'This is description',
                    productQty: '4',
                    productWarranty: '3',
                    productStatus: 'Delivered',
                    productPrice: '65478'
                },
            ],
        },

        {
            orderNumber: '#245875641',
            orderPlace: '24-12-2021',
            orderManage: '/my/manage-order',
            storeName:[
                {productStoreName: '360 Shopping'},
            ],
            subProduct: [
                {
                    productImg: image1,
                    productDescription: 'This is description',
                    productQty: '5',
                    productWarranty: '3',
                    productStatus: 'Delivered',
                    productPrice: '65478'
                },
                {
                    productImg: image2,
                    productDescription: 'This is description',
                    productQty: '4',
                    productWarranty: '3',
                    productStatus: 'Delivered',
                    productPrice: '65478'
                },
            ],
        }
    ])

    return (
        <div>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="my-dashboard-inner">
                            <div className="row">
                                <div className="col-md-3">
                                    <SideNavBar/>
                                </div>
                                <div className="col-md-9">
                                    <ClintProfileTopBar/>
                                    <div className="all-order-dropdown">
                                        <div className="main-all-order-section">
                                            <label className={'all-order-section-label'}>Show:</label>
                                            <select name="" id="" className={'all-order-select-section'}>
                                                <option value="" className={'all-order-option-section'}>Last 5 Order
                                                </option>
                                                <option value="" className={'all-order-option-section'}>Last 1 Week
                                                </option>
                                                <option value="" className={'all-order-option-section'}>Last 15 Days
                                                </option>
                                                <option value="" className={'all-order-option-section'}>Last 30 Days
                                                </option>
                                                <option value="" className={'all-order-option-section'}>All Order
                                                </option>
                                            </select>
                                        </div>
                                        <h5 className={'my-order-title'}>Delivered Order</h5>
                                    </div>
                                    <div className="order-table-section">
                                        {products.map((item, index) => (
                                            <div className="repeat-table" key={index}>
                                                <div className="product-section-header">
                                                    <div className="order-table-top-bar">
                                                        <h6>{item.orderNumber}</h6>
                                                        <p>Place on Date:{item.orderPlace}</p>
                                                        <Link to={item.orderManage}>Manage</Link>
                                                    </div>
                                                    <div className="main-product-section-inner">
                                                        {item.storeName.map((store,storeIndex)=>(
                                                            <h6 className={'pt-2 heading-after'} key={storeIndex}>{store.productStoreName}</h6>
                                                        ))}
                                                        {item.subProduct.map((itemProduct, itemProductIndex) => (
                                                            <div className="main-product-section" key={itemProductIndex}>
                                                                <div className="all-order-product-image">
                                                                    <img src={itemProduct.productImg}
                                                                         className={'all-order-product'}
                                                                         alt=""/>
                                                                </div>
                                                                <div
                                                                    className="all-order-product-description">
                                                                    <p>{itemProduct.productDescription}</p>
                                                                </div>
                                                                <p>Qty:{itemProduct.productQty}</p>
                                                                <p>Warranty
                                                                    Left: {itemProduct.productWarranty} Days</p>
                                                                <p>{itemProduct.productStatus}</p>
                                                                <p>৳ {itemProduct.productPrice}</p>
                                                            </div>
                                                        ))}
                                                    </div>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Delivered;
