import React from "react";
import {Link} from "react-router-dom";
import CartIcon from "../../Assets/Images/icons/shopping_bag.png";

const ProductCartMaster = () => {
  return(
      <div className="cart-section">
          {/*<Link to="" className="gray-text">*/}
          {/*    */}
          {/*</Link>*/}
          <div className="cart-icon-item">
              <div className="icon">
                  <img src={CartIcon} alt={'Cart Icon'}/>
              </div>
              <div className="item">
                  <h5>999</h5>
              </div>
          </div>
          <div className="cart-price">
              <h6>৳ <span>900000</span></h6>
          </div>
      </div>
  )
}
export default ProductCartMaster