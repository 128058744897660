import React from 'react'
import {BrowserRouter, Route} from 'react-router-dom'
import './Assets/css/style.css'
import TopBanner from './Components/Include/TopBanner'
import HeadLine from './Components/Include/HeadLine'
import TopBar from './Components/Include/TopBar'
import Search from './Components/Include/Search'
import CatagoryMenu from './Components/Include/CatagoryMenu'
import Footer from './Components/Include/Footer'
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'
import '../node_modules/bootstrap/dist/js/bootstrap.bundle.min.js'
import '@fortawesome/fontawesome-free/js/brands.min'
import '@fortawesome/fontawesome-free/js/solid.min'
import '@fortawesome/fontawesome-free/js/fontawesome.min'
import Home from './Components/Pages/Home'
import Product from './Components/Pages/Products/Product'
import Category from './Components/Pages/Catagorys/Category'
import MyCart from './Components/Pages/Cart/MyCart'
import Checkout from './Components/Pages/Checkouts/Checkout'
import Payment from './Components/Pages/Cart/include/Payment'
import Merchant from "./Components/Pages/Merchant/Merchant";
import AllMerchentShop from "./Components/Pages/AllShopPage/AllMerchentShop";
import About from "./Components/Pages/MerchantAbout/About";
import My from "./Components/Pages/UserDashboard/My";
import Chat from "./Components/Pages/UserDashboard/Chat/Chat";
import AboutUs from "./Components/Pages/OtherPages/AboutUs";
import TermsAndConditions from "./Components/Pages/OtherPages/Terms&Conditions";
import PrivacyPolicy from "./Components/Pages/OtherPages/PrivacyPolicy";
import ReturnPolicy from "./Components/Pages/OtherPages/ReturnPolicy";
import RefundPolicy from "./Components/Pages/OtherPages/RefundPolicy";
import Mission from "./Components/Pages/OtherPages/Mission";
import Vision from "./Components/Pages/OtherPages/Vision";
import DeliveryPolicy from "./Components/Pages/OtherPages/DeliveryPolicy";
import FAQ from "./Components/Pages/OtherPages/FAQ";
import Contact from "./Components/Pages/OtherPages/Contact";
import VideoTutorial from "./Components/Pages/OtherPages/VideoTutorial";
import Nav from "./Components/Pages/OtherPages/Include/Nav";
import PaymentCompleted from "./Components/Pages/Cart/include/PaymentCompleted";
import Wishlisht from "./Components/Pages/WishlistPage/Wishlisht";
import Blog from "./Components/Pages/BologPage/Blog";
import BlogDetails from "./Components/Pages/BologPage/BlogDetails";
// import logo from './logo.svg';
import './App.css';
import ProductCart from "./Components/Include/ProductCart";
import AllOrder from "./Components/Pages/UserDashboard/Order/AllOrder";
import PendingOrder from "./Components/Pages/UserDashboard/Order/PendingOrder";
import Delivered from "./Components/Pages/UserDashboard/Order/Delivered";
import AllTransaction from "./Components/Pages/UserDashboard/TransactionList/AllTransaction";
import AllInvoice from "./Components/Pages/UserDashboard/TransactionList/AllInvoice";
import UserProfile from "./Components/Pages/UserDashboard/Profile/UserProfile";
import ShippingAddress from "./Components/Pages/UserDashboard/Profile/ShippingAddress";
import AccontAndSecurity from "./Components/Pages/UserDashboard/Profile/AccountAndSecurity";
import MyTickets from "./Components/Pages/UserDashboard/Support/MyTickets";
import OpenTicket from "./Components/Pages/UserDashboard/Support/OpenTicket";
import ManageOrder from "./Components/Pages/UserDashboard/Order/ManageOrder";
import ScrollToTop from "./Components/ScrollToTop";


function App() {
    return (
        <BrowserRouter>
                <ScrollToTop/>
            {/*<TopBanner/>*/}
            <HeadLine/>
            <TopBar/>
            <Search/>
            <CatagoryMenu/>
            <ProductCart/>
            <Route exact path="/" component={Home}/>
            <Route path="/product" component={Product}/>
            <Route path="/catagory" component={Category}/>
            <Route path="/mycart" component={MyCart}/>
            <Route path="/checkout" component={Checkout}/>
            <Route path="/secure-payment" component={Payment}/>
            <Route path="/merchent" component={Merchant}/>
            <Route path="/all-merchant" component={AllMerchentShop}/>
            <Route path="/about" component={About}/>
            <Route exact path="/my" component={My}/>
            <Route exact path="/my/live-chat" component={Chat}/>
            <Route exact path="/my/all-order" component={AllOrder}/>
            <Route exact path="/my/pending-order" component={PendingOrder}/>
            <Route exact path="/my/delivered-order" component={Delivered}/>
            <Route exact path="/my/all-transaction" component={AllTransaction}/>
            <Route exact path="/my/all-invoice" component={AllInvoice}/>
            <Route exact path="/my/profile" component={UserProfile}/>
            <Route exact path="/my/shipping-address" component={ShippingAddress}/>
            <Route exact path="/my/account-and-security" component={AccontAndSecurity}/>
            <Route exact path="/my/open-ticket" component={OpenTicket}/>
            <Route exact path="/my/my-ticket" component={MyTickets}/>
            <Route exact path="/my/manage-order" component={ManageOrder}/>

            <Route path="/aboutus" component={AboutUs}/>
            <Route path="/terms-and-conditions" component={TermsAndConditions}/>
            <Route path="/privacy-policy" component={PrivacyPolicy}/>
            <Route path="/return-policy" component={ReturnPolicy}/>
            <Route path="/refund-policy" component={RefundPolicy}/>
            <Route path="/mission" component={Mission}/>
            <Route path="/vision" component={Vision}/>
            <Route path="/delivery-policy" component={DeliveryPolicy}/>
            <Route path="/faq" component={FAQ}/>
            <Route path="/contact" component={Contact}/>
            <Route path="/video-tutorial" component={VideoTutorial}/>
            <Route path="/all-policy-menu" component={Nav}/>
            <Route path="/payment-completed" component={PaymentCompleted}/>
            <Route path="/wishlist" component={Wishlisht}/>
            <Route path="/blog" component={Blog}/>
            <Route path="/bolgdetails" component={BlogDetails}/>
            <Footer/>
        </BrowserRouter>
    );
}

export default App;