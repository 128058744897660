import React from "react";
import images1 from "../../../Assets/Images/newarival/2.jpg"
import {Link} from "react-router-dom";
import BlogCategory from "./include/BlogCategory";

const Blog = () => {

    return (
        <>
            <div className="container">
                <div className="blog-section">
                    <div className="row">
                        <div className="col-md-3">
                            <BlogCategory/>
                        </div>
                        <div className="col-md-9">
                            <div className="bolg-section-inner bg-white">
                                <div className="bolg-section-thumbnail">
                                    <Link to={'/bolgdetails'}><img src={images1} className={'img-fluid blog-thumbnail'} alt=""/></Link>
                                    <div className="category-title-section">
                                        <h6 className={'blog-title'}>Web</h6>
                                        <Link to={'/bolgdetails'} className={'blog-heading'}>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</Link>
                                        <Link to={'/bolgdetails'} className={'bolg-description'}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias cumque
                                            dolores ducimus earum eligendi fugit impedit ipsam ipsum minus nesciunt,
                                            officia, quidem, voluptates.</Link>
                                        <div className="name-and-date">
                                            <p className={'bolg-date-and-name'}>Naimul Hasan</p>
                                            <p className={'bolg-date-and-name'}>15-08-2021</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="bolg-section-thumbnail">
                                    <Link to={'/bolgdetails'}><img src={images1} className={'img-fluid blog-thumbnail'} alt=""/></Link>
                                    <div className="category-title-section">
                                        <h6 className={'blog-title'}>Web</h6>
                                        <Link to={'/bolgdetails'} className={'blog-heading'}>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</Link>
                                        <Link to={'/bolgdetails'} className={'bolg-description'}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias cumque
                                            dolores ducimus earum eligendi fugit impedit ipsam ipsum minus nesciunt,
                                            officia, quidem, voluptates.</Link>
                                        <div className="name-and-date">
                                            <p className={'bolg-date-and-name'}>Naimul Hasan</p>
                                            <p className={'bolg-date-and-name'}>15-08-2021</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="bolg-section-thumbnail">
                                    <Link to={'/bolgdetails'}><img src={images1} className={'img-fluid blog-thumbnail'} alt=""/></Link>
                                    <div className="category-title-section">
                                        <h6 className={'blog-title'}>Web</h6>
                                        <Link to={'/bolgdetails'} className={'blog-heading'}>Lorem ipsum dolor sit amet, consectetur adipisicing elit.</Link>
                                        <Link to={'/bolgdetails'} className={'bolg-description'}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias cumque
                                            dolores ducimus earum eligendi fugit impedit ipsam ipsum minus nesciunt,
                                            officia, quidem, voluptates.</Link>
                                        <div className="name-and-date">
                                            <p className={'bolg-date-and-name'}>Naimul Hasan</p>
                                            <p className={'bolg-date-and-name'}>15-08-2021</p>
                                        </div>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default Blog