import React from 'react';
import SideNavBar from "../Include/SideNavBar";
import ClintProfileTopBar from "../Include/ClintProfileTopBar";
import {Link} from "react-router-dom";
import image1 from "../../../../Assets/Images/newarival/1.jpg";
import image2 from "../../../../Assets/Images/newarival/2.jpg";
import image3 from "../../../../Assets/Images/newarival/3.jpg";

const ManageOrder = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="my-dashboard-inner">
                        <div className="row">
                            <div className="col-md-3">
                                <SideNavBar/>
                            </div>
                            <div className="col-md-9">
                                <ClintProfileTopBar/>
                                <div className="customer-order-details">
                                    <p className={'order-details'}>Order Details</p>

                                    <div className="order-number-and-total">
                                        <div><p className="order-number">Order&nbsp;#623048336321430</p>
                                            <p className="">Placed on 22 Dec 2021 01:04:25</p>
                                        </div>
                                        <div className=""><span
                                            className="detail-info-total-title">Total:&nbsp;</span><span className="">৳ 1,263</span>
                                        </div>
                                    </div>
                                    <div className="customer-order-tracking">
                                        <div className="row">
                                            <div className="col-md-6">
                                                <div className="card mt-2 mb-2 shipping-status-area">
                                                    <div className="card-body">
                                                        <div className="card mt-3" style={{border: "none"}}>
                                                            <div className="card-body date-status-area">
                                                                <div className="vl"/>
                                                                <div className="date-area">
                                                                    <h6 className="date-h6">
                                                                        <i className="fas fa-arrow-alt-circle-up  text-success margin-left-icon"/>
                                                                        <div
                                                                            className="spinner-border text-primary fast-one-spinner"
                                                                            role="status"/>
                                                                        <strong className="me-2"> Wednesday, 27th of
                                                                            January, 2021</strong></h6>

                                                                </div>
                                                                <div className="date-area">
                                                                </div>
                                                                <div className="date-area">
                                                                    <p className="status-time">
                                                                        <i className="fas fa-long-arrow-alt-right text-info"/>
                                                                        <span
                                                                            className="text-success"> 06:06:04 AM </span>
                                                                        <i className="fas fa-long-arrow-alt-right text-success"/>
                                                                        <strong> Shipped</strong></p>
                                                                    <p className={'des-in-pickup'}>Your package has been
                                                                        shipped by Aladiner Prodip.</p>
                                                                    <p className={'des-in-pickup'}>
                                                                        <strong>Note:</strong> This is comment</p>
                                                                </div>
                                                                <div className="date-area">
                                                                    <p className="status-time">
                                                                        <i className="fas fa-long-arrow-alt-right text-info"/>
                                                                        <span
                                                                            className="text-success"> 06:05:47 AM </span>
                                                                        <i className="fas fa-long-arrow-alt-right text-success"/>
                                                                        <strong> Pickup in Process</strong>
                                                                    </p>
                                                                    <p className={'des-in-pickup'}>Your package has been
                                                                        packed and is being handed over to a logistics
                                                                        partner.</p>
                                                                    <p className={'des-in-pickup'}>
                                                                        <strong>Note:</strong> This is comment</p>
                                                                </div>
                                                                <div className="date-area">
                                                                    <h6 className="date-h6"><i
                                                                        className="fas fa-arrow-alt-circle-up text-info"/><strong
                                                                        className="text-success me-2"> Saturday, 17th of
                                                                        October, 2020</strong></h6>
                                                                    <p className="status-time">
                                                                        <i className="fas fa-long-arrow-alt-right text-info"/>
                                                                        <span
                                                                            className="text-success"> 08:19:11 PM </span>
                                                                        <i className="fas fa-long-arrow-alt-right text-success"/>
                                                                        <strong> Package being prepared</strong>
                                                                    </p>

                                                                    <p className={'des-in-pickup'}>Thank you for
                                                                        shopping at Aladiner Prodip! Your order is being
                                                                        verified.</p>
                                                                    <p className={'des-in-pickup'}>
                                                                        <strong>Note:</strong> This is comment</p>
                                                                </div>
                                                                <i className="fas fa-arrow-alt-circle-up text-success starting-arrow"/>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default ManageOrder;
