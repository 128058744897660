import React, {useEffect, useState} from "react";
import {Link, NavLink} from "react-router-dom";
import Nav from "./Include/Nav";
import SideNavBar from "./Include/SideNavBar";
import ClintProfileTopBar from "./Include/ClintProfileTopBar";
import image1 from '../../../Assets/Images/flashdeal/1.jpg'
import image2 from '../../../Assets/Images/flashdeal/2.jpg'
import image3 from '../../../Assets/Images/flashdeal/3.jpg'
import image4 from '../../../Assets/Images/flashdeal/4.jpg'
import image5 from '../../../Assets/Images/flashdeal/5.jpg'

const My = () => {

    const [card] = useState([
        {title: 'All Order', icon: '<i class="fas fa-envelope"/>', count: 250},
        {title: 'Pending Order', icon: '<i class="fas fa-envelope"/>', count: 250},
        {title: 'Delivered', icon: '<i class="fas fa-envelope"/>', count: 250},
        {title: 'All Transaction', icon: '<i class="fas fa-envelope"/>', count: 250},
        {title: 'All Invoice', icon: '<i class="fas fa-envelope"/>', count: 250},
        {title: 'My Ticket', icon: '<i class="fas fa-envelope"/>', count: 250},
    ])

    const [recentTicket, setRecentTicket] = useState([
        {
            title: '#355481 - Affiliate Withdrawal Request Withdrawal Request Withdrawal',
            status: 'Close',
            date: 'Last Updated: Tuesday, November 10th,2020 (06:09)'
        },
        {
            title: '#355481 - Affiliate Withdrawal Request Withdrawal Request Withdrawal',
            status: 'Close',
            date: 'Last Updated: Tuesday, November 10th,2020 (06:09)'
        },
        {
            title: '#355481 - Affiliate Withdrawal Request Withdrawal Request Withdrawal',
            status: 'Close',
            date: 'Last Updated: Tuesday, November 10th,2020 (06:09)'
        },
        {
            title: '#355481 - Affiliate Withdrawal Request Withdrawal Request Withdrawal',
            status: 'Close',
            date: 'Last Updated: Tuesday, November 10th,2020 (06:09)'
        },
        {
            title: '#355481 - Affiliate Withdrawal Request Withdrawal Request Withdrawal',
            status: 'Close',
            date: 'Last Updated: Tuesday, November 10th,2020 (06:09)'
        },
        {
            title: '#355481 - Affiliate Withdrawal Request Withdrawal Request Withdrawal',
            status: 'Close',
            date: 'Last Updated: Tuesday, November 10th,2020 (06:09)'
        },
    ])

    const [recentOrder, setRecentOrder] = useState([
        {
            img: image1,
            imgDescription: 'simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry\'s standard',
            price: '12454'
        },
        {
            img: image2,
            imgDescription: 'crambled it to make a type specimen book. It has survived not only five centuries,',
            price: '12454'
        },
        {
            img: image3,
            imgDescription: 'more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum',
            price: '12454'
        },
        {
            img: image4,
            imgDescription: 'it look like readable English. Many desktop publishing packages and web page editors now use Lorem Ipsum as their default model tex',
            price: '12454'
        },
        {
            img: image5,
            imgDescription: 'There are many variations of passages of Lorem Ipsum available, but the majority have suffered alteration in some form',
            price: '12454'
        },

    ])
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="my-dashboard-inner">
                            <div className="row">
                                {/*<Nav/>*/}
                                <div className="col-md-3">
                                    <SideNavBar/>
                                </div>
                                <div className="col-md-9">
                                    <ClintProfileTopBar/>
                                    <div className="my-dashboard-main-content">
                                        <div className="my-dashboard-overview">
                                            <div className="row">
                                                {card.map((cardItem, cardIndex) => (
                                                    <div className="col-md-4 p-1" key={cardIndex}>
                                                        <div
                                                            className={`my-dashboard-overview-card custom-eliment-shaow new-design-${cardIndex}`}>
                                                            <div className="my-dashboard-overview-card-icon">
                                                                <span
                                                                    dangerouslySetInnerHTML={{__html: cardItem.icon}}/>
                                                            </div>
                                                            <div className="my-dashboard-overview-card-content">
                                                                <p>{cardItem.title}</p>
                                                                <p>{cardItem.count}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                            </div>
                                            <div className="my-order-content-inner">
                                                <div className="row">
                                                    <div className="col-md-6 p-1">
                                                        <div
                                                            className="my-recent-order  active-and-recent-order-bg custom-eliment-shaow">
                                                            <h5>Recent Order</h5>
                                                            <div className="recent-order-section">
                                                                {recentOrder.map((orderImg, orderImgIndex) => (
                                                                    <div className="recent-order-section-inner"
                                                                         key={orderImgIndex}>
                                                                        <div className="order-image">
                                                                            <img src={orderImg.img}
                                                                                 className={'order-image-inner'}
                                                                                 alt=""/>
                                                                        </div>
                                                                        <div className="order-description">
                                                                            <p>{orderImg.imgDescription}</p>
                                                                        </div>
                                                                        <div className="order-price">
                                                                            <p>৳ {orderImg.price}</p>
                                                                        </div>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="col-md-6 p-1">
                                                        <div
                                                            className="my-active-order active-and-recent-order-bg custom-eliment-shaow ">
                                                            <h5>Recent Support Tickets<Link to=""><i
                                                                className="fas fa-plus recent-support-tickets-icon-float"/></Link>
                                                            </h5>
                                                            <div className="recent-support-ticket-list">
                                                                {recentTicket.map((item, index) => (
                                                                    <div className="recent-support-ticket-content-list"
                                                                         key={index}>
                                                                        <Link to={'/'}
                                                                              className={'recent-support-ticket-link'}>
                                                                            <div className="support-ticket-heading">
                                                                                <p className={'recent-support-ticket-list-inner'}>{item.title}</p>
                                                                                <label
                                                                                    className="label-open-and-close">{item.status}</label>
                                                                            </div>
                                                                            <small>{item.date}</small>
                                                                        </Link>
                                                                    </div>
                                                                ))}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default My