import React, {useEffect, useState} from 'react'
import CategoryMenuLeft from "./CategoryMenuLeft";
import {Link} from "react-router-dom";

const CatagoryMenu = () => {
    const [sliderWidth, setSliderWidth] = useState(0)
    const [url, setUrl] = useState(0)
    useEffect(() => {
        // Update the document title using the browser API
        let width = document.getElementById('slider_area')
        if (width != null) {
            width = width.offsetWidth
        } else {
            width = document.getElementById('category_bar_width').offsetWidth
        }
        width -= width * .025
        setSliderWidth(width)
        setUrl(window.location.pathname)
    }, []);
    return (
        <div className="container-fluid catagorymenu-color ">
            <div className="container">
                <div className="row">
                    <div className="col-md-3">
                        <div className="category-menu-nav">
                            <nav className="navbar navbar-expand-lg">
                                <ul className="navbar-nav category-navbar-nav">
                                    <li className={url == '/' ? 'd-none' : 'nav-item dropdown category-menu-dropdown'}>
                                        <Link to={'/'} className="nav-link dropdown-toggle text-center text-white">
                                            <i className="fas fa-list"/> ক্যাটাগরি <i
                                            className="fas fa-chevron-down dropdown-icon"/>
                                        </Link>
                                        <ul className="dropdown-menu  category-menu-dropdown-item">
                                            <CategoryMenuLeft/>
                                        </ul>
                                    </li>
                                    <li className={url == '/' ? 'home-category-menu-hover-disabled' : 'd-none'}><i
                                        className="fas fa-list"/> ক্যাটাগরি
                                    </li>
                                </ul>
                            </nav>
                        </div>
                    </div>
                    <div className="col-md-9" id={'category_bar_width'}>
                        <ul className="navbar-nav navbar-flex ">
                            <li className="nav-item dropdown custom-dropdown-toggle">
                                <Link to={'/'} className="nav-link dropdown-color dropdown-toggle"
                                      data-bs-toggle="dropdown">
                                    ক্যাটাগরি <i className="fas fa-chevron-down dropdown-icon"/>
                                </Link>
                                <ul className="dropdown-menu right-category-dropdown  slideInDown"
                                    style={{width: sliderWidth}}>
                                    <div className={'row'}>
                                        <div className="col-md-4">
                                            <li><Link to={'/'} className="dropdown-item"> ক্যাটাগরি সাইটম্যাপ </Link>
                                            </li>
                                            <li><Link to={'/'} className="dropdown-item"> ক্যাটাগরি সমূহ </Link></li>
                                        </div>
                                        <div className="col-md-4">
                                            <li><Link className="dropdown-item" to="/">Home</Link></li>
                                            <li><Link className="dropdown-item" to="/catagory">category</Link></li>
                                            <li><Link className="dropdown-item" to="/product">Product</Link></li>
                                            <li><Link className="dropdown-item" to="/mycart">Cart</Link></li>
                                            <li><Link className="dropdown-item" to="/checkout">Checkout</Link></li>
                                            <li><Link className="dropdown-item" to="/secure-payment">Payment</Link></li>
                                            <li><Link className="dropdown-item" to="/merchent">Merchent</Link></li>
                                            <li><Link className="dropdown-item" to="/all-merchant">All-Merchant</Link>
                                            </li>
                                            <li><Link className="dropdown-item" to="/about">About-Merchant</Link></li>
                                            <li><Link className="dropdown-item" to="/my">Customer profile</Link></li>
                                            <li><Link className="dropdown-item" to="/blog">Blog</Link></li>
                                        </div>
                                        <div className="col-md-4">
                                            <li><Link to={'/'} className="dropdown-item">Action</Link></li>
                                            <li><Link to={'/'} className="dropdown-item">Another action</Link></li>
                                            <li><Link to={'/'} className="dropdown-item">Something else here</Link></li>
                                        </div>
                                    </div>
                                </ul>
                            </li>
                            <li className="nav-item dropdown custom-dropdown-toggle">
                                <Link to={'/'} className="nav-link dropdown-color">নতুন পণ্য</Link>
                            </li>
                            <li className="nav-item dropdown custom-dropdown-toggle">
                                <Link to={'/'} className="nav-link dropdown-color">অফার</Link>
                            </li>
                            <li className="nav-item dropdown custom-dropdown-toggle">
                                <Link to={'/'} className="nav-link dropdown-color dropdown-toggle"
                                      data-bs-toggle="dropdown">
                                    জনপ্রিয় ক্যাটাগরি <i className="fas fa-chevron-down dropdown-icon"/>
                                </Link>
                                <ul className="dropdown-menu  slideInDown coustom-dropdown">
                                    <li><Link to={'/'} className="dropdown-item">১টি কিনলে ১ টি ফ্রি</Link></li>
                                    <li><Link to={'/'} className="dropdown-item">ইলেক্ট্রনিক্স ডিভাইস</Link></li>
                                    <li><Link to={'/'} className="dropdown-item">ল্যাপটপ</Link></li>
                                </ul>
                            </li>

                            <li className="nav-item dropdown custom-dropdown-toggle">
                                <Link to={'/'} className="nav-link dropdown-color dropdown-toggle"
                                      data-bs-toggle="dropdown">
                                    জনপ্রিয় বিক্রেতা <i className="fas fa-chevron-down dropdown-icon"/>
                                </Link>
                                <ul className="dropdown-menu  slideInDown coustom-dropdown">
                                    <li><Link to={'/'} className="dropdown-item">সকল বিক্রেতা</Link></li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown custom-dropdown-toggle">
                                <Link to={'/'} className="nav-link dropdown-color dropdown-toggle"
                                      data-bs-toggle="dropdown"> অন্যান্য <i
                                    className="fas fa-chevron-down dropdown-icon"/></Link>
                                <ul className="dropdown-menu  slideInDown coustom-dropdown">
                                    <li><Link to={'/'} className="dropdown-item">সকল বিক্রেতা</Link></li>
                                    <li><Link to={'/'} className="dropdown-item">সকল ব্রান্ড</Link></li>
                                    <li><Link to={'/contact'} className="dropdown-item">যোগাযোগ</Link></li>
                                    <li><Link to={'/vision'} className="dropdown-item">ভিশন</Link></li>
                                    <li><Link to={'/mission'} className="dropdown-item">মিশন</Link></li>
                                    <li><Link to={'/privacy-policy'} className="dropdown-item">পলিসি</Link></li>
                                    <li><Link to={'delivery-policy'} className="dropdown-item">ডেলিভারি পলিসি</Link></li>
                                    <li><Link to={'/terms-and-conditions'} className="dropdown-item">শর্তাবলী</Link></li>
                                </ul>
                            </li>
                            <li className="nav-item dropdown custom-dropdown-toggle">
                                <Link to={'/'} className="nav-link dropdown-color"> সিরাজগঞ্জ কর্ণার </Link>
                            </li>
                            <li className="nav-item">
                                <Link to={'/'} className="nav-link dropdown-color"><i
                                    className="fas fa-map-marker-alt"/> Track Order</Link>
                            </li>
                        </ul>

                    </div>
                </div>
            </div>
        </div>
    )
}
export default CatagoryMenu


