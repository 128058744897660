import React from 'react';
import SideNavBar from "../Include/SideNavBar";
import ClintProfileTopBar from "../Include/ClintProfileTopBar";
import {Link} from "react-router-dom";

const MyTickets = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="my-dashboard-inner">
                        <div className="row">
                            <div className="col-md-3">
                                <SideNavBar/>
                            </div>
                            <div className="col-md-9">
                                <ClintProfileTopBar/>
                                <div className="user-profile">
                                    <h5 className={'my-order-title text-center'}>My Support Tickets</h5>
                                    <div className="support-ticket-category text-center">
                                        <button className={'support-ticket-category-btn'}>Open</button>
                                        <button className={'support-ticket-category-btn'}>Answered</button>
                                        <button className={'support-ticket-category-btn'}>Customer Reply</button>
                                        <button className={'support-ticket-category-btn'}>Close</button>
                                    </div>
                                </div>
                                    <div className="all-transaction-table">
                                        <div className="show-ticket">
                                            <div className="main-all-order-section">
                                                <label className={'all-order-section-label'}>Show:</label>
                                                <select name="" id="" className={'all-order-select-section'}>
                                                    <option value="" className={'all-order-option-section'}> 10 entries
                                                    </option>
                                                    <option value="" className={'all-order-option-section'}>25 entries
                                                    </option>
                                                    <option value="" className={'all-order-option-section'}>50 entries
                                                    </option>
                                                    <option value="" className={'all-order-option-section'}>All
                                                    </option>
                                                </select>
                                            </div>
                                            <div className="transaction-list-search-box">
                                                <input className="transaction-list-search-box-main" type="text" placeholder="Search"/>
                                                <span className={'search-icon-absolute'}><i className="fas fa-search"/></span>
                                            </div>
                                        </div>
                                        <table className="table custom-table table-hover">
                                            <thead className={'ticket-table-heading'}>
                                            <tr>
                                                <th>Ticket Id</th>
                                                <th>Category</th>
                                                <th>Product</th>
                                                <th>Subject</th>
                                                <th>Date</th>
                                                <th>Status</th>
                                            </tr>
                                            </thead>
                                            <tbody className={'ticket-table-body'}>
                                            <tr>
                                                <td><Link to={'/'}>654824</Link></td>
                                                <td>Billing Support</td>
                                                <td><Link to={'/'}>64218</Link></td>
                                                <td><Link to={'/'}>Affiliate Withdrawal Request</Link></td>
                                                <td>
                                                    Tuesday, November 10th, 2020 (06:09)

                                                </td>
                                                <td><span  className={'ticket-status-one'}>Close</span></td>
                                            </tr>
                                            <tr>
                                                <td><Link to={'/'}>54785</Link></td>
                                                <td>Technical Support</td>
                                                <td><Link to={'/'}>64218</Link></td>
                                                <td><Link to={'/'}>Support - Testing</Link></td>
                                                <td>
                                                    Tuesday, November 10th, 2020 (06:09)
                                                </td>
                                                <td><span className={'ticket-status-one'}>Close</span></td>
                                            </tr>
                                            <tr>
                                                <td><Link to={'/'}>47584</Link></td>
                                                <td>Pre-Sales Support</td>
                                                <td><Link to={'/'}>64218</Link></td>
                                                <td><Link to={'/'}>Affiliate Withdrawal Request</Link></td>
                                                <td>
                                                    Tuesday, November 10th, 2020 (06:09)
                                                </td>
                                                <td><span className={'ticket-status-tow'}>In Progress</span></td>
                                            </tr>
                                            <tr>
                                                <td><Link to={'/'}>68754</Link></td>
                                                <td>General Enquiries</td>
                                                <td><Link to={'/'}>64218</Link></td>
                                                <td><Link to={'/'}>Testing Support</Link></td>
                                                <td>
                                                    Tuesday, November 10th, 2020 (06:09)
                                                </td>
                                                <td><span className={'ticket-status-four'}>Hold on</span></td>
                                            </tr>

                                            <tr>
                                                <td><Link to={'/'}>98754</Link></td>
                                                <td>Test</td>
                                                <td><Link to={'/'}>64218</Link></td>
                                                <td><Link to={'/'}>Pre-Sales Support</Link></td>
                                                <td>
                                                    Tuesday, November 10th, 2020 (06:09)
                                                </td>
                                                <td><span className={'ticket-status-one'}>Close</span></td>
                                            </tr>

                                            <tr>
                                                <td><Link to={'/'}>35485</Link></td>
                                                <td>Technical Support</td>
                                                <td><Link to={'/'}>64218</Link></td>
                                                <td><Link to={'/'}>Testing Support</Link></td>
                                                <td>
                                                    Tuesday, November 10th, 2020 (06:09)
                                                </td>
                                                <td> <span className={'ticket-status-three'}>Open</span></td>
                                            </tr>
                                            </tbody>
                                        </table>
                                        <div className="all-transaction-page-info">
                                            <div className="data-tables-info">
                                                <p>Showing 1 to 3 of 3 entries</p>
                                            </div>
                                            <div className="data-tables-paginate">
                                                <nav>
                                                    <ul className="pagination">
                                                        <li className="page-item disabled">
                                                            <Link className="page-link" to={'/'}>Previous</Link>
                                                        </li>
                                                        <li className="page-item active">
                                                            <Link className="page-link" to={'/'}>1</Link>
                                                        </li>
                                                        <li className="page-item">
                                                            <Link className="page-link" to={'/'}>2</Link>
                                                        </li>
                                                        <li className="page-item">
                                                            <Link className="page-link" to={'/'}>3</Link>
                                                        </li>
                                                        <li className="page-item">
                                                            <Link className="page-link" to={'/'}>Next</Link>
                                                        </li>
                                                    </ul>
                                                </nav>
                                            </div>
                                        </div>
                                    </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default MyTickets;
