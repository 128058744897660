import React, { useState } from 'react'
import images1 from '../../Assets/Images/flashdeal/1.jpg'
import images2 from '../../Assets/Images/flashdeal/2.jpg'
import images3 from '../../Assets/Images/flashdeal/3.jpg'
import images7 from '../../Assets/Images/product/bannerp.jpg'
import {Link} from "react-router-dom";
import icon5 from "../../Assets/Images/home-page-icon/global.png";
const GlobalShoppingNew = () => {

    const [globalImg,setGlobalIgm]=useState([
        {img:images1, price:1020},
        {img:images2, price:1020},
        {img:images3, price:1020},
    ])
    return (
        <div className="container py-2">
            <div className="bg-color custom-shadow">
                <div className="row">
                    <div className="col-md-12 g-1">
                        <div className="flash-deal-inner">
                            <div className="img-and-title">
                                <img src={icon5} alt=""/>
                                <h3 className={'new-font-size'}>Global Shopping New</h3>
                            </div>
                            <a href="">View More  <span><i className="fas fa-arrow-circle-right" /></span></a>
                        </div>
                    </div>
                </div>
                <div className="row ">
                    <div className="col-md-9 g-0" id={'global_shopping_new'}>
                        <div className="row g-2 GlobalShoppingNewSingleBox">
                            <div className="col-md-6" >
                                <div className="GlobalShoppingNewInnarBox GlobalBoxColor">
                                    <div className="GlobalShoppingHeading">
                                        <h4>Women's Fashion</h4>
                                    </div>
                                    <div className="row GlobalInnarRowPadding">
                                        {globalImg.map((item,index)=>(
                                            <div className="col-md-4 GlobalInnarColPadding" key={index}>
                                                <div className="GlobalShoppingItemInnar">
                                                    <a href="/">
                                                        <div className="GlobalShoppingItemInnarImg">
                                                            <img src={item.img} alt="" />
                                                            <div className="GlobalShoppingPrice">
                                                                <h4>
                                                                    <span>৳</span>{item.price}
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                             <div className="col-md-6">
                                <div className="GlobalShoppingNewInnarBox ">
                                    <div className="GlobalShoppingHeading">
                                        <h4>Women's Fashion</h4>
                                    </div>
                                    <div className="row GlobalInnarRowPadding">
                                        {globalImg.map((item,index)=>(
                                            <div className="col-md-4 GlobalInnarColPadding" key={index}>
                                                <div className="GlobalShoppingItemInnar">
                                                    <a href="/">
                                                        <div className="GlobalShoppingItemInnarImg">
                                                            <img src={item.img} alt="" />
                                                            <div className="GlobalShoppingPrice">
                                                                <h4>
                                                                    <span>৳</span>{item.price}
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="row g-2 GlobalShoppingNewSingleBox ">
                            <div className="col-md-6">
                                <div className="GlobalShoppingNewInnarBox ">
                                    <div className="GlobalShoppingHeading">
                                        <h4>Women's Fashion</h4>
                                    </div>
                                    <div className="row GlobalInnarRowPadding">
                                        {globalImg.map((item,index)=>(
                                            <div className="col-md-4 GlobalInnarColPadding" key={index}>
                                                <div className="GlobalShoppingItemInnar">
                                                    <a href="/">
                                                        <div className="GlobalShoppingItemInnarImg">
                                                            <img src={item.img} alt="" />
                                                            <div className="GlobalShoppingPrice">
                                                                <h4>
                                                                    <span>৳</span>{item.price}
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                            <div className="col-md-6">
                                <div className="GlobalShoppingNewInnarBox GlobalBoxColor">
                                    <div className="GlobalShoppingHeading">
                                        <h4>Women's Fashion</h4>
                                    </div>
                                    <div className="row GlobalInnarRowPadding">
                                        {globalImg.map((item,index)=>(
                                            <div className="col-md-4 GlobalInnarColPadding" key={index}>
                                                <div className="GlobalShoppingItemInnar">
                                                    <a href="/">
                                                        <div className="GlobalShoppingItemInnarImg">
                                                            <img src={item.img} alt="" />
                                                            <div className="GlobalShoppingPrice">
                                                                <h4>
                                                                    <span>৳</span>{item.price}
                                                                </h4>
                                                            </div>
                                                        </div>
                                                    </a>
                                                </div>
                                            </div>
                                        ))}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-3 pe-0 GlobalNewShoppingColBanner">
                        <Link to={'/'}>
                            <div className="GlobalNewShoppingBanner">
                                <img src={images7} alt="" />
                            </div>
                        </Link>
                    </div>
                </div>
            </div>
        </div>
    )
}
export default GlobalShoppingNew