import React from "react";

import {Link} from "react-router-dom";

const BlogCategory = () => {
    return (
        <>
            <div className="blog-category-inner bg-white">
                <h4 className={'blog-category'}>Category</h4>
                <nav className="navigation">
                    <ul className="mainmenu">
                        <li><Link to={'/blog'}>Home</Link></li>
                        <li><Link to={'/blog'}>About</Link></li>
                        <li><Link to={'/blog'}>Products</Link>
                            <ul className="submenu-blog">
                                <li><Link to={'/blog'}>Tops</Link></li>
                                <li><Link to={'/blog'}>Bottoms</Link></li>
                                <li><Link to={'/blog'}>Footwear</Link></li>
                            </ul>
                        </li>
                        <li><Link to={'/blog'}>Contact us</Link>
                            <ul className="submenu-blog">
                                <li><Link to={'/blog'}>Tops</Link></li>
                                <li><Link to={'/blog'}>Bottoms</Link></li>
                                <li><Link to={'/blog'}>Footwear</Link>
                                    <ul className="subsubmenu">
                                        <li><Link to={'/blog'}>Tops</Link></li>
                                        <li><Link to={'/blog'}>Bottoms</Link></li>
                                        <li><Link to={'/blog'}>Footwear</Link></li>
                                    </ul>
                                </li>
                            </ul>
                        </li>
                        <li><Link to={'/blog'}>About</Link></li>
                    </ul>
                </nav>
            </div>

        </>
    )
}
export default BlogCategory