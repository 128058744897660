import React from 'react';
import SideNavBar from "../Include/SideNavBar";
import ClintProfileTopBar from "../Include/ClintProfileTopBar";
import {Link} from "react-router-dom";
import img from '../../../../Assets/Images/profile/1.jpg'


const UserProfile = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="my-dashboard-inner">
                        <div className="row">
                            <div className="col-md-3">
                                <SideNavBar/>
                            </div>
                            <div className="col-md-9">
                                <ClintProfileTopBar/>
                                <div className="user-profile">
                                    <h5 className={'my-order-title text-center'}>Profile</h5>
                                </div>
                                <div className="card user-profile">
                                    <div className="card-body">
                                        <div className="profile_wrapper">
                                            <div className="profile_photo_area">
                                                <img src={img} alt={'profile-img'}/>
                                            </div>
                                            <div className="camera-wrappwer">
                                                <i className="fas fa-camera fa-2x" id="profile_photo_area"
                                                   style={{display: 'none'}}/>
                                                <form>
                                                    <input id="profile_photo_upload" name="photo" type="file"
                                                           style={{display: 'none'}}/>
                                                    <button type="submit" id="image_upload_button"
                                                            className="btn btn-success btn-sm"
                                                            style={{display: 'none'}}>Upload
                                                    </button>
                                                </form>
                                            </div>
                                            <div className="profile-name-and-email">
                                                <p><strong>Zakaria Ahmed Zahid</strong></p>
                                                <p>zahidfact@gmail.com</p>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                                <div className="card mt-3 user-profile">
                                    <div className="">
                                        <form  className="profile_form">
                                            <input name="_token" type="hidden"/>
                                            <div className="row">
                                                <div className="col-md-6">
                                                    <label htmlFor="company_name">Company Name</label>
                                                    <input className="form-control" placeholder="Insert Company Name"
                                                           name="company_name" type="text" value="Innovative IT"
                                                           id="company_name"/>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="address">Address</label>
                                                    <input className="form-control" placeholder="Insert Address"
                                                           name="address" type="text" id="address"/>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="city">City</label>
                                                    <input className="form-control" placeholder="Insert City"
                                                           name="city" type="text" id="city"/>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="region">Region</label>
                                                    <input className="form-control" placeholder="Insert Region"
                                                           name="region" type="text" id="region"/>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="country">Country</label>
                                                    <input className="form-control" placeholder="Insert Country"
                                                           disabled="disabled" name="country" type="text"
                                                           value="Bangladesh" id="country"/>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="post_code">Post Code</label>
                                                    <input className="form-control" placeholder="Insert Post Code"
                                                           name="post_code" type="text" id="post_code"/>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="nid">NID Number </label>
                                                    <input className="form-control" placeholder="Insert NID Number "
                                                           name="nid" type="text" id="nid"/>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="dob">Date of Birth</label>
                                                    <input className="form-control" placeholder="Insert Date of Birth"
                                                           id="datetimepicker" name="dob" type="date"/>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="Mobile">Phone Number (Primary)</label>
                                                    <input className="form-control"
                                                           placeholder="Insert Phone Number (Optional)"
                                                           disabled="disabled" name="phone" type="text"
                                                           value="+8801811151115"/>
                                                </div>
                                                <div className="col-md-6">
                                                    <label htmlFor="phone">Additional Phone Number</label>
                                                    <input className="form-control"
                                                           placeholder="Insert Additional Phone Number" name="phone"
                                                           type="text" id="phone"/>
                                                </div>
                                                <div className="col-md-12 text-center">
                                                    <button className="btn btn-success btn-block" type="submit">Save
                                                        Changes
                                                    </button>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default UserProfile;
