import React, {useState} from "react";
import {Link} from "react-router-dom";
import $ from 'jquery';

const SideNavBar = () => {

    const subMenuShowHandler = (id) => {
        $('#' + id).siblings('.nav-main-submenu').slideToggle()
        let element_id = id.split('_')
        let length = Object.keys(menus).length
        for (let i = 0; i <= length; i++) {
            if (i != element_id[1]) {
                $('#mainMenu_' + i).siblings('.nav-main-submenu').slideUp()
            }
        }
    }
    const [menus] = useState([
        {
            name: 'Order',
            path: '',
            icon: 'faSearch',
            subMenu: [
                {
                    name: 'All Order',
                    path: '/my/all-order',
                    icon: 'faSearch',
                },
                {
                    name: 'Pending',
                    path: '/my/pending-order',
                    icon: 'faSearch',
                },
                {
                    name: 'Delivered',
                    path: '/my/delivered-order',
                    icon: 'faSearch',
                },
            ]
        },
        {
            name: 'Billing',
            path: '',
            icon: 'faSearch',
            subMenu: [
                {
                    name: 'All Transactions',
                    path: '/my/all-transaction',
                    icon: 'faSearch',
                },
                {
                    name: 'All Invoice',
                    path: '/my/all-invoice',
                    icon: 'faSearch',
                },
            ]
        },

        {
            name: 'Account',
            path: '',
            icon: 'faSearch',
            subMenu: [
                {
                    name: 'Profile',
                    path: '/my/profile',
                    icon: 'faSearch',
                },
                {
                    name: 'Shipping Address',
                    path: '/my/shipping-address',
                    icon: 'faSearch',
                },
                {
                    name: 'Account & Security',
                    path: '/my/account-and-security',
                    icon: 'faSearch',
                },
            ]
        },

        {
            name: 'Support',
            path: '',
            icon: 'faSearch',
            subMenu: [
                {
                    name: 'Open Ticket',
                    path: '/my/open-ticket',
                    icon: 'faSearch',
                },
                {
                    name: 'My Ticket',
                    path: '/my/my-ticket',
                    icon: 'faSearch',
                },
            ]
        },
        {
            name: 'Live Chat',
            path: '/my/live-chat',
            icon: 'faSearch',
        },

        // {
        //     name: 'Invoice',
        //     path: '/billing',
        //     icon: 'faSearch',
        // },
    ])
    const [searchTerm,setSearchTerm]=useState("")
    return (
        <>
            <div className="navbar-section-main">
                <div className="navbar-search-section">
                    <form>
                        <input type={'text'} className={'input-search-box text-center'} placeholder="Search Menu" onChange={event => {setSearchTerm(event.target.value)}}/>
                        <i className={'fas fa-search top-search-icon-nav'}/>
                    </form>
                </div>

                <div className="nav-menu-section">
                    {menus.filter((menu)=>{
                        if(searchTerm==""){
                            return menu
                        }else if(menu.name.toLowerCase().includes(searchTerm.toLowerCase())){
                             return menu
                       }
                    }).map((menu, index) => (
                        <div className="nav-main-menu" key={index}>
                            <div className="nav-menu-flex" id={`mainMenu_${index}`}
                                 onClick={() => subMenuShowHandler(`mainMenu_${index}`)}>
                                <div className="nav-menu-item-inner">
                                    <i className={'fas fa-bars'}/>
                                    <Link to={menu.path}
                                          onClick={menu.subMenu != undefined && Object.keys(menu.subMenu).length > 0 ? (event) => event.preventDefault() : null}
                                          className={'dashboard-item'}>{menu.name}</Link>
                                </div>
                                <i className="fas fa-chevron-right"/>
                            </div>
                            {menu.subMenu != undefined ?
                                <div className="nav-main-submenu" style={{display: 'none'}} id={'sub_menu'}>
                                    <div className="submenu-flex">
                                        {menu.subMenu.map((submenu, subIndex) => (
                                            <div className="submenu" key={subIndex}>
                                                <i className={'fas fa-arrow-right'}/>
                                                <Link to={submenu.path}
                                                      className={'submenu-title'}>{submenu.name}</Link>
                                            </div>
                                        ))}
                                    </div>
                                </div> : null
                            }
                        </div>
                    ))}
                </div>
            </div>
        </>
    );
};

export default SideNavBar;
