import React, {useEffect, useState} from 'react';
import logo from '../../../../Assets/Images/brand_logo.png'
import {Link} from "react-router-dom";
import 'react-tabs/style/react-tabs.css';
import Toggle from 'react-toggle'
import "react-toggle/style.css" // for ES6 modules
import $ from 'jquery'
import {faFacebook, faGoogle, faWhatsapp} from "@fortawesome/free-brands-svg-icons";
import Countdown from 'react-countdown';
import {Col, Container, Form, Row} from "react-bootstrap";


const SignInPage = () => {
    const renderer = ({hours, minutes, seconds, completed}) => {
        if (completed) {
            // Render a completed state
            setCounterEnd(true)
            return null;
        } else {
            // Render a countdown
            return <span className={'flash-deal-count-down-new'}>
                <span className={'count-min'}>{minutes}</span>
            :<span className={'count-sec'}>{seconds}</span></span>;
        }
    };
    const [counterEnd, setCounterEnd] = useState(false)
    useEffect(() => {
        setCounter(false)
    }, [counterEnd])
    const [counter, setCounter] = useState(false)

    const login = (e) => {
        e.preventDefault()
    }

    const handleLoginToggle = (id) => {
        if (id == 'email') {
            $('#sign_in_with_email').slideDown()
            $('#sign_in_with_otp').slideUp()
            $('#password_login_button').addClass('sign-in-active-btn')
            $('#otp_login_button').removeClass('sign-in-active-btn')
        } else {
            $('#sign_in_with_email').slideUp()
            $('#sign_in_with_otp').slideDown()
            $('#password_login_button').removeClass('sign-in-active-btn')
            $('#otp_login_button').addClass('sign-in-active-btn')
        }
    }

    const handleLoginToggle2 = (id2) => {
        if (id2 == 'account') {
            $('#sign_in_email_otp_btn_section').slideUp()
            $('#sign_up_section').slideDown()
        } else {
            $('#sign_in_email_otp_btn_section').slideDown()
            $('#sign_up_section').slideUp()
        }
    }

    const showOtpField = () => {
        $('#otp_input').slideDown()
        setCounter(true)
    }

    const handlePasswordShow = (id) => {
        let element = $('#'+id)
        let type = element.attr('type')
        if (type == 'password') {
            element.attr('type', 'text')
        } else {
            element.attr('type', 'password')
        }
    }
    return (
        <>
            <div className="signin-container">
                <div className="singin-header">
                    <div className="sign-in-logo">
                        <img src={logo} className={'img-fluid'} alt=""/>
                    </div>
                </div>
                <div className="sign-in-email-otp-btn-section" id={'sign_in_email_otp_btn_section'}>
                    <div className="signin-buttons">
                        <button onClick={() => handleLoginToggle('email')}
                                className={' login-btn-new sign-in-active-btn'}
                                id={'password_login_button'}>Login With Password
                        </button>
                        <button onClick={() => handleLoginToggle('otp')} className={' login-btn-new '}
                                id={'otp_login_button'}>Login With OTP
                        </button>
                    </div>
                    <div className="singin-with-email" id={'sign_in_with_email'}>
                        <div className="sign-in-form">
                            <Form>
                                <Form.Control className={'sign-in-form-control'} type="email"
                                              placeholder="Enter Your Email"/>
                                <div className="password-with-eye-icon">
                                    <Form.Control id={'login_password'} className={'sign-in-form-control'}
                                                  type="password"
                                                  placeholder="Enter Your Password new"/>
                                    <div className="eye-icon" onClick={()=>handlePasswordShow('login_password')}>
                                        <i className={'fas fa-eye'}/>
                                    </div>
                                </div>
                                <div className="sign-in-and-forgot-pass">
                                    <div className="chek-box-sign-in">
                                        <Toggle
                                            icons={false}
                                            value='yes'/>
                                        <span className={'remember'}>Remember Me</span>
                                    </div>
                                    <div className="forgot-password">
                                        <Link to={'/sign-in'} className={'forgot-pass'}>Forget your
                                            password?</Link>
                                    </div>
                                </div>
                                <div className={'text-center email-and-phone-sign-in'}>
                                    <button type={'button'} className="sign-in-btn-new">Sign
                                        In
                                    </button>
                                </div>
                            </Form>
                            <div className="not-registered">
                                <p>Not registered? <span className={'create-account'}
                                                         onClick={() => handleLoginToggle2('account')}>Create an account</span>
                                </p>
                            </div>
                            <div className="sign-in-with-social-media-icon">
                                <p className={'or-sign-in-with'}>Or Sign In With</p>
                                <div className="s-icon-section">
                                    <i className={'fab fa-google s-media-icon'}/>
                                    <i className={'fab fa-facebook s-media-icon'}/>
                                    <i className={'fab fa-whatsapp s-media-icon'}/>
                                </div>
                            </div>
                        </div>

                    </div>

                    <div className="singin-with-otp" id={'sign_in_with_otp'} style={{display: 'none'}}>
                        <div className="sign-in-form">
                            <Form>
                                <Form.Control className={'sign-in-form-control '} type="number"
                                              placeholder="Enter Your Mobile Number"/>
                                <div className="otp-box" style={{display: 'none'}} id={'otp_input'}>
                                    <Form.Control className={' sign-in-form-control new-otp '} type="number"
                                                  placeholder="245766"/>
                                    <div className="otp-resend-countdown">
                                        {counter == true ?
                                            <Countdown
                                                date={Date.now() + 5000}
                                                renderer={renderer}
                                            />
                                            : null}
                                        {counterEnd == true ?
                                            <div className="resend-otp">
                                                <button
                                                    className={'resend-otp-and-change-number border-left-otp'}> Change
                                                    Number
                                                </button>
                                                <button
                                                    className={'resend-otp-and-change-number new-two-otp-btn-color'}>Resend
                                                    OTP
                                                </button>
                                            </div> : null
                                        }
                                    </div>

                                </div>
                                <div className={'text-center email-and-phone-sign-in'}>
                                    <button type={'button'} onClick={showOtpField} className="sign-in-btn-new">SEND ME
                                        OTP
                                    </button>
                                </div>
                            </Form>
                            <div className="sign-in-with-social-media-icon">
                                <p className={'or-sign-in-with'}>Or Sign In With</p>
                                <div className="s-icon-section">
                                    <i className={'fab fa-google s-media-icon'}/>
                                    <i className={'fab fa-facebook s-media-icon'}/>
                                    <i className={'fab fa-whatsapp s-media-icon'}/>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div className="sign-up-section" id={'sign_up_section'} style={{display: 'none'}}>
                    <div className="sign-in-form">
                        <Form>
                            <Form.Control className={'sign-in-form-control'} type="email"
                                          placeholder="Enter Your Name"/>
                            <Form.Control className={'sign-in-form-control'} type="password"
                                          placeholder="Enter Your Phone Number"/>
                            <Form.Control className={'sign-in-form-control'} type="email"
                                          placeholder="Enter Your Email"/>
                            <div className="password-with-eye-icon">
                                <Form.Control id={'create_password'} className={'sign-in-form-control'}
                                              type="password"
                                              placeholder="Enter Your Password new"/>
                                <div className="eye-icon" onClick={()=>handlePasswordShow('create_password')}>
                                    <i className={'fas fa-eye'}/>
                                </div>
                            </div>
                            <div className={'text-center email-and-phone-sign-in'}>
                                <button type={'button'} className="sign-in-btn-new">Create</button>
                            </div>
                        </Form>
                        <div className="not-registered">
                            <p>If All Ready registered? <span className={'create-account'}
                                                              onClick={() => handleLoginToggle2('sign_in')}>Sign In</span>
                            </p>
                        </div>
                    </div>
                    <div className="sign-in-with-social-media-icon">
                        <p className={'or-sign-in-with'}>Or Sign Up With</p>
                        <div className="s-icon-section">
                            <i className={'fab fa-google s-media-icon'}/>
                            <i className={'fab fa-facebook s-media-icon'}/>
                            <i className={'fab fa-whatsapp s-media-icon'}/>
                        </div>
                    </div>
                </div>

            </div>
        </>

    );
}

export default SignInPage;