import React, {useState} from "react";
import Nav from "./Include/Nav";

const DeliveryPolicy= () => {

    const [delivery, setDelivery]=useState([
        {title:'About', description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias cumque dolores\n' +
                'ducimus earum eligendi fugit impedit ipsam ipsum minus nesciunt, officia, quidem,\n' +
                'voluptates. Accusantium amet animi consectetur deserunt dicta distinctio, est fuga\n' +
                'maiores minus molestiae molestias, nobis quod recusandae sapiente sequi sit, veniam\n' +
                'voluptatem. Accusantium eligendi esse est laboriosam magni!'},
        {title:'Our Target', description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias cumque dolores\n' +
                'ducimus earum eligendi fugit impedit ipsam ipsum minus nesciunt, officia, quidem,\n' +
                'voluptates. Accusantium amet animi consectetur deserunt dicta distinctio, est fuga\n' +
                'maiores minus molestiae molestias, nobis quod recusandae sapiente sequi sit, veniam\n' +
                'voluptatem. Accusantium eligendi esse est laboriosam magni!'}
    ])
    return(
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <Nav/>
                    </div>
                    <div className="col-md-12">
                        <div className="others-section bg-white custom-shadow-without-top">
                            <h1 className={'text-center'}>Delivery Policy</h1>

                            <div className="delivery-information-table-section">
                                <h5> Delivery Timeline:</h5>
                                <table className={'delivery-information-table custom-box-shadow-inner'}>
                                    <thead>
                                    <tr>
                                        <th>Seller location</th>
                                        <th>Customer location</th>
                                        <th>Delivery time</th>
                                        <th>Complain time</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr>
                                        <td>Inside Dhaka</td>
                                        <td>inside Dhaka city</td>
                                        <td> 5 working days</td>
                                        <td>within 30 days of order time</td>
                                    </tr>
                                    <tr>
                                        <td>inside Dhaka</td>
                                        <td>outside Dhaka city</td>
                                        <td>10  working days</td>
                                        <td> within 30 days of order time</td>
                                    </tr>
                                    <tr>
                                        <td>Outside Dhaka</td>
                                        <td>inside Dhaka city</td>
                                        <td>10  working days</td>
                                        <td>within 30 days of order time</td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                            {delivery.map((item,index)=>(
                                <div className="other-section-inner custom-box-shadow-inner" key={index}>
                                    <div className="other-section-inner-title">
                                        <h4>{item.title}</h4>
                                    </div>
                                    <p>{item.description}</p>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default DeliveryPolicy