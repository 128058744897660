import React, {useState} from 'react'
import images1 from '../../Assets/Images/newarival/1.jpg'
import images2 from '../../Assets/Images/newarival/2.jpg'
import images3 from '../../Assets/Images/newarival/3.jpg'
import images4 from '../../Assets/Images/newarival/4.jpg'
import images5 from '../../Assets/Images/newarival/5.jpg'
import images6 from '../../Assets/Images/newarival/6.jpg'
import icon1 from '../../Assets/Images/home-page-icon/new.png'
import {Link, NavLink} from "react-router-dom";
import ModalProduct from "./ModalProduct";
import CardHover from "./CardHover";

const Newarivel = () => {
    const [newarivel, setnewarivel] = useState([
        {price: 28.12, product: 'This is paragraph', image: images1},
        {price: 180.00, product: 'This is paragraph', image: images2},
        {price: 158.13, product: 'This is paragraph', image: images3},
        {price: 258.89, product: 'This is paragraph', image: images4},
        {price: 12.43, product: 'This is paragraph', image: images5},
        {price: 3.58, product: 'This is paragraph', image: images6},
    ])
    const [quantity, setQuantity] = useState(1)

    const handleInputChange = (e) => {
        setQuantity(e.target.value)
    }
    const decreaseQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1)
        }
    }
    const increaseQuantity = () => {
        setQuantity(parseInt(quantity) + 1)
    }

    return (
        <div className="container py-2">
            <div className="bg-color custom-shadow">
                <div className="row">
                    <div className="col-md-12 g-1">
                        <div className="flash-deal-inner">
                            <div className="img-and-title">
                                <img src={icon1} alt=""/>
                                <h3 className={'new-font-size'}>New Arrival</h3>
                            </div>
                            <a href="">View More <span><i className="fas fa-arrow-circle-right"/></span></a>
                        </div>
                    </div>
                </div>
                <div className="row">
                    {newarivel.map((item, index) => (
                        <div className="col-md-2 g-1" key={index}>
                            <div className="card-inner">
                                <div className="card  my-card  img-relative">
                                    <CardHover/>
                                    <img src={item.image} className="card-img-top image" alt="..."/>
                                    <div className="card-body">
                                        <p className={'flash-deal-sold'}>{item.product}</p>
                                        <span className="flash-deal-price">৳ {item.price}</span>
                                    </div>
                                </div>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <ModalProduct/>
        </div>
    )
}
export default Newarivel