import React from "react";
import Countdown from "react-countdown";

const Flashsell = () => {
    const renderer = ({hours, minutes, seconds, completed}) => {
        if (completed) {
            // Render a completed state
            return 'Event Finished';
        } else {
            // Render a countdown
            return <span className={'flash-deal-count-down'}><span
                className={'flash-deal-count-hour'}>{hours}</span>:<span
                className={'flash-deal-count-min'}>{minutes}</span>:<span
                className={'flash-deal-count-sec'}>{seconds}</span></span>;
        }
    };

    return (
        <div className="flash-sell-section">
            <p className="custom-input-group custom-flash-sell-border">Flash Sell</p>
            <div className="flash-sell-inner">
                <h3 className={'flash-sell-font-size'}> End in:
                    <span className={'text-success'}>
                        <Countdown
                        date={Date.now() + 86400000}
                        renderer={renderer}/>
                    </span>
                </h3>
            </div>
            <p className={'custom-input-group custom-sold'}>2000 Sold</p>
        </div>
    )
}
export default Flashsell