import React, {useEffect, useState} from "react";
import Image1 from "../../../../Assets/Images/payment/1.svg";
import Image2 from "../../../../Assets/Images/payment/2.svg";
import Image3 from "../../../../Assets/Images/payment/3.svg";
import Image4 from "../../../../Assets/Images/payment/4.svg";
import Image5 from "../../../../Assets/Images/payment/5.svg";
import Image6 from "../../../../Assets/Images/payment/6.svg";
import {Link} from "react-router-dom";

const OrderSummary = () => {
    const [url, setUrl] = useState(0)

    useEffect(() => {
        setUrl(window.location.pathname)
    }, [])

    return (
        <div className="col-md-3 ps-2">

            <div className="sidebar-top-bg custom-shadow">
                <div className="content-color">
                    <h5>Order Summary</h5>
                    <div className="extra-border-total">

                    </div>
                </div>
                <div className="subtotal">
                    <p>Subtotal</p>
                    <p>৳ 1200</p>
                </div>
                <div className="subtotal">
                    <p>Shipping</p>
                    <p>৳ 50</p>
                </div>
                <div className="subtotal">
                    <p>Savings</p>
                    <p>৳ 120</p>
                </div>
                <div className="extra-border-total">

                </div>
                <div className="subtotal">
                    <p>Total <span>(4 Items)</span></p>
                    <p>৳ 1200</p>
                </div>
                {url == '/mycart' || url=='/checkout' || url == '/secure-payment' ?
                <div className="checkout-btn-section">
                    <Link to={url=='/mycart'?'/checkout':url=='/checkout'?'/secure-payment':url=='/secure-payment'?'/payment-completed':''}>
                        <button className="checkout-btn">{url == '/mycart'?'Proceed to Checkout':url=='/checkout'?'Proceed to Payment':url=='/secure-payment'?'Payment':''} </button>
                    </Link>
                </div>
                :''}
            </div>
            <div className={url != '/checkout' && url != '/secure-payment'  ? 'payment-method custom-shadow' : 'd-none'} >
                <div className="payment-method-title">
                    <p>Payment Methods</p>
                </div>
                <div className="payment-icon">
                    <img src={Image1} alt=""/>
                    <img src={Image2} alt=""/>
                    <img src={Image3} alt=""/>
                    <img src={Image4} alt=""/>
                    <img src={Image5} alt=""/>
                    <img src={Image6} alt=""/>
                    <img src={Image4} alt=""/>
                    <img src={Image5} alt=""/>
                    <img src={Image6} alt=""/>
                </div>
            </div>
        </div>

    )
}
export default OrderSummary