import React, {useState} from "react";
import images2 from "../../Assets/Images/flashdeal/2.jpg";
import NumberFormat from "react-number-format";

const ModalProduct = () => {
    const [quantity, setQuantity] = useState(1)

    const handleInputChange = (e) => {
        setQuantity(e.target.value)
    }
    const decreaseQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1)
        }
    }
    const increaseQuantity = () => {
        setQuantity(parseInt(quantity) + 1)
    }

    return (

            <div className="modal fade" id="exampleModal" tabIndex="-1">
                <div className="modal-dialog modal-lg custom-modal">
                    <div className="modal-content custom-modal-content">
                        <div className="modal-body custom-modal-body">
                            <div className="custom-modal-button">
                            <button type="button" className="fas fa-times-circle custom-close-button" data-bs-dismiss="modal"
                                    aria-label="Close"/>
                            </div>
                            <div className="row">
                                <div className="col-md-12 p-0">
                                    <div className="row">
                                        <div className="col-md-5 p-0">
                                            <div className="product-image">
                                                <img src={images2} className={'img-fluid'} alt=""/>
                                            </div>
                                            <div className="modal-small-img">
                                                <img src={images2} className={'img-fluid'} alt=""/>
                                                <img src={images2} className={'img-fluid'} alt=""/>
                                                <img src={images2} className={'img-fluid'} alt=""/>
                                                <img src={images2} className={'img-fluid'} alt=""/>
                                                <img src={images2} className={'img-fluid'} alt=""/>
                                            </div>
                                        </div>
                                        <div className="col-md-7 pe-0">
                                            <div className="modal-margin">
                                                <div className="product-title product-heading-color">
                                                    <h6>আইফোন 12 11 প্রো ট্রান্সপারেন্ট কেসের জন্য আইফোন 12 11 প্রো
                                                        ম্যাক্স ব্যাক
                                                        পিসি+টিপিইউ শকপ্রুফ ফুল লেন্স প্রোটেকশন কভার জন্য জয়রুম
                                                        ক্লিয়ার কেস</h6>
                                                </div>
                                                <div className="star-review-order">
                                                    <div className="star-icon">
                                                        <a href="/">
                                                            <span className="star-icon-color"><i
                                                                className="fas fa-star"/></span>
                                                            <span className="star-icon-color"><i
                                                                className="fas fa-star"/></span>
                                                            <span className="star-icon-color"><i
                                                                className="fas fa-star"/></span>
                                                            <span className="star-icon-color"><i
                                                                className="fas fa-star"/></span>
                                                            <span className="star-icon-color"><i
                                                                className="fas fa-star"/></span>
                                                            <span className="rating">5.0</span>
                                                            <span className="rating-arrow-icon"><i
                                                                className="fas fa-chevron-down"/></span>
                                                        </a>
                                                    </div>
                                                    <a href="/" className={'review-font-size'}><span
                                                        className="product-reviews">58 Reviews</span></a>
                                                    <span className="product-order-number">112 orders</span>
                                                </div>
                                                <div className="product-price">
                                                    <div className="product-price-card">
                                                        <h5><NumberFormat value={1980} displayType={'text'}
                                                                          thousandSeparator={true}
                                                                          prefix={'৳'}/></h5>
                                                    </div>

                                                </div>
                                                <div className="product-color product-heading-color">
                                                    <h6>Color:</h6>
                                                    <div className="product-color-new">
                                                        <a href="/"><img src={images2} alt=""/></a>
                                                        <a href="/"><img src={images2} alt=""/></a>
                                                        <a href="/"><img src={images2} alt=""/></a>
                                                    </div>
                                                </div>
                                                <div className="product-size product-heading-color">
                                                    <h6>Size:</h6>
                                                    <div className="product-size-inner">
                                                        <ul>
                                                            <li><span>S</span></li>
                                                            <li><span>M</span></li>
                                                            <li><span>L</span></li>
                                                            <li><span>XL</span></li>
                                                        </ul>
                                                    </div>
                                                </div>
                                                <div className="product-quantity product-heading-color">
                                                    <h6>Quantity:</h6>
                                                    <div className="product-quantity-inner">
                                                        <button className="minus-btn" onClick={decreaseQuantity}><i
                                                            className="fas fa-minus"/></button>
                                                        <input
                                                            className="quantity-input"
                                                            onChange={handleInputChange}
                                                            name="quantity"
                                                            type="number"
                                                            value={quantity}
                                                            max="20"/>
                                                        <button className="plus-btn" onClick={increaseQuantity}><i
                                                            className="fas fa-plus"/></button>
                                                        <span className="product-available">20 pieces available</span>
                                                    </div>
                                                    <button className="product-emi"><span
                                                        className="product-heading-color">EMI:</span> Available
                                                    </button>
                                                </div>
                                                <div className="buy-now-add-to-cart-btn">
                                                    <div className="buy-now-add-to-cart-btn-inner btn-margin">
                                                        <button className="btn rounded-pill buy-now-btn-color">Buy Now
                                                        </button>
                                                        <button
                                                            className="btn rounded-pill buy-now-btn-color add-to-cart-btn-color">Add
                                                            to Cart
                                                        </button>
                                                        <button className="product-fav-btn"><i
                                                            className="fas fa-heart"/></button>
                                                        <span
                                                            className="product-heading-color">Add to Wishlist (250)</span>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

    )
}
export default ModalProduct