import React from 'react'
import images from '../../Assets/Images/banner.png'
import icon2 from "../../Assets/Images/home-page-icon/crown.png";

 const BestSelling = () => {
    return (
        <div className="container py-2">
            <div className="bg-color custom-shadow">
                <div className="row">
                    <div className="col-md-12 g-1">
                    <div className="flash-deal-inner">
                        <div className="img-and-title">
                            <img src={icon2} alt=""/>
                            <h3 className={'new-font-size'}>Best Selling</h3>
                        </div>
                    <a href="">View More  <i className="fas fa-arrow-circle-right"></i></a>
                    </div>
                    <div className="banner-img">
                        <img className= "img-fluid" src={images} alt="" />
                    </div>
                </div>
                </div>
                </div>
        </div>
    )
}
export default BestSelling
