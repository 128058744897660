import React, {useState} from 'react'
import Select from 'react-select'
import {Tab, Tabs, TabList, TabPanel} from 'react-tabs';
import 'react-tabs/style/react-tabs.css';
import images1 from '../../../Assets/Images/topbrand/1.png'
import images2 from '../../../Assets/Images/topbrand/2.png'
import images3 from '../../../Assets/Images/topbrand/3.png'
import images4 from '../../../Assets/Images/topbrand/4.png'
import images5 from '../../../Assets/Images/topbrand/5.png'
import images6 from '../../../Assets/Images/topbrand/6.png'

const AllMerchentShop = () => {

    const [allShop, setallShop] = useState([
        {image: images1, storeName: 'aladiner prodip'},
        {image: images2, storeName: 'aladiner prodip'},
        {image: images3, storeName: 'aladiner prodip'},
        {image: images4, storeName: 'aladiner prodip'},
        {image: images5, storeName: 'aladiner prodip'},
        {image: images6, storeName: 'aladiner prodip'},
        {image: images1, storeName: 'aladiner prodip'},
        {image: images2, storeName: 'aladiner prodip'},
        {image: images3, storeName: 'aladiner prodip'},
        {image: images4, storeName: 'aladiner prodip'},
        {image: images5, storeName: 'aladiner prodip'},
        {image: images6, storeName: 'aladiner prodip'},
    ])
    const [allShopDelivery, setallShopDelivery] = useState([
        {image: images4, storeName: 'aladiner prodip'},
        {image: images5, storeName: 'aladiner prodip'},
        {image: images3, storeName: 'aladiner prodip'},
        {image: images6, storeName: 'aladiner prodip'},
        {image: images1, storeName: 'aladiner prodip'},
        {image: images2, storeName: 'aladiner prodip'},

    ])

    const [searchOption, setSearchOption] = useState([
        {value: 'chocolate', label: 'Chocolate'},
        {value: 'strawberry', label: 'Strawberry'},
        {value: 'vanilla', label: 'Vanilla'}
    ])

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            // width: '100%',
            // fontSize: '13px',
            outline: 'none',
            border: 'none',
            zIndex: 9999
        }),
        control: (provided, state) => ({
            ...provided,
            width: '164px',
            // fontSize: '13px',
            outline: 'none',
            border: 'none',
            boxShadow: 'none',

        }),
        option: (provided, state) => ({
            ...provided,
            "&:hover": {
                backgroundColor: "silver"
            }
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            right: '-35px',
            position: 'relative',
        })
    }
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <div className="all-shop-bg-section custom-shadow">
                            <div className="all-merchant-shop-inner">
                                <div className="row align-items-center">
                                    <div className="col-md-3">
                                        <div className="all-shop-cash-on-delivery-section">
                                            <h5>All Merchants</h5>
                                        </div>
                                    </div>
                                    <div className="col-md-9 all-shop-search-section-flex ">
                                        <div className="all-shop-search-section">
                                            <div className="input-group search-input-group">
                                                <div className="category-search-area">
                                                    <Select
                                                        options={searchOption}
                                                        styles={customStyles}
                                                        placeholder={'সকল বিক্রেতা'}
                                                    />
                                                </div>

                                                <div className="search-area">
                                                    <input type="text"
                                                           className="form-control search-area-control all-shop-search-section-control  "
                                                           placeholder=" বিক্রেতা খুজুন এখানে "/>
                                                    <span className="search-icon"><i className="fas fa-search"/></span>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="all-merchant-shop-location">
                                            <span><i className="fas fa-map-marker-alt"/></span>
                                        </div>
                                    </div>

                                </div>

                            </div>
                            <div className="row">
                                <div className="col-md-12 p-0">
                                    <div className="all-merchant-shop">
                                        <div className="all-merchant-brand">
                                            <div className="row">
                                                {allShop.map((item, index) => (
                                                    <div className="col-md-2 p-1" key={index}>
                                                        <div className="card all-merchant-card">
                                                            <a href=""><img src={item.image} className="card-img-top"
                                                                            alt="..."/></a>
                                                            <div className="card-body">
                                                                <a href=""><h5
                                                                    className="card-title">{item.storeName}</h5></a>
                                                            </div>
                                                        </div>
                                                    </div>
                                                ))}
                                                <div className="all-merchant-view-more-button">
                                                    <button>
                                                        View More
                                                    </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>
            </div>
        </>
    )
}
export default AllMerchentShop