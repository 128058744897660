import React from "react";
import logo from "../../../Assets/Images/brand_logo2.png";
import Select from "react-select";

const About = () => {
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12 merchent-section-padding ">
                        <div className="row white-background custom-shadow py-4 merchant-logo-address-follow-section">
                            <div className="col-md-12">
                                <div className="merchant-inner merchant-about-inner ">
                                    <div className="merchant-logo">
                                        <img src={logo} className={'img-fluid'} alt=""/>
                                    </div>
                                    <div className="merchant-address">
                                        <h5>Aladin Fashion Gallery</h5>
                                        <address> Mirpur, Dhaka-1216</address>
                                        <p>
                                            <span className="star-icon-color">
                                                    <i className="fas fa-star"/>
                                                    <i className="fas fa-star"/>
                                                    <i className="fas fa-star"/>
                                                    <i className="fas fa-star"/>
                                                    <i className="fas fa-star"/>
                                            </span>
                                            <span className={'merchant-review'}>
                                                0 | 0
                                            </span>
                                        </p>

                                    </div>
                                    <div className="merchant-follow-live-chat">

                                        <button type="button"
                                                className="follow-us-btn"><i className="fas fa-bell"/> Follow
                                        </button>
                                        <button type="button"
                                                className="chat-btn"><i className="fas fa-comment-dots"/> Chat
                                        </button>
                                    </div>
                                </div>
                            </div>
                     </div>
                    </div>
                </div>
                <div className="row">
                    <div className="col-md-12">
                        <div className="single-about-merchant white-background custom-shadow">
                            <h5>About</h5>
                            <p>Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum.</p>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default About
