import React from "react";
import BlogCategory from "./include/BlogCategory";
import images1 from '../../../Assets/Images/newarival/2.jpg'

const BlogDetails = () => {
    return (
        <>
            <div className="container">
                <div className="blog-details-section">
                    <div className="row">
                        <div className="col-md-3">
                            <BlogCategory/>
                        </div>
                        <div className="col-md-9 p-0">
                            <div className="blog-details-inner">
                                <div className="blog-details-content">
                                    <div className="blog-details">
                                        <div className="blog-inner">
                                            <div className="other-section-inner-title">
                                                <h4 className={'bolg-details-title'}>About</h4>
                                            </div>
                                            <p className={'blog-content'}>Lorem ipsum dolor sit amet, consectetur adipisicing elit. Distinctio et
                                                nihil nobis odit officia officiis quisquam temporibus unde voluptas
                                                voluptates. Cum debitis deserunt fugiat nisi, non officiis perferendis
                                                quaerat similique suscipit voluptate. Ab aliquid, beatae eligendi
                                                expedita ipsa porro ratione similique. Accusantium dolores eveniet nihil
                                                officia quos repudiandae rerum voluptates?</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="preview-and-next-btn">
                                <button className={'next-preview'}><i className="fas fa-chevron-left"/></button>
                                <button className={'next-preview'}><i className="fas fa-chevron-right"/></button>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default BlogDetails