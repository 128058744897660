import React from 'react'
import images1 from '../../Assets/Images/card/1.jpg'
import images2 from '../../Assets/Images/card/2.jpg'
import images3 from '../../Assets/Images/card/3.jpg'
import images4 from '../../Assets/Images/card/4.jpg'
import images from '../../Assets/Images/brand_logo.png';
import sendImage from '../../Assets/Images/live-chat-img/send.png'
import qrcode from '../../Assets/Images//QrCode.png'
import {Link} from "react-router-dom";


const Footer = () => {
    return (
        <footer className="footer-section">
            <div className="container footer-bg">
                <div className="fotter-innner">
                    <div className="row h-100">
                        <div className="col-md-4">
                            <div className="address-icon  h-100">
                                <span className="icon-inner"><i className="fas fa-map-marker-alt"/></span>
                                <div className="addres-inner">
                                    <h4>Find Us</h4>
                                    <p>Address</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4 ">
                            <div className="address-icon  h-100">
                                <span className="icon-inner"><i className="fas fa-phone"/></span>
                                <div className="addres-inner">
                                    <h4>Call us</h4>
                                    <p>01778-0000000</p>
                                </div>
                            </div>
                        </div>

                        <div className="col-md-4  ">
                            <div className="address-icon h-100">
                                <span className="icon-inner"><i className="fas fa-envelope"/></span>
                                <div className="addres-inner">
                                    <h4>Mail us</h4>
                                    <p>iithost@gmail.com</p>
                                </div>
                            </div>
                        </div>
                    </div>

                </div>

                <div className="row footer-about-padding">
                    <div className="col-md-3">
                        <div className="footer-about">
                            <img src={images} alt=""/>
                        </div>
                        <div className="footer-social-icon">
                            <a href=""><span><i className="fab fa-facebook"></i></span></a>
                            <a href=""><span><i className="fab fa-twitter"></i></span></a>
                            <a href=""><span><i className="fab fa-google-plus"></i></span></a>
                        </div>
                        <div className="follow-email-button">
                            <input className="search-feild" type="text" placeholder="Subscribe with Email"/>
                            <div className="search-button">
                                <Link to={'/'}>
                                    <img src={sendImage} alt=""/>
                                </Link>
                            </div>
                        </div>
                    </div>
                    <div className="col-md-9">
                        <div className="footer-account-nav-section">
                            <div className="footer-account-inner">
                                <h4 className="footer-inner-heading ">My Account</h4>
                                <div className="footer-account-nav">
                                    <Link to={'/about'}>About</Link>
                                    <Link to={'/contact'}>Contact</Link>
                                    <Link to={'/delivery-policy'}>Delivery-Policy</Link>
                                    <Link to={'/faq'}>FAQ</Link>
                                    <Link to={'/mission'}>Mission</Link>
                                    <Link to={'/vision'}>Vision</Link>
                                </div>
                            </div>
                            <div className="footer-account-inner">
                                <h4 className="footer-inner-heading">Information</h4>
                                <div className="footer-account-nav">
                                    <Link to={'/privacy-policy'}>Privacy-Policy</Link>
                                    <Link to={'/refund-policy'}>Refund-Policy</Link>
                                    <Link to={'/return-policy'}>Return-Policy</Link>
                                    <Link to={'/terms-and-conditions'}>Terms & Conditions</Link>
                                    <Link to={'/video-tutorial'}>Video-Tutorial</Link>

                                </div>
                            </div>
                            <div className="footer-account-inner footer-account-flex-2">
                                <h4 className="footer-inner-heading">Dowonload App</h4>
                                <div className="footer-account-nav  footer-account-flex">
                                    <div className="footer-Qr-code">
                                    <h5 className={'footer-app-title'}>Android</h5>
                                    <img src={qrcode} alt="" className={'img-fluid'}/>
                                    </div>
                                    <div className="footer-Qr-code">
                                    <h5 className={'footer-app-title'}>Apple</h5>
                                    <img src={qrcode} alt="" className={'img-fluid'}/>
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                        <hr className={'footer-bottom-hr'}/>
                    <div className="row footer-inner-buttom-margin">
                        <div className="col-md-6 footer-inner-buttom ">
                            <p>Copyright © 2021 Aladiner-Prodip. All Rights Reserved.</p>
                        </div>
                        <div className="col-md-6 footer-inner-buttom ">
                            <p>We're using safe payment for</p>
                            <ul>
                                <li>
                                    <img src={images1} alt=""/>
                                </li>
                                <li>
                                    <img src={images2} alt=""/>
                                </li>
                                <li>
                                    <img src={images3} alt=""/>
                                </li>
                                <li>
                                    <img src={images4} alt=""/>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}
    export default Footer