import React, {useState} from "react";
import Nav from "./Include/Nav";
import {
    Accordion,
    AccordionItem,
    AccordionItemHeading,
    AccordionItemButton,
    AccordionItemPanel,
} from 'react-accessible-accordion';
import 'react-accessible-accordion/dist/fancy-example.css';

const FAQ= () => {

    // const [faq, setFaq]=useState([
    //     {title:'About', description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias cumque dolores\n' +
    //             'ducimus earum eligendi fugit impedit ipsam ipsum minus nesciunt, officia, quidem,\n' +
    //             'voluptates. Accusantium amet animi consectetur deserunt dicta distinctio, est fuga\n' +
    //             'maiores minus molestiae molestias, nobis quod recusandae sapiente sequi sit, veniam\n' +
    //             'voluptatem. Accusantium eligendi esse est laboriosam magni!'},
    //     {title:'Our Target', description:'Lorem ipsum dolor sit amet, consectetur adipisicing elit. Alias cumque dolores\n' +
    //             'ducimus earum eligendi fugit impedit ipsam ipsum minus nesciunt, officia, quidem,\n' +
    //             'voluptates. Accusantium amet animi consectetur deserunt dicta distinctio, est fuga\n' +
    //             'maiores minus molestiae molestias, nobis quod recusandae sapiente sequi sit, veniam\n' +
    //             'voluptatem. Accusantium eligendi esse est laboriosam magni!'}
    // ])
    return(
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
<Nav/>
                    </div>
                    <div className="col-md-12">
                        <div className="others-section accordion-section bg-white custom-shadow-without-top">
                            <h1 className={'text-center'}>FAQ</h1>
                            <Accordion allowZeroExpanded={true} className={'accordion custom-box-shadow-inner'}>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            What harsh truths do you prefer to ignore?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                            Exercitation in fugiat est ut ad ea cupidatat ut in
                                            cupidatat occaecat ut occaecat consequat est minim minim
                                            esse tempor laborum consequat esse adipisicing eu
                                            reprehenderit enim.
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                                <AccordionItem>
                                    <AccordionItemHeading>
                                        <AccordionItemButton>
                                            Is free will real or just an illusion?
                                        </AccordionItemButton>
                                    </AccordionItemHeading>
                                    <AccordionItemPanel>
                                        <p>
                                            In ad velit in ex nostrud dolore cupidatat consectetur
                                            ea in ut nostrud velit in irure cillum tempor laboris
                                            sed adipisicing eu esse duis nulla non.
                                        </p>
                                    </AccordionItemPanel>
                                </AccordionItem>
                            </Accordion>

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default FAQ