import React from "react";
import SecurePayment from "./SecurePayment";


const PaymentCompleted = () => {
  return(
      <>
          <div className="container">
              <div className="row">
                  <div className="col-md-12">
                      <SecurePayment/>
                  </div>
              </div>

              <div className="row">
                  <div className="col-md-12">
                      <div className="payment-completed-section bg-white">
                        <h1>Payment Completed</h1>
                      </div>
                  </div>
              </div>

          </div>

      </>
  )
}
export default PaymentCompleted