import React, {useEffect, useState, Component} from "react";
import Select from 'react-select'
import Slider from "react-slick";
import '../../../../node_modules/slick-carousel/slick/slick.css';
import "../../../../node_modules/slick-carousel/slick/slick-theme.css";
import Slider1 from '../../../Assets/Images/banner/1.jpg';
import Slider2 from '../../../Assets/Images/banner/2.jpg';
import Slider3 from '../../../Assets/Images/banner/3.jpg';
// import images from '../../../Assets/Images/newarival/2.jpg'
import logo from '../../../../src/Assets/Images/brand_logo2.png'

import images1 from '../../../Assets/Images/flashdeal/1.jpg'
import images2 from '../../../Assets/Images/flashdeal/2.jpg'
import images3 from '../../../Assets/Images/flashdeal/3.jpg'
import images4 from '../../../Assets/Images/flashdeal/4.jpg'
import images5 from '../../../Assets/Images/flashdeal/5.jpg'
import images6 from '../../../Assets/Images/flashdeal/6.jpg'
import images7 from '../../../Assets/Images/newarival/1.jpg'
import images8 from '../../../Assets/Images/newarival/2.jpg'
import images9 from '../../../Assets/Images/newarival/3.jpg'
import images10 from '../../../Assets/Images/newarival/4.jpg'
import images11 from '../../../Assets/Images/newarival/5.jpg'
import images12 from '../../../Assets/Images/newarival/6.jpg'
import CategoryMaster from "../Catagorys/include/CategoryMaster";

const Merchant = () => {
    const [searchOption, setSearchOption] = useState([
        {value: 'chocolate', label: 'Chocolate'},
        {value: 'strawberry', label: 'Strawberry'},
        {value: 'vanilla', label: 'Vanilla'}
    ])
    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            // width: '100%',
            // fontSize: '13px',
            outline: 'none',
            border: 'none',
            zIndex: 9999
        }),

        control: (provided, state) => ({
            ...provided,
            outline: 'none',
            border: 'none',
            boxShadow: 'none',


        }),
        option: (provided, state) => ({
            ...provided,
            "&:hover": {
                backgroundColor: "silver"
            }
        }),

        indicatorSeparator: (provided, state) => ({
            ...provided,
            right: '-35px',
            position: 'relative',
        }),


    }
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12 merchent-section-padding">
                    <div className="row white-background py-4 merchant-logo-address-follow-section">
                        <div className="col-md-5">
                            <div className="merchant-inner">
                                <div className="merchant-logo">
                                    <img src={logo} className={'img-fluid'} alt=""/>
                                </div>
                                <div className="merchant-address">
                                    <h5>Aladin Fashion Gallery</h5>
                                    <address> Mirpur, Dhaka-1216</address>
                                    <p>
                                            <span className="star-icon-color">
                                                    <i className="fas fa-star"/>
                                                    <i className="fas fa-star"/>
                                                    <i className="fas fa-star"/>
                                                    <i className="fas fa-star"/>
                                                    <i className="fas fa-star"/>
                                            </span>
                                        <span className={'merchant-review'}>
                                                0 | 0
                                            </span>
                                    </p>

                                </div>
                                <div className="merchant-follow-live-chat">

                                    <button type="button"
                                            className="follow-us-btn"><i className="fas fa-bell"/> Follow
                                    </button>
                                    <button type="button"
                                            className="chat-btn"><i className="fas fa-comment-dots"/> Chat
                                    </button>
                                </div>
                            </div>
                        </div>
                        <div className="col-md-7 m-auto">
                            <div className="merchant-search-box-category">
                                <div className="input-group search-input-group">
                                    <div className="category-search-area">
                                        <Select
                                            options={searchOption}
                                            styles={customStyles}
                                            placeholder={'সকল ক্যাটাগরি'}
                                        />
                                    </div>
                                    <div className="search-area">
                                        <input type="text" className="form-control search-area-control"
                                               placeholder=" পণ্য খুজুন এখানে "/>
                                        <span className="merchent-search-icon input-group-text" id="basic-addon1"><i
                                            className="fas fa-search"/></span>
                                        {/*<span className="search-icon"><i className="fas fa-search" /></span>*/}
                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>
                </div>
            </div>
            <CategoryMaster/>
        </div>


    )
}

export default Merchant
