import React, {useState, useEffect} from 'react'
import Select from 'react-select'
import Odometer from 'react-odometerjs';
import images from '../../Assets/Images/brand_logo.png';
import {Link} from "react-router-dom";
import ProductCartMaster from "./ProductCartMaster";
import SignInPage from "../Pages/UserDashboard/Include/SignInPage";
import $ from 'jquery';
import CategoryMenuLeft from "./CategoryMenuLeft";


const Search = () => {
    const [searchOption, setSearchOption] = useState([
        {value: 'chocolate', label: 'Chocolate'},
        {value: 'strawberry', label: 'Strawberry'},
        {value: 'vanilla', label: 'Vanilla'}
    ])

    const customStyles = {
        menu: (provided, state) => ({
            ...provided,
            // width: '100%',
            // fontSize: '13px',
            outline: 'none',
            border: 'none',
            zIndex: 9999
        }),
        control: (provided, state) => ({
            ...provided,
            //  width: '1000%',
            // fontSize: '13px',
            outline: 'none',
            border: 'none',
            boxShadow: 'none',

        }),
        option: (provided, state) => ({
            ...provided,
            "&:hover": {
                backgroundColor: "silver"
            }
        }),
        indicatorSeparator: (provided, state) => ({
            ...provided,
            right: '-35px',
            position: 'relative',
        })
    };
    // $(document).ready(function(){
    //     $(this).on('scroll', function () {
    //         if ($(this).scrollTop() >50) {
    //             $('.header-area').addClass('sticky');
    //             $('.sticky-category').show();
    //         } else {
    //             $('.header-area').removeClass('sticky');
    //             $('.sticky-category').hide();
    //         }
    //     })
    // });

    const [url, setUrl] = useState(0)
    useEffect(() => {
        // Update the document title using the browser API
        let width = document.getElementById('slider_area')
        if (width != null) {
            width = width.offsetWidth
        } else {
            width = document.getElementById('category_bar_width').offsetWidth
        }


        window.addEventListener('scroll', handleSticky,true);

    }, []);

    const [menuDisplay, setMenuDisplay] = useState('none')

    const handleSticky = (e) => {
        let doScroll = true
        if (doScroll){
            $('#sticky_menu').addClass('sticky-menu')
            doScroll=false
        }
        //sticky-menu
        let distance =   $('#sticky_menu').offset().top
        if (distance > 46){
           $('#sticky_menu').addClass('sticky-menu').children('.container').removeClass('py-3')
        }else{
            $('#sticky_menu').removeClass('sticky-menu').addClass('container-fluid').children('.container').addClass('py-3')
        }

        if ( $('#sticky_menu').hasClass('sticky-menu')){
            setMenuDisplay('block')
        }else{
            setMenuDisplay('none')
        }
    }
    return (
        <div className={'container-fluid'} id={'sticky_menu'} onScroll={handleSticky}>
            <div className="container py-3">
                <div className="row align-items-center header-area">
                    <div className="col-xl-2 col-lg-2  image-sticky">
                        <div id={'sticky_category'} className="sticky-category" style={{display: menuDisplay}}>
                            <div className="category-menu-nav-new">
                                <nav className="navbar navbar-expand-lg category-navbar">
                                    <ul className="navbar-nav category-navbar-nav">
                                        <li className={url == '/' ? 'd-none' : 'nav-item dropdown category-menu-dropdown'}>
                                            <div className="sticky-dropdown-icon">
                                                <i className="fas fa-list"/>
                                            </div>
                                            <ul className="dropdown-menu  category-menu-dropdown-item">
                                                <CategoryMenuLeft/>
                                            </ul>
                                        </li>
                                    </ul>
                                </nav>
                            </div>
                        </div>
                        <div className={'logo-container'}>
                            <Link to={'/'}><img className='logo' src={images} alt=""/></Link>
                        </div>
                    </div>
                    <div className="col-xl-8 col-lg-8 ">
                        <div className="input-group search-input-group">
                            <div className="category-search-area">
                                <Select
                                    options={searchOption}
                                    styles={customStyles}
                                    placeholder={'সকল ক্যাটাগরি'}
                                />
                            </div>
                            <div className="search-area">
                                <input type="text" className="form-control search-area-control"
                                       placeholder=" পণ্য খুজুন এখানে "/>
                                <span className="search-icon"><i className="fas fa-search"/></span>
                            </div>
                        </div>
                    </div>
                    <div className="col-xl-2 col-lg-2 cart-icon-section-flex">

                         <Link to={'/mycart'}>
                             <ProductCartMaster/>
                         </Link>


                                <div className="cart-social-icon">
                                    <Link to={'/wishlist'}><i className="fas fa-heart"/></Link>
                                </div>

                                <div className="cart-social-icon" data-bs-toggle="modal"
                                     data-bs-target="#sign-in-modal">
                                    <Link to=""><i className="fas fa-user-circle"/></Link>
                                </div>
                    </div>
                </div>
                <div className="modal fade" id="sign-in-modal">
                    <div className="modal-dialog">
                        <div className="modal-content custom-modal-content ">
                            <div className="modal-header">
                                <h5 className="modal-title new-sign-in-modal-title" id="exampleModalLabel">Login User
                                    Account</h5>
                                <button type="button" className="fas fa-times-circle custom-close-button"
                                        data-bs-dismiss="modal"
                                        aria-label="Close"/>
                            </div>
                            <div className="modal-body sign-in-modal-body custom-modal-body">
                                <SignInPage/>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default Search
