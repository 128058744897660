import React, {useState} from "react";
import Nav from "./Include/Nav";
import {Link} from "react-router-dom";
import {Modal} from "react-bootstrap";


const VideoTutorial = () => {
    const [modalShow, setModalShow] = useState(false)
    const [vidUrl, setVidUrl] = useState(null)
    const handleModal = (url, e) => {
        setVidUrl(url)
        setModalShow(true)
    }

    const [video, setVideo] = useState([
        {
            videoTitle: 'How to place an order for Domain and Hosting',
            videoicon: '<i class="fas fa-play"/>',
            url: 'https://www.youtube.com/embed/qHlmeItERZQ'
        },
        {
            videoTitle: 'How to place an order for Register a New Domain',
            videoicon: '<i class="fas fa-play"/>',
            url: 'https://www.youtube.com/embed/C2rJ0BgKHvw'
        },
        {
            videoTitle: 'How to place an order for Register a New Domain',
            videoicon: '<i class="fas fa-play"/>',
            url: 'https://www.youtube.com/embed/C2rJ0BgKHvw'
        },
        {
            videoTitle: 'How to place an order for Register a New Domain',
            videoicon: '<i class="fas fa-play"/>',
            url: 'https://www.youtube.com/embed/C2rJ0BgKHvw'
        }
    ])
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <Nav/>
                    </div>
                    <div className="col-md-12">
                        <div className="video-tutorial-section bg-white custom-shadow-without-top">
                            <h1>Video Tutorial</h1>
                            <div className="video-section">
                                <div className="row">
                                    {video.map((item, index) => (
                                        <div className="col-md-6" key={index}>
                                            <div className="video-section-background custom-box-shadow-inner">
                                                <div className="row align-items-center">
                                                    <div className="col-2">
                                                        <div className="video-icon">

                                                            <div
                                                                onClick={(e) => handleModal(item.url, e)}
                                                                className="popup-youtube video-play-icon">
                                                                <span
                                                                    dangerouslySetInnerHTML={{__html: item.videoicon}}/>
                                                            </div>

                                                        </div>
                                                    </div>
                                                    <div className="col-10">
                                                        <div className="video-title">
                                                            <p onClick={(e) => handleModal(item.url, e)}>{item.videoTitle}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className="video-btn">
                                    <button className={'video-view-more'}>More Videos</button>
                                </div>

                            </div>
                        </div>
                    </div>

                </div>
            </div>

            <Modal
                size="lg"
                aria-labelledby="contained-modal-title-vcenter"
                centered
                show={modalShow}
            >
                <Modal.Body className={'p-2'}>
                    <button className={'modal_close-button'} onClick={() => setModalShow(false)}>X</button>
                    <div className={'ratio ratio-16x9 position-relative'}>
                        <iframe src={vidUrl}
                                title="YouTube video player" frameBorder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowFullScreen/>
                    </div>
                </Modal.Body>
            </Modal>
        </>
    )
}
export default VideoTutorial