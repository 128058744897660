import React, {useState} from "react";
import Nav from "./Include/Nav";

const TermsAndConditions = () => {
    const [terms, setTerms] = useState([
        {
            title: '1. INTRODUCTION', description: 'Welcome to aladinerprodip.com also   by known as “we", "us" or "aladinerprodip.com". We are an online marketplace and these are the terms and conditions governing your access and use of aladinerprodip.com along with its related sub-domains, sites, mobile app, services and tools (the "Site"). By using the Site, you   by accept these terms and conditions (including the linked information   in) and represent that you agree to comply with these terms and conditions (the "User Agreement"). This User Agreement is deemed effective upon your use of the Site which signifies your acceptance of these terms. If you do not agree to be bound by this User Agreement please do not access, register with or use this Site. This Site is owned and operated by aladinerprodip.com '+
                'The Site reserves the right to change, modify, add, or remove portions of these Terms and Conditions at any time without any prior notification. Changes will be effective when posted on the Site with no other notice provided. Please check these Terms and Conditions regularly for updates. Your continued use of the Site following the posting of changes to Terms and Conditions of use constitutes your acceptance of those changes.'

        },
        {
            title: '2. CONDITIONS OF USE',
            description: 'A. YOUR ACCOUNT '+
                'To access certain services offered by the platform, we may require that you create an account with us or provide personal information to complete the creation of an account. We may at any time in our sole and absolute discretion, invalidate the username and/or password without giving any reason or prior notice and shall not be liable or responsible for any losses suffered by, caused by, arising out of, in connection with or by reason of such request or invalidation.'
        }
    ])
    return (
        <>
            <div className="container">
                <div className="row">
                    <div className="col-md-12">
                        <Nav/>
                    </div>
                    <div className="col-md-12">
                        <div className="others-section bg-white custom-shadow-without-top">
                            <h1 className={'text-center'}>Terms And Conditions</h1>

                            {terms.map((item, index) => (
                                <div className="other-section-inner custom-box-shadow-inner" key={index}>
                                    <div className="other-section-inner-title">
                                        <h4>{item.title}</h4>
                                    </div>
                                    <p>{item.description}</p>
                                </div>
                            ))}

                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
export default TermsAndConditions