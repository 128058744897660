import React, {useEffect, useState} from "react";
import Slider from "react-slick";
import '../../../node_modules/slick-carousel/slick/slick.css';
import "../../../node_modules/slick-carousel/slick/slick-theme.css";
import Slider1 from '../../Assets/Images/banner/1.jpg';
import Slider2 from '../../Assets/Images/banner/2.jpg';
import Slider3 from '../../Assets/Images/banner/3.jpg';
import Slider4 from '../../Assets/Images/banner/4.jpg';
import Slider5 from '../../Assets/Images/banner/5.jpg';
import Slider6 from '../../Assets/Images/banner/6.jpg';
import CategoryMenuLeft from "./CategoryMenuLeft";
import NewSlider from "./NewSlider";

const SliderAndCategory = () => {
    const [settings] = useState({
        dots: false,
        arrows: false,
        infinite: true,
        autoplay: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1
    })
    const [settingsnew] = useState({
        dots: false,
        arrows: true,
        infinite: true,
        autoplay:true,
        speed: 1000,
        slidesToShow: 1,
        slidesToScroll: 1,
        delay: 300,
    })
    return (
        <>
            <div className="container py-2">
                <div className="row">
                    <div className="col-md-3  social catagorymenu-font-size"  id={'menu_height'}>
                       <CategoryMenuLeft/>
                    </div>
                    <div className="col-md-9 slider-banner-width" id={'slider_area'} >
                        <Slider {...settings}>
                            <div>
                                <img src={Slider1} className={'banner-width'} alt="" />
                            </div>
                            <div>
                                <img src={Slider2} className={'banner-width'} alt="" />
                            </div>
                            <div>
                                <img src={Slider3}  className={'banner-width'} alt="" />
                            </div>
                        </Slider>
                        <div className="banner-inner">
                            <Slider {...settingsnew}>
                                <div>
                                <img src={Slider4} alt="" />
                                </div>
                                <div>
                                <img src={Slider5}  alt="" />
                                </div>
                                <div>
                                <img src={Slider6}  alt="" />
                                </div>
                            </Slider>
                        </div>
                    </div>
                    {/*<NewSlider/>*/}

                </div>
            </div>
        </>
    )
}
export default SliderAndCategory