import React from 'react';
import SideNavBar from "../Include/SideNavBar";
import ClintProfileTopBar from "../Include/ClintProfileTopBar";
import {Link} from "react-router-dom";

const OpenTicket = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="my-dashboard-inner">
                        <div className="row">
                            <div className="col-md-3">
                                <SideNavBar/>
                            </div>
                            <div className="col-md-9">
                                <ClintProfileTopBar/>
                                <div className="user-profile">
                                    <h5 className={'my-order-title text-center'}>OpenTickets</h5>
                                </div>
                                <div className="open-ticket-section">
                                    <div className="custom-card-block account-eidt">
                                        <div className="row">
                                            <div className="form-group col-sm-6">
                                                <label htmlFor="inputName">Name</label>
                                                <input type="text" name="name" id="inputName"
                                                        className="form-control disabled"
                                                       disabled="disabled"/>
                                            </div>
                                            <div className="form-group col-sm-6">
                                                <label htmlFor="inputEmail">Email Address</label>
                                                <input type="email" name="email" id="inputEmail"
                                                        className="form-control disabled"
                                                       disabled="disabled"/>
                                            </div>
                                        </div>
                                        <div className="row mt-4">
                                            <div className="form-group col-sm-3">
                                                <label htmlFor="inputDepartment">Category</label>
                                                <select name="deptid" id="inputDepartment" className="form-select"
                                                        onChange="refreshCustomFields(this)">
                                                    <option value="1" selected="selected">
                                                        General Enquiries
                                                    </option>
                                                    <option value="2">
                                                        Pre-Sales
                                                    </option>
                                                    <option value="3">
                                                        Billing
                                                    </option>
                                                </select>
                                            </div>
                                            <div className="form-group col-sm-6">
                                                <label htmlFor="inputRelatedService">Related Service</label>
                                                <select name="relatedservice" id="inputRelatedService"
                                                        className="form-select">
                                                    <option value="">None</option>
                                                    <option value="S12">
                                                        547854 - Mobile
                                                    </option>
                                                    <option value="S2">
                                                        765748 - Bag
                                                    </option>
                                                    <option value="S13">
                                                        547854 - Watch
                                                    </option>
                                                    <option value="S930">
                                                        512486 - Dress
                                                    </option>
                                                    <option value="S930">
                                                        657841 - T-Shirt
                                                    </option>

                                                </select>
                                            </div>
                                            <div className="form-group col-sm-3">
                                                <label htmlFor="inputPriority">Priority</label>
                                                <select name="urgency" id="inputPriority" className="form-select">
                                                    <option value="High">
                                                        High
                                                    </option>
                                                    <option value="Medium" selected="selected">
                                                        Medium
                                                    </option>
                                                    <option value="Low">
                                                        Low
                                                    </option>
                                                </select>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="open-ticket-message-section open-ticket-section">
                                    <div className="sub-heading mb-3">
                                        <h6>Your Message</h6>
                                    </div>
                                    <div className="custom-card-block account-eidt">
                                        <div className="row">
                                            <div className="form-group col-sm-12">
                                                <label htmlFor="inputSubject">Subject</label>
                                                <input type="text" name="subject" id="inputSubject" value=""
                                                       className="form-control subject-on-ticket"/>
                                            </div>
                                            <div className="open-text-area">
                                                <textarea className={'open-text-area-inner'} placeholder="Write something...."/>
                                            </div>
                                            <div className="attach-file-main-section">
                                                <div className="attach-file-icon">
                                                    <label htmlFor="file-upload" className="attach-file-upload-icon"><i className="fas fa-paperclip"/></label><input id="file-upload" type="file" style={{display:'none'}}/>
                                                </div>

                                                {/*<div className="reply-button">*/}
                                                {/*    <button type="submit" className={'reply-button-inner'}><span><i className="fas fa-reply"/></span>Reply</button>*/}
                                                {/*</div>*/}

                                            </div>
                                            <div className="massage-submit-btn text-center">
                                                <button className={'submit-btn'}>Submit</button>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default OpenTicket;
