import React, {useState} from "react";
import images1 from "../../../Assets/Images/flashdeal/1.jpg";
import images2 from "../../../Assets/Images/flashdeal/2.jpg";
import NumberFormat from "react-number-format";

const Wishlisht = () => {
    const [quantity, setQuantity] = useState(1)

    const handleInputChange = (e) => {
        setQuantity(e.target.value)
    }
    const decreaseQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1)
        }
    }
    const increaseQuantity = () => {
        setQuantity(parseInt(quantity) + 1)
    }
    const [url, setUrl] = useState(0)

    const [cart_color, set_cart_color] = useState([
        {link: '/mycart', img: images1},
        {link: '/mycart', img: images1},
        {link: '/mycart', img: images1},
        {link: '/mycart', img: images1},
        {link: '/mycart', img: images1},
        {link: '/mycart', img: images1},
        {link: '/mycart', img: images1},
    ])

    return (


        <div className="container">

            <div className="row">
                <div className="col-md-12">
                    <div className="row maycart-store-name bg-white custom-shadow">
                        <div className="row">
                            <div className="col-12 mb-2">
                                <div className="product-img-item cart-store-inner ">
                                    <input type="checkbox" name="" id=""/>
                                    <h5 className="cart-store-name">Store Name</h5>
                                </div>
                            </div>
                        </div>
                        <div className="cart-single-product">
                            <div className="row cart-single-product-row">
                                <div className="col-md-3">
                                    <div className="product-img-item">

                                        <input type="checkbox" name="" id=""/>

                                        <img src={images1} className="img-fluid" alt=""/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="product-tittle-inner">
                                        <a href=""> [bluetooth Call] Bakeey CK30 Temperature Measurement Heart Rate
                                            Blood
                                            Pressure....</a>
                                        <div className="product-size-color">
                                            <div className="dropdown">
                                                <button className={'product-dropdown wishlist-dropdown'}
                                                        id="dropdownMenu2"
                                                        data-bs-toggle="dropdown">
                                                    Size:M | Color: red <span className={'product-dropdown-icon'}><i
                                                    className="fas fa-chevron-down"/></span>
                                                </button>
                                                <ul className="dropdown-menu dropdown-shopping-cart">
                                                    <div
                                                        className="shopping-cart-product-color product-heading-color">
                                                        <h6>Color:</h6>
                                                        <div className="shopping-cart-product-color-img">
                                                            {cart_color.map((item, index) => (
                                                                <a href="/mycart" key={index}><img src={images2}
                                                                                                   alt=""/></a>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="shopping-cart-product-size product-heading-color">
                                                        <h6>Size:</h6>
                                                        <div className="shopping-cart-product-size-inner">
                                                            <a href={'/mycart'}>S</a>
                                                            <a href={'/mycart'}>M</a>
                                                            <a href={'/mycart'}>L</a>
                                                        </div>
                                                    </div>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="product-cart-price">
                                            <h3>৳ 158.13</h3>
                                            <h6>
                                                <del>৳ 1230.2</del>
                                            </h6>
                                        </div>
                                        <div className="product-quantity product-heading-color">
                                            <div className="product-quantity-inner">
                                                <button className="minus-btn" onClick={decreaseQuantity}><i
                                                    className="fas fa-minus"/></button>
                                                <input
                                                    className="quantity-input"
                                                    onChange={handleInputChange}
                                                    name="quantity"
                                                    type="number"
                                                    value={quantity}
                                                    max="20"/>
                                                <button className="plus-btn" onClick={increaseQuantity}><i
                                                    className="fas fa-plus"/></button>
                                            </div>
                                        </div>
                                        <div className="product-delivery-time">
                                            <a className="gray-text" href=""><h6>Estimated Delivery
                                                Time: <span>5-7 Days</span></h6>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 text-end">
                                    <div className="recycle">
                                        <span><i className="fas fa-trash-alt"/></span>
                                    </div>
                                    <div className="buy-now-add-to-cart-btn">
                                        <div className="wishlist-cart-btn">
                                            <button className="btn rounded-pill wishlist-form-buy-now-btn-color">Buy
                                                Now
                                            </button>
                                            <button
                                                className="btn rounded-pill wishlist-form-buy-now-btn-color wishlist-form-add-to-cart-btn-color">Add
                                                to Cart
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className="cart-single-product ">
                            <div className="row cart-single-product-row  ">
                                <div className="col-md-3">
                                    <div className="product-img-item">

                                        <input type="checkbox" name="" id=""/>

                                        <img src={images1} className="img-fluid" alt=""/>
                                    </div>
                                </div>
                                <div className="col-md-6">
                                    <div className="product-tittle-inner">
                                        <a href=""> [bluetooth Call] Bakeey CK30 Temperature Measurement Heart Rate
                                            Blood
                                            Pressure....</a>
                                        <div className="product-size-color">
                                            <div className="dropdown">
                                                <button className={'product-dropdown wishlist-dropdown'}
                                                        id="dropdownMenu2"
                                                        data-bs-toggle="dropdown">
                                                    Size:M | Color: red <span className={'product-dropdown-icon'}><i
                                                    className="fas fa-chevron-down"/></span>
                                                </button>
                                                <ul className="dropdown-menu dropdown-shopping-cart">
                                                    <div
                                                        className="shopping-cart-product-color product-heading-color">
                                                        <h6>Color:</h6>
                                                        <div className="shopping-cart-product-color-img">
                                                            {cart_color.map((item, index) => (
                                                                <a href="/mycart" key={index}><img src={images2}
                                                                                                   alt=""/></a>
                                                            ))}
                                                        </div>
                                                    </div>
                                                    <div
                                                        className="shopping-cart-product-size product-heading-color">
                                                        <h6>Size:</h6>
                                                        <div className="shopping-cart-product-size-inner">
                                                            <a href={'/mycart'}>S</a>
                                                            <a href={'/mycart'}>M</a>
                                                            <a href={'/mycart'}>L</a>
                                                        </div>
                                                    </div>
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="product-cart-price">
                                            <h3>৳ 158.13</h3>
                                            <h6>
                                                <del>৳ 1230.2</del>
                                            </h6>
                                        </div>
                                        <div className="product-quantity product-heading-color">
                                            <div className="product-quantity-inner">
                                                <button className="minus-btn" onClick={decreaseQuantity}><i
                                                    className="fas fa-minus"/></button>
                                                <input
                                                    className="quantity-input"
                                                    onChange={handleInputChange}
                                                    name="quantity"
                                                    type="number"
                                                    value={quantity}
                                                    max="20"/>
                                                <button className="plus-btn" onClick={increaseQuantity}><i
                                                    className="fas fa-plus"/></button>
                                            </div>
                                        </div>
                                        <div className="product-delivery-time">
                                            <a className="gray-text" href=""><h6>Estimated Delivery
                                                Time: <span>5-7 Days</span></h6>
                                            </a>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-md-3 text-end">
                                    <div className="recycle">
                                        <span><i className="fas fa-trash-alt"/></span>
                                    </div>
                                    <div className="buy-now-add-to-cart-btn">
                                        <div className="wishlist-cart-btn">
                                            <button className="btn rounded-pill wishlist-form-buy-now-btn-color">Buy
                                                Now
                                            </button>
                                            <button
                                                className="btn rounded-pill wishlist-form-buy-now-btn-color wishlist-form-add-to-cart-btn-color">Add
                                                to Cart
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

    )
}
export default Wishlisht