import React from 'react';
import SideNavBar from "../Include/SideNavBar";
import ClintProfileTopBar from "../Include/ClintProfileTopBar";
import {Link} from "react-router-dom";
import image1 from "../../../../Assets/Images/newarival/1.jpg";
import image2 from "../../../../Assets/Images/newarival/2.jpg";
import image3 from "../../../../Assets/Images/newarival/3.jpg";

const PendingOrder = () => {
    return (
        <div className="container">
            <div className="row">
                <div className="col-md-12">
                    <div className="my-dashboard-inner">
                        <div className="row">
                            <div className="col-md-3">
                                <SideNavBar/>
                            </div>
                            <div className="col-md-9">
                                <ClintProfileTopBar/>
                                <div className="all-order-dropdown">
                                    <div className="main-all-order-section">
                                        <label className={'all-order-section-label'}>Show:</label>
                                        <select name="" id="" className={'all-order-select-section'}>
                                            <option value="" className={'all-order-option-section'}>Last 5 Order
                                            </option>
                                            <option value="" className={'all-order-option-section'}>Last 1 Week
                                            </option>
                                            <option value="" className={'all-order-option-section'}>Last 15 Days
                                            </option>
                                            <option value="" className={'all-order-option-section'}>Last 30 Days
                                            </option>
                                            <option value="" className={'all-order-option-section'}>All Order
                                            </option>
                                        </select>
                                    </div>
                                    <h5 className={'my-order-title'}>Pending Order</h5>
                                </div>
                                <div className="order-table-section">
                                    <div className="repeat-table">
                                        <div className="product-section-header">
                                            <div className="order-table-top-bar">
                                                <h6>Order:#245875641</h6>
                                                <p>Place on Date:24-12-2021</p>
                                                <Link to={'/'}>Manage</Link>
                                            </div>
                                            <div className="main-product-section-inner">
                                                <h6 className={'pt-2 heading-after'}>Aladiner Prodip</h6>
                                                <div className="main-product-section">
                                                    <div className="all-order-product-image">
                                                        <img src={image1} className={'all-order-product'} alt=""/>
                                                    </div>
                                                    <div className="all-order-product-description">
                                                        <p>Lorem Ipsum has been the industry's standard dummy text ever
                                                            since the 1500s</p>
                                                    </div>
                                                    <p>Qty:2</p>
                                                    <p>Warranty Left: 7 Days</p>
                                                    <p>Pending</p>
                                                    <p>৳ 124575</p>
                                                </div>
                                                <div className="main-product-section">
                                                    <div className="all-order-product-image">
                                                        <img src={image1} className={'all-order-product'} alt=""/>
                                                    </div>
                                                    <div className="all-order-product-description">
                                                        <p>Lorem Ipsum has been the industry's standard dummy text ever
                                                            since the 1500s</p>
                                                    </div>
                                                    <p>Qty:2</p>
                                                    <p>Warranty Left: 7 Days</p>
                                                    <p>Pending</p>
                                                    <p>৳ 124575</p>
                                                </div>
                                            </div>

                                            <div className="main-product-section-inner">
                                                <h6 className={'pt-2 heading-after'}>E valy</h6>
                                                <div className="main-product-section">
                                                    <div className="all-order-product-image">
                                                        <img src={image1} className={'all-order-product'} alt=""/>
                                                    </div>
                                                    <div className="all-order-product-description">
                                                        <p>Lorem Ipsum has been the industry's standard dummy text ever
                                                            since the 1500s</p>
                                                    </div>
                                                    <p>Qty:2</p>
                                                    <p>Warranty Left: 7 Days</p>
                                                    <p>Pending</p>
                                                    <p>৳ 124575</p>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                    <div className="repeat-table">
                                        <div className="product-section-header">
                                            <div className="order-table-top-bar">
                                                <h6>Order:#245875641</h6>
                                                <p>Place on Date:24-12-2021</p>
                                                <Link to={'/'}>Manage</Link>
                                            </div>
                                            <div className="main-product-section-inner">
                                                <h6 className={'pt-2 heading-after'}>Daraz BD</h6>
                                                <div className="main-product-section">
                                                    <div className="all-order-product-image">
                                                        <img src={image1} className={'all-order-product'} alt=""/>
                                                    </div>
                                                    <div className="all-order-product-description">
                                                        <p>Lorem Ipsum has been the industry's standard dummy text ever
                                                            since the 1500s</p>
                                                    </div>
                                                    <p>Qty:1</p>
                                                    <p>Warranty Left: 7 Days</p>
                                                    <p>Pending</p>
                                                    <p>৳ 124575</p>
                                                </div>
                                                <div className="main-product-section">
                                                    <div className="all-order-product-image">
                                                        <img src={image2} className={'all-order-product'} alt=""/>
                                                    </div>
                                                    <div className="all-order-product-description">
                                                        <p>Lorem Ipsum has been the industry's standard dummy text ever
                                                            since the 1500s</p>
                                                    </div>
                                                    <p>Qty:2</p>
                                                    <p>Warranty Left: 7 Days</p>
                                                    <p>Pending</p>
                                                    <p>৳ 124575</p>
                                                </div>
                                            </div>

                                            <div className="main-product-section-inner">
                                                <h6 className={'pt-2 heading-after'}>E valy</h6>
                                                <div className="main-product-section">
                                                    <div className="all-order-product-image">
                                                        <img src={image3} className={'all-order-product'} alt=""/>
                                                    </div>
                                                    <div className="all-order-product-description">
                                                        <p>Lorem Ipsum has been the industry's standard dummy text ever
                                                            since the 1500s</p>
                                                    </div>
                                                    <p>Qty:2</p>
                                                    <p>Warranty Left: 7 Days</p>
                                                    <p>Pending</p>
                                                    <p>৳ 124575</p>
                                                </div>
                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default PendingOrder;
