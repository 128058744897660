import React, { useEffect, useState } from "react";
import images1 from '../../Assets/Images/topbrand/1.png'
import images2 from '../../Assets/Images/topbrand/2.png'

const CategoryMenuLeft = () => {
    const [sliderWidth, setSliderWidth] = useState(0)
    const [menuHeight, setMenuHeight] = useState(0)

    useEffect(() => {
        // Update the document title using the browser API
        let width = document.getElementById('slider_area')
        if (width!= null){
            setSliderWidth(document.getElementById('slider_area').offsetWidth)
        }else {
            setSliderWidth(document.getElementById('category_bar_width').offsetWidth)
        }
        let height = document.getElementById('menu_height')
        if (height!= null){
            setMenuHeight(height.offsetHeight)
        }else{
            setMenuHeight()
        }

      //
    }, []);
    const [menu, setMenu] = useState([
        {
            name: ' ফ্লাশ সেল ', icon: 'fas fa-address-book',
            subMenu: [
                { name: 'TV' },
                { name: 'Fridge' },
                { name: 'LED Light' },
                { name: 'Star Light' },
                // {name: 'TV'},
            ]
        },
        {
            name: ' ইলেক্ট্রনিক্স ডিভাইস ', icon: 'fas fa-address-book',
            subMenu: [
                { name: 'Apple' },
                { name: 'MI' },
                { name: 'Realme' },
                { name: 'Vivo' },
            ]
        },
        {
            name: ' ইলেক্ট্রনিক্স এক্সেসরিজ ', icon: 'fas fa-address-book',
            subMenu: [
                { name: 'Rice Cooker' },
                { name: 'Mixer' },
                { name: 'Blender' },
                { name: 'Water Heater' },
            ]
        },
        {
            name: ' টেলিভিশন এবং হোম আপ্ল্যায়েন্স ', icon: 'fas fa-address-book',
            subMenu: [
                { name: 'Shirt' },
                { name: 'Shorts' },
                { name: 'Pant' },
                { name: 'Gown' },
            ]
        },
        {
            name: ' গ্রোসারিজ/খাদ্যসামগ্রী ', icon: 'fas fa-address-book',
            subMenu: [
                { name: 'T-Shirt' },
                { name: 'Half Pant' },
                { name: 'Tawal' },
                { name: 'V-shirt' },
            ]
        },
        {
            name: ' মেয়েদের ফ্যাশন ও গহনা ', icon: 'fas fa-address-book',
            subMenu: [
                { name: 'Mobile Cover' },
                { name: 'Protector' },
                { name: 'Ear Phone' },
                { name: 'Charger' },
            ]
        },
        {
            name: ' ছেলেদের ফ্যাশন এবং এক্সেসরিজ ', icon: 'fas fa-address-book',
            subMenu: [
                { name: 'Biriani' },
                { name: 'Kacchi' },
                { name: 'Tehari' },
                { name: 'Mughlai' },
            ]
        },
        {
            name: ' হেলথ এবং বিউটি ', icon: 'fas fa-address-book',
            subMenu: [
                { name: 'Coke' },
                { name: '7 UP' },
                { name: 'Sprite' },
                { name: 'Upper TEN' },
            ]
        },
        {
            name: ' বাসা-বাড়ি সাজানো সামগ্রী ', icon: 'fas fa-address-book',
            subMenu: [
                { name: 'Coke' },
                { name: '7 UP' },
                { name: 'Sprite' },
                { name: 'Upper TEN' },
            ]
        },
        {
            name: ' আর্টস এবং ক্রাফট সামগ্রী ', icon: 'fas fa-address-book',
            subMenu: [
                { name: 'Coke' },
                { name: '7 UP' },
                { name: 'Sprite' },
                { name: 'Upper TEN' },
            ]
        },
        {
            name: ' ঘড়ি ও এক্সেসরিজ ', icon: 'fas fa-address-book',
            subMenu: [
                { name: 'Coke' },
                { name: '7 UP' },
                { name: 'Sprite' },
                { name: 'Upper TEN' },
            ]
        },
        {
            name: '  চিকিৎসা সরঞ্জাম ', icon: 'fas fa-address-book',
            subMenu: [
                { name: 'Coke' },
                { name: '7 UP' },
                { name: 'Sprite' },
                { name: 'Upper TEN' },
            ]
        },
        {
            name: ' পাটজাত পন্য  ', icon: 'fas fa-address-book',
            subMenu: [
                { name: 'Coke' },
                { name: '7 UP' },
                { name: 'Sprite' },
                { name: 'Upper TEN' },
            ]
        },
        {
            name: ' শিশু বা কিডস সামগ্রী ', icon: 'fas fa-address-book',
            subMenu: [
                { name: 'Coke' },
                { name: '7 UP' },
                { name: 'Sprite' },
                { name: 'Upper TEN' },
            ]
        },
        {
            name: ' বই ও স্টেশনারি ', icon: 'fas fa-address-book',
            subMenu: [
                { name: 'Coke' },
                { name: '7 UP' },
                { name: 'Sprite' },
                { name: 'Upper TEN' },
            ]
        },
        {
            name: ' সকল ক্যাটাগরি ', icon: 'fas fa-address-book',
            subMenu: [
                { name: 'Coke' },
                { name: '7 UP' },
                { name: 'Sprite' },
                { name: 'Upper TEN' },
            ]
        }
    ])
    const [brandImage,setBrandImage]=useState([
        {img1:images1,img2:images2},
        {img1:images1,img2:images2},
        {img1:images1,img2:images2},
        {img1:images1,img2:images2},
        {img1:images1,img2:images2},
        {img1:images1,img2:images2},
        {img1:images1,img2:images2},
    ])
    return (
        <>
            <nav className="sidebar card left-category-dropdown-indicator">
                <ul className="nav flex-column">
                    {menu.map((menu, index) => (
                        <li className="nav-item" key={index}>
                            <a className="nav-link category-nav-link" href="/"> <span className={'menu-left-icon'}><i className={menu.icon} /></span>{menu.name}</a>
                            <ul className="submenu dropdown-menu category-dropdown-menu  slideInLeft" style={{ width: sliderWidth, height: menuHeight }}>
                                <li>
                                    <div className="row bg-white">
                                        <div className="col-md-8">
                                            <div className="row">
                                                {menu.subMenu.map((sub, idx) => (
                                                    <div className="col-md-3 menu-heading" key={idx}>
                                                        <h4>Static Menu</h4>
                                                        <hr />
                                                        <a className="nav-link leftCategoryFont-color" href="/"><span className={'menu-left-icon'}><i className={menu.icon} /></span>{sub.name}</a>
                                                    </div>
                                                ))}
                                            </div>
                                        </div>
                                        <div className="col-md-4 ps-0">
                                            {brandImage.map((item,index)=>(
                                                <div className="row g-1" key={index}>
                                                    <div className="col-6">
                                                        <div className="left-category-image">
                                                            <img src={item.img1} alt="brand-image1"/>
                                                        </div>
                                                    </div>
                                                    <div className="col-6">
                                                        <div className="left-category-image">
                                                            <img src={item.img2} alt="brand-image2"/>
                                                        </div>
                                                    </div>
                                                </div>
                                            ))}
                                        </div>
                                    </div>
                                </li>
                            </ul>
                        </li>
                    ))}
                </ul>
            </nav>
        </>
    )
}
export default CategoryMenuLeft