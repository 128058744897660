import React, {useState} from "react";
import {Link} from "react-router-dom";
import images2 from "../../Assets/Images/flashdeal/2.jpg";
import NumberFormat from "react-number-format";
import ModalProduct from "./ModalProduct";

const CardHover = () => {
    const [quantity, setQuantity] = useState(1)

    const handleInputChange = (e) => {
        setQuantity(e.target.value)
    }
    const decreaseQuantity = () => {
        if (quantity > 1) {
            setQuantity(quantity - 1)
        }
    }
    const increaseQuantity = () => {
        setQuantity(parseInt(quantity) + 1)
    }

    return (

        <div className="card-hover">
            <div className="product-hover">
                <div className="product-hover-inner">
                    <button className="minus-btn product-minus" onClick={decreaseQuantity}><i
                        className="fas fa-minus"/></button>
                    <input
                        className="quantity-input new-quantity-margin"
                        onChange={handleInputChange}
                        name="quantity"
                        type="number"
                        value={quantity}
                        max="20"/>
                    <button className="plus-btn product-minus" onClick={increaseQuantity}><i
                        className="fas fa-plus"/></button>
                </div>
                <div className="add-to-cart-and-view">
                    <button type="button" className={'view-and-cart'} data-bs-toggle="modal"
                            data-bs-target="#exampleModal">
                        Add to Cart
                    </button>
                    <Link to={'/product'} className={'view-and-cart hover-view-details'}>View Details</Link>
                </div>
            </div>
        </div>


    )
}
export default CardHover